import axios from 'axios';
import React, {useEffect, RefObject, useState, createRef} from 'react';
import { Button, InputBase, FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';
import loadScript from 'load-script';
import { ContentType } from '@components/modal-add-file';
import InputTooltip from '@components/custom-input-tooltip';

import DriveBlue from '@assets/svg/google/googleDriveBlue.svg?tag';
import Sheet from '@assets/svg/google/googleSheets.svg?tag';
import Slide from '@assets/svg/google/googleSlides.svg?tag';
import Drive from '@assets/svg/google/googleDrive.svg?tag';
import Doc from '@assets/svg/google/googleDocs.svg?tag';
import Upload from '@assets/svg/upload.svg?tag';
import Link from '@assets/svg/link.svg?tag';
import styles from './styles.m.scss';
import Plausible from 'plausible-tracker';
import {setActiveWizardStep, setNewWizardStep} from '@redux/reducers/wizard/actions';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

interface IProps {
  accessToken: string;
  activeFileType: GoogleDocumentType;
  typeFile: GoogleDocumentType;
  changeType: (type: GoogleDocumentType) => void;
  handleSelectFromDriveModal: (value: boolean) => void;
  closeModal: (value: boolean | IPickerResponse) => void;
  setType: (type: ContentType) => void;
  handleError: () => void;
  inputRef: RefObject<HTMLInputElement>;
  isGoogleNameInvalid: boolean;
  wizard: IWizardState;
  resetTooltip: () => void;
  setPickedDirectoryId: (directoryId: string) => void;
  setNewWizardStep: typeof setNewWizardStep;
  setActiveWizardStep: typeof setActiveWizardStep;
}

const ERROR_MESSAGE_GOOGLE_NAME_INVALID: string =
  'New google document name cannot be empty';

function AddFileMain(props: IProps) {
  const {
    activeFileType,
    changeType,
    inputRef,
    isGoogleNameInvalid,
    resetTooltip
  } = props;
  const [googleName, setGoogleName] = useState<string>('');
  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  const _wizardRef = createRef<HTMLDivElement>();

  const onApiLoad = () => {
    (window as any).gapi.load('picker');
  };

  useEffect(() => {
    loadScript(GOOGLE_SDK_URL, onApiLoad);
    if (props.wizard.isWizardActive) {
      props.setNewWizardStep({
        content: {
          text: 'Should we include files from GDrive, or perhaps something outside of GDrive completely?',
          title: 'Choosing type of files',
        },
        position: _wizardRef?.current?.getBoundingClientRect(),
        step: 3,
      });
    }
  }, [props.wizard.activeStep]);

  const handlePicked = (response: IPickerResponse, isFolders?: boolean) => {
    if (response.action === 'cancel') {
          props.handleSelectFromDriveModal(false);
    } else if (response.action === 'picked') {
      if (isFolders) {
        props.setPickedDirectoryId(response.docs ? response.docs[0].id : '');
      } else {
        plausible.trackEvent('Add File', {
          props: {
            type: 'picked from drive'
          }
        });
        props.closeModal(response);
      }
      if (props.wizard.isWizardActive) {
        props.setActiveWizardStep(5);
      }
    }
  };

  const onGoogleNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setGoogleName(event.target.value);
    if (isGoogleNameInvalid) {
      resetTooltip();
    }
  };

  const setPropTypeToLink = () => {
    props.setType('link');
    if (props.wizard.isWizardActive) {
      props.setNewWizardStep({
        content: {
          text: 'Fill in name and URL for the link. Keep in mind that Neatly won`t have native integration with this link as it does with files and folders synced from GDrive.',
          title: `Linking resources on the web`,
        },
        position: {current: 'dummy'},
        step: 4,
      });
      props.setActiveWizardStep(4);
    }
  };

  const setPropTypeToUpload = () => {
    props.setType('upload');
    if (props.wizard.isWizardActive) {
      props.setNewWizardStep({
        content: {
          text: 'Upload a file with any format from your computer. Size of the file can be up to 100MB. This will be uploaded to your GDrive alongside Neatly.',
          title: `Upload a file from computer`,
        },
        position: {current: 'dummy'},
        step: 4,
      });
      props.setActiveWizardStep(4);
    }
  };

  const addNewWizardStep = (step: number, isFolders: boolean) => {
    const userAgent = window.navigator.userAgent;
    let buttonToClick = 'Control';
    if (/Mac/i.test(userAgent)) {
      buttonToClick = 'Command';
    }
    const newWizardStep = {
      content: {
        text: `Select file(s) that you want to sync and click "Select." You can select multiple files by holding the ${buttonToClick} button on your keyboard while selecting files.`,
        title: `Choosing files from Google Drive`,
      },
      position: {current: 'dummy'},
      step,
    };
    if (isFolders) {
      newWizardStep.content.text = 'Select a single folder that you want to sync and click "Select". Double click on a folder to search for subfolders. You can select only one folder at a time.';
      newWizardStep.content.title = 'Choosing a folder from Google Drive';
    }
    if (props.wizard.isWizardActive) {
      props.setNewWizardStep(newWizardStep);
      props.setActiveWizardStep(step);
    }
  };

  const select = async (isFolders?: boolean) => {
    let result;
    try {
      result = await axios.get(
        `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${props.accessToken}`
      );
      if (result.status === 200) {
        props.handleSelectFromDriveModal(true);

        const google = (window as any).google;
        const googleViewId = google.picker.ViewId.DOCS;
        const sharedDriveView = new google.picker.DocsView(googleViewId);
        sharedDriveView.setEnableDrives(true);
        const personalDriveView = new google.picker.DocsView(googleViewId).setOwnedByMe(true);
        if (isFolders) {
          sharedDriveView
            .setIncludeFolders(true)
            .setMimeTypes('application/vnd.google-apps.folder')
            .setSelectFolderEnabled(true);

          personalDriveView
              .setIncludeFolders(true)
              .setMimeTypes('application/vnd.google-apps.folder')
              .setSelectFolderEnabled(true);
        }
        let picker = new google.picker.PickerBuilder()
          .addView(sharedDriveView)
          .addView(personalDriveView)
          .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
          .setOAuthToken(props.accessToken)
          .setDeveloperKey(process.env.DEVELOPER_KEY)
          .setAppId(process.env.GOOGLE_APP_ID)
          .setCallback((response: IPickerResponse) => handlePicked(response, isFolders))
          .setSize(800, 600);
        if (!isFolders) {
          picker = picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
        }
        picker.build().setVisible(true);

        addNewWizardStep(4, isFolders);
      } else {
        props.handleError();
      }
    } catch (error) {
      props.handleError();
    }
  };

  const googleBtnClassNames = (type: GoogleDocumentType) => {
    switch (type) {
      case 'document':
        return classNames(
          styles.googleBtn,
          activeFileType === 'document' && styles.active
        );
      case 'spreadsheet':
        return classNames(
          styles.googleBtn,
          activeFileType === 'spreadsheet' && styles.active
        );
      case 'presentation':
        return classNames(
          styles.googleBtn,
          activeFileType === 'presentation' && styles.active
        );
      default:
        return styles.googleBtn;
    }
  };

  const googleFileNameControl = (
    <div className={styles.formLink}>
      <InputTooltip
        message={ERROR_MESSAGE_GOOGLE_NAME_INVALID}
        isOpen={isGoogleNameInvalid}
        type="error"
      >
        <InputBase
          placeholder="Please enter a file name"
          className={styles.inputGoogleName}
          error={isGoogleNameInvalid}
          inputRef={inputRef}
          value={googleName}
          autoFocus={true}
          onChange={onGoogleNameChange}
        />
      </InputTooltip>
    </div>
  );

  return (
    <div className={styles.addFileMain}>
      <p className={classNames(styles.modalText, styles.createNew)}>
        Add From:
      </p>
      <div className={styles.googleSet} ref={_wizardRef}>
        <Button
          variant="outlined"
          className={classNames(styles.googleBtn, styles.googleDrive)}
          startIcon={<Drive />}
          onClick={() => select()}
        >
          Sync files
        </Button>
        <Button
            variant="outlined"
            className={classNames(styles.googleBtn, styles.link)}
            startIcon={<DriveBlue />}
            onClick={() => select(true)}
        >
          Sync a folder
        </Button>
        <Button
          variant="outlined"
          className={classNames(styles.googleBtn, styles.link)}
          startIcon={<Link />}
          onClick={setPropTypeToLink}
        >
          Link to a file
        </Button>
        <Button
          variant="outlined"
          className={classNames(styles.googleBtn, styles.link)}
          startIcon={<Upload />}
          onClick={setPropTypeToUpload}
        >
          Upload file
        </Button>
      </div>
      <p className={classNames(styles.modalText, styles.createNew)}>
        Create New:
      </p>
      <div className={styles.googleSet}>
        <Button
          variant="outlined"
          className={googleBtnClassNames('document')}
          startIcon={<Doc />}
          onClick={() => changeType('document')}
        >
          Google Doc
        </Button>
        <Button
          variant="outlined"
          className={googleBtnClassNames('spreadsheet')}
          startIcon={<Sheet />}
          onClick={() => changeType('spreadsheet')}
        >
          Google Sheet
        </Button>
        <Button
          variant="outlined"
          className={googleBtnClassNames('presentation')}
          startIcon={<Slide />}
          onClick={() => changeType('presentation')}
        >
          Google Slide
        </Button>
      </div>
      {activeFileType && (
        <div className={styles.googleFileNameWrap}>
          <FormControlLabel
            label="File Name"
            labelPlacement="top"
            className={styles.labelGoogleName}
            control={googleFileNameControl}
          />
        </div>
      )}
    </div>
  );
}

export default AddFileMain;
