import Types from './types';

export const fetchFiles = (
  sectionId: string,
  groupId: string,
  count?: number,
  offset?: number,
  tags?: string[]
) => ({
  payload: { sectionId, groupId, count, offset, tags },
  type: Types.FETCH_FILES_REQUEST
});

export const getFileFromNotificationById = (id: string) => ({
  payload: { id },
  type: Types.GET_FILE_FROM_NOTIFICATION_REQUEST
});

export const getFileById = (id: string, isOpenFile?: boolean) => ({
  payload: { id },
  type: isOpenFile ? Types.OPEN_FILE_FROM_DRIVE_REQUEST : Types.GET_FILE_REQUEST
});

export const adjustFilePermissionsById = (id: string) => ({
  payload: { id },
  type: Types.ADJUST_FILE_PERMISSIONS_REQUEST
});

export const clearPreviewAllowed = () => ({
  payload: {},
  type: Types.CLEAR_PREVIEW_ALLOWED
});

export const createDocFile = (
  sectionId: string,
  type: string,
  name: string,
  groupId?: string,
  groupTitle?: string,
  groupDescription?: string
) => {
  if (groupId) {
    return {
      payload: { sectionId, type, name, groupId },
      type: Types.CREATE_DOC_FILE_REQUEST
    };
  } else {
    const emptyGroupId: null = null;
    return {
      payload: {
        emptyGroupId,
        groupDescription,
        groupTitle,
        name,
        sectionId,
        type
      },
      type: Types.CREATE_DOC_FILE_REQUEST
    };
  }
};

export const createByUrl = (
  sectionId: string,
  name: string,
  url: string,
  groupId?: string,
  groupTitle?: string,
  groupDescription?: string
) => {
  if (groupId) {
    return {
      payload: { sectionId, name, url, groupId },
      type: Types.CREATE_BY_URL_REQUEST
    };
  } else {
    const emptyGroupId: null = null;
    return {
      payload: {
        emptyGroupId,
        groupDescription,
        groupTitle,
        name,
        sectionId,
        url
      },
      type: Types.CREATE_BY_URL_REQUEST
    };
  }
};

export const createFromDrive = (file: IFilesPicked) => ({
  payload: { ...file },
  type: Types.CREATE_BY_DRIVE_REQUEST
});

export const uploadFile = (
  sectionId: string,
  file: File,
  groupId?: string,
  groupTitle?: string,
  groupDescription?: string
) => {
  if (groupId) {
    return {
      payload: { sectionId, file, groupId },
      type: Types.UPLOAD_REQUEST
    };
  } else {
    const emptyGroupId: null = null;
    return {
      payload: { sectionId, file, emptyGroupId, groupTitle, groupDescription },
      type: Types.UPLOAD_REQUEST
    };
  }
};

export const updateFile = (file: IFile, name: string, tags: IFileTag[]) => ({
  payload: { file, name, tags },
  type: Types.UPDATE_FILE_REQUEST
});

export const makeFileShareableWithLink = (fileId: string) => ({
  payload: { fileId },
  type: Types.MAKE_SHARABLE_WITH_LINK_REQUEST
});

export const fetchTags = () => ({
  type: Types.GET_FILE_TAGS_REQUEST
});

export const addNewFileTag = (fileTag: IFileTag) => ({
  payload: { fileTag },
  type: Types.ADD_NEW_FILE_TAG_REQUEST
});

export const deleteFileTags = (ids: string[]) => ({
  payload: { tagIds: ids },
  type: Types.DELETE_FILE_TAGS_REQUEST
});

export const deleteFiles = (
  sectionId?: string,
  groupId?: string,
  filesIds?: string[]
) => ({
  payload: { sectionId, groupId, filesIds },
  type: Types.DELETE_FILE_REQUEST
});

export const clearFilesAfterNotifications = (
  sectionId?: string,
  groupId?: string,
  filesIds?: string[]
) => ({
  payload: { sectionId, groupId, filesIds },
  type: Types.CLEAR_FILES_AFTER_NOTIFICATION
});

export const deleteAllFilesInSection = (sectionId: string) => ({
  payload: { sectionId },
  type: Types.DELETE_ALL_FILES_FROM_SECTION
});

export const changeToGroup = (fileId: string) => ({
  payload: { fileId },
  type: Types.CHANGE_TO_GROUP_REQUEST
});

export const setActiveIds = (
  sectionId: string,
  filesIds: string[],
  groupId: string
) => ({
  payload: { sectionId, filesIds, groupId },
  type: Types.SET_ACTIVE_IDS
});

export const toggleNewFileWindow = (id: string) => ({
  payload: id,
  type: Types.TOGGLE_NEW_FILE
});

export const clearActiveGroup = () => ({
  type: Types.CLEAR_ACTIVE_GROUP
});

export const handleSelectFromDriveModal = (isOpen: boolean) => ({
  payload: isOpen,
  type: Types.SELECT_FROM_DRIVE_MODAL
});

export const inviteToFile = (
  emails: string[],
  filesIds: string[],
  isNeedInvite: boolean
) => ({
  payload: { emails, filesIds, isNeedInvite },
  type: Types.INVITE_FILE_REQUEST
});

export const getFilesFeed = (sortBy?: string) => ({
  payload: { sortBy },
  type: Types.GET_FILES_FEED_REQUEST
});

export const clearShareFileError = () => ({
  type: Types.CLEAR_SHARE_ERROR
});

export const getNewFilesFeedAmount = () => ({
  type: Types.GET_FILES_FEED_NEW_AMOUNT_REQUEST
});
