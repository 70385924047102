import Types from '../reducers/payments​/types';
import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import { openModalWindow, closeModalWindow } from '@redux/reducers/modal-window/actions';
import {
  PaymentDetailsModalWindows,
  UpdatePlanModalWindow
} from '@components/modal-window/modalWindowsTypes';

const middlware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);

  if (action.type === Types.FETCH_INVOICE_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.PAYMENTS_ERROR,
        onSuccess: Types.FETCH_INVOICE_SUCCESS,
        url: `/payments/invoice?
        ${action.payload.count ? `&count=${action.payload.count}` : ''}
        ${action.payload.offset ? `&offset=${action.payload.offset}` : ''}`
      })
    );
  }

  if (action.type === Types.FETCH_CARD_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.PAYMENTS_ERROR,
        onSuccess: Types.FETCH_CARD_SUCCESS,
        url: '/payments/card'
      })
    );
  }

  if (action.type === Types.CREATE_PAYMENTS_CARD_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.CREATE_PAYMENTS_CARD_ERROR,
        onSuccess: Types.CREATE_PAYMENTS_CARD_SUCCESS,
        url: `/payments/card`
      })
    );
  }
  if (action.type === Types.PAYMENTS_ERROR) {
    if (action.payload === 'HAS_OPEN_INVOICES') {
      store.dispatch(
        openModalWindow(UpdatePlanModalWindow.errorModal, 'updatePlan')
      );
    }
  }

  if (action.type === Types.CREATE_PAYMENTS_CARD_SUCCESS) {
    store.dispatch(closeModalWindow(PaymentDetailsModalWindows.editPaymentDetailsModal));
    store.dispatch(
        openModalWindow(PaymentDetailsModalWindows.successModal, 'payment')
    );
  }

  if (action.type === Types.SUBSCRIBE_TO_STRIPE) {
      store.dispatch(
          api({
              method: 'post',
              onError: Types.PAYMENTS_ERROR,
              onSuccess: Types.FETCH_CARD_SUCCESS,
              url: '/payments/subscribe'
          })
      );
  }
};

export default middlware as Middleware;
