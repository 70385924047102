import React from 'react';
import classNames from 'classnames';

import CheckMark from '@assets/svg/checkmark.svg?tag';
import styles from './styles.m.scss';

interface IProps {
  subscription: ISubscription;
}

const SubscriptionCard = (props: IProps) => {
  const { subscription } = props;

  const labelCurrentPlan = (
      <div>
          <div className={styles.groupCurrentPlan}>
              <p className={styles.labelCurrentPlan}>Total paid users: {subscription.quantity}</p>
          </div>
          <div className={styles.groupCurrentPlan}>
              <p className={styles.labelCurrentPlan}>This month's forecast:</p>
              <p className={styles.labelCurrentPlan}>${subscription.quantity * subscription.plan.amount / 100}</p>
          </div>
      </div>
  );

  const getBtnPlanCard = (): React.ReactNode => {
    if (subscription.plan.active) {
      return labelCurrentPlan;
    } else {
      return null;
    }
  };

  return (
    <div className={styles.proposeCard}>
      <p className={classNames(styles.proposeTitle, subscription.plan.active && styles.selectedProposeTitle)}>Team</p>
      <div className={styles.proposeInfoBox}>
        <p className={styles.proposePrice}>
          <span
              className={classNames(
                  styles.proposeBoldPrice,
                  subscription.plan.active && styles.selectedProposeBoldPrice
              )}
          >
            ${subscription.plan.amount / 100}
          </span>
        </p>
        <span>per user/month</span>
      </div>
      <div className={styles.proposeCardBtnBox}>
        {getBtnPlanCard()}
      </div>
    </div>
  );
};

export default SubscriptionCard;
