import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  breakpoints: {
    values: {
      lg: 1200,
      md: 960,
      sm: 768,
      xl: 1920,
      xs: 500
    }
  },
  palette: {
    primary: {
      main: '#2979ff'
    },
    secondary: { main: '#000' }
  }
});
