import Types from './types';

const initialState: INotificationsState = {
  categoryPending: null,
  errors: [],
  eventPending: null,
  isEventWaiting: false,
  isLoading: false,
  notifications: {},
  readNotificationIdPending: '',
};

export default (state: INotificationsState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.ADD_NOTIFICATION: {
      const newState = {
        ...state,
        errors: [],
        isLoading: false
      } as INotificationsState;

      if (action.payload && !newState.notifications[action.payload.notification.id]) {
        const notification = action.payload.notification as INotification;
        notification.notificationMessage = action.payload.notificationMessage;
        newState.notifications[notification.id] = notification;
      }

      return newState;
    }

    case Types.SET_ADD_FILE_NOTIFICATION: {
      const newState = {
        ...state,
        errors: [],
        isLoading: false
      } as INotificationsState;

      newState.categoryPending = action.payload.category;
      newState.eventPending = action.payload.event;
      if (!action.payload.isSectionAvailable) {
        newState.isEventWaiting = true;
      }

      return newState;
    }

    case Types.SET_PERMISSIONS_CHANGED_NOTIFICATION: {
      const newState = {
        ...state,
        errors: [],
        isLoading: false
      } as INotificationsState;

      newState.categoryPending = action.payload.category;
      newState.eventPending = action.payload.event;
      if (!action.payload.isSectionAvailable) {
        newState.isEventWaiting = true;
      }

      return newState;
    }

    case Types.ADD_FILE_NOTIFICATION: {
      const newState = {
        ...state,
        errors: [],
        isLoading: false
      } as INotificationsState;

      if (newState.eventPending && newState.categoryPending) {
        let notification = `${newState.eventPending.displayName} has added ${action.payload.fileName} to ${newState.categoryPending.name}`;
        if (newState.isEventWaiting) {
          notification += `. Reopen ${newState.categoryPending.name} to view new file`;
          newState.isEventWaiting = false;
        }
        newState.notifications[newState.eventPending.id] = newState.eventPending;
        newState.notifications[newState.eventPending.id].notificationMessage = notification;
        newState.eventPending = null;
        newState.categoryPending = null;
      }

      return newState;
    }

    case Types.READ_NOTIFICATION_REQUEST: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: true,
        readNotificationIdPending: action.payload
      };
    }

    case Types.READ_ALL_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: true
      };
    }

    case Types.READ_NOTIFICATION_CALLBACK: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      if (newState.notifications[newState.readNotificationIdPending]) {
        newState.notifications = { ...state.notifications };
        delete newState.notifications[newState.readNotificationIdPending];
        newState.readNotificationIdPending = '';
      }

      return newState;
    }

    case Types.READ_ALL_NOTIFICATIONS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      newState.notifications = {};
      return newState;
    }

    case Types.READ_ALL_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };
    }

    default:
      return state;
  }
};

// selectors:
export const getNotificationsArray = (
  state: INotificationsState,
): INotification[] => {
  return state.notifications ? Object.values(state.notifications) : null;
};
