import React from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { toggleNotitficationsCategory } from '@redux/reducers/categories/actions';
import { toggleNotificationsProject } from '@redux/reducers/projects/actions';
import { Checkbox, FormControlLabel, Popover } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import classNames from 'classnames';

import { useStylesCheckbox } from '@styles/checkbox';
import styles from './styles.m.scss';

interface IReduxProps {
  categories: ICategory[];
  projects: ICategory[];
  activeTab: 'categories' | 'projects';
  toggleNotitficationsCategory: typeof toggleNotitficationsCategory;
  toggleNotificationsProject: typeof toggleNotificationsProject;
}

interface IOwnProps {
  popperEl: HTMLButtonElement | null;
  setPopperEl: (value: HTMLButtonElement | null) => void;
}

interface IProps extends IOwnProps, IReduxProps { }

const NotifiedOfUpdates = (props: IProps) => {
  const { categories, popperEl, setPopperEl, projects, activeTab } = props;
  const classes = useStylesCheckbox({});
  const items = activeTab === 'projects' ? projects : categories;

  const itemsRows = items?.map((item, index) => {
    const checkBoxCategory = (
      <Checkbox
        checked={item.subscribed}
        checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        className={classes.root}
        onClick={() => {
          if (activeTab === 'projects') {
            props.toggleNotificationsProject(item.id);
          } else {
            props.toggleNotitficationsCategory(item.id);
          }
        }}
      />
    );

    return (
      <FormControlLabel
        key={index}
        control={checkBoxCategory}
        label={item.name}
        className={styles.formControlCategories}
      />
    );
  });

  const popperAnchorOrigin: PopoverOrigin = {
    horizontal: 'left',
    vertical: 'center',
  };

  const popperTransformOrigin: PopoverOrigin = {
    horizontal: 'right',
    vertical: 'center',
  };

  return (
    <Popover
      anchorOrigin={popperAnchorOrigin}
      transformOrigin={popperTransformOrigin}
      open={!!popperEl}
      anchorEl={popperEl}
      onClose={() => setPopperEl(null)}
      className={styles.paperNotifiedOfUpdates}
    >
      <div className={classNames(styles.paperNotifiedOfUpdates, styles.fadeIn)}>
        <div className={styles.triangle} />
        <p className={styles.notifiedOfUpdatesLabel}>Get notified of updates</p>
        <div className={styles.categoriesContent}>
          <Scrollbars autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
            {itemsRows}
          </Scrollbars>
        </div>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state: IStore) => {
  return {
    activeTab: state.dashboardState.activeTab,
    categories: state.categoriesState.categories,
    projects: state.projectState.projects,
  };
};

const actions = {
  toggleNotificationsProject,
  toggleNotitficationsCategory,
};

export default connect(mapStateToProps, actions)(NotifiedOfUpdates);
