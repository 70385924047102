import Types from './types';

const initialState: IModalWindowState = {
  modalWindows: {}
};

export default (state: IModalWindowState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.OPEN_MODAL_WINDOW: {
      const newState = {
        ...state
      };

      newState.modalWindows = {
        ...newState.modalWindows,
        [action.payload.keyName]: action.payload.id
      };

      return newState;
    }

    case Types.CLOSE_MODAL_WINDOW: {
      const newState = {
        ...state
      };
      newState.modalWindows = { ...state.modalWindows };
      delete newState.modalWindows[action.payload];

      return newState;
    }

    default:
      return state;
  }
};
