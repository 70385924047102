import React from 'react';
import {Modal, Fade, Backdrop, Button, Chip, TextField} from '@material-ui/core';
import {
    FileTagModal
} from '@components/modal-window/modalWindowsTypes';
import styles from './styles.m.scss';
import {addNewFileTag, fetchTags} from '@redux/reducers/files/actions';
import { closeModalWindow, openModalWindow } from '@redux/reducers/modal-window/actions';
import ModalWindow from '@components/modal-window';
import {compose} from 'redux';
import {connect} from 'react-redux';

interface IProps {
    addNewFileTag: typeof addNewFileTag;
    modalWindows: any;
    closeModalWindow: typeof closeModalWindow;
}

interface IState {
    newTag: IFileTag;
    validationErrors: any;
}

class ModalAddTag extends React.Component<IProps, IState> {
    state: IState = {
        newTag: {
            color: '#e22828',
            description: '',
            name: '',
        } as IFileTag,
        validationErrors: {
            tagName: ''
        },
    };

    defineFileTagMessage = {
        text: (
            <p/>
        ),
        title: <h1>Define new file tag</h1>
    };

    isModalWindowOpen = (type: string): boolean => {
        return (this.props.modalWindows
            && this.props.modalWindows[type]
            && this.props.modalWindows[type] === 'fileTag');
    };

    addNewFileTag = (confirm: boolean): void => {
        if (confirm) {
            if (this.state.newTag.name.length < 2) {
                this.setState({validationErrors: {
                        tagName: 'Tag name must be at least 2 characters long.'
                    }});
                return;
            }
            this.props.addNewFileTag(this.state.newTag);
        }
        this.setState({validationErrors: {
                tagName: ''
            }});
        this.props.closeModalWindow(FileTagModal.addNewModal);
    };

    render() {

        return (
            <ModalWindow
                type="form"
                message={this.defineFileTagMessage}
                open={this.props.modalWindows[FileTagModal.addNewModal] === 'fileTag'}
                onClose={this.addNewFileTag}
                isLoading={false}
            >
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', maxWidth: '400px' }}>
                    <TextField
                        error={this.state.validationErrors.tagName.length > 0}
                        variant="outlined"
                        type="text"
                        label="Name"
                        placeholder="ie. preview ready"
                        value={this.state.newTag.name}
                        helperText={this.state.validationErrors.tagName}
                        onChange={(event) => {
                            if (event.target.value.length > 15) { return; }
                            const newName = event.target.value;
                            this.setState((prevState) => ({
                                newTag: {
                                    ...prevState.newTag,
                                    name: newName
                                }
                            }));
                        }}
                    />
                    <span className={styles.customDropdown}>
                            <input
                                className={styles.droplet}
                                type="color"
                                value={this.state.newTag.color}
                                onChange={(event) => {
                                    if (!event.target?.value) { return; }
                                    const newColor = event.target.value;
                                    this.setState((prevState) => ({
                                        newTag: {
                                            ...prevState.newTag,
                                            color: newColor
                                        }
                                    }));
                                }}
                            />
                        </span>
                </div>
                <div className={styles.commonTextField}>
                    <TextField
                        error={this.state.newTag.description.length > 100}
                        className={styles.commonTextField}
                        id="outlined-multiline-flexible"
                        label="Description (Optional)"
                        placeholder="ie. This item is ready for preview"
                        multiline={true}
                        maxRows={4}
                        value={this.state.newTag.description}
                        onChange={(event) => {
                            if (event.target.value.length > 100) { return; }
                            const newDescription = event.target.value;
                            this.setState((prevState) => ({
                                newTag: {
                                    ...prevState.newTag,
                                    description: newDescription
                                }
                            }));
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{marginTop: '15px'}}>
                    <Chip
                        label={this.state.newTag.name.length > 0 ? this.state.newTag.name : 'preview'}
                        style={{ backgroundColor: this.state.newTag.color }}
                    />
                </div>
            </ModalWindow>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        modalWindows: state.modalWindowState.modalWindows,
    };
};

const actions = {
    addNewFileTag,
    closeModalWindow,
};

export default compose(connect(mapStateToProps, actions))(ModalAddTag);
