import React, { Component, ReactNode } from 'react';
import classnames from 'classnames';
import './image-tooltip-styles.scss';
import { connect } from 'react-redux';
import { requestTokenUpdate } from '@redux/reducers/auth/actions';
import { adjustFilePermissionsById, clearPreviewAllowed } from '@redux/reducers/files/actions';
import Plausible from 'plausible-tracker';

interface IProps {
    showTooltip: any;
    tooltipX: number;
    tooltipY: number;
}

interface IReduxProps {
    children: ReactNode;
    isPreviewAllowed: boolean;
    file: IFile;
    adjustFilePermissionsById: typeof adjustFilePermissionsById;
    clearPreviewAllowed: typeof clearPreviewAllowed;
}

const mapStateToProps = (state: IStore) => ({
    isPreviewAllowed: state.filesState.isPreviewAllowed
});

class ImageTooltip extends Component<IReduxProps, IProps> {
    tooltipTimer: ReturnType<typeof setTimeout> | null = null;
    state = {
        showTooltip: false,
        tooltipX: 0,
        tooltipY: 0
    } as IProps;

    plausible = Plausible({
        domain: `${process.env.DOMAIN}`
    });

    handleTooltipDelay = () => {
        this.tooltipTimer = setTimeout(() => {
            this.props.adjustFilePermissionsById(this.props.file.id);
            this.setState({
                showTooltip: true
            });
            this.plausible.trackEvent('Preview File');
        }, 300);
    };

    handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        if (this.tooltipTimer === null) {
            this.handleTooltipDelay();
            this.setState({
                tooltipX: event.clientX,
                tooltipY: event.clientY,
            });
        }
    };

    handleMouseLeave = () => {
        if (this.tooltipTimer) {
            clearTimeout(this.tooltipTimer);
            this.tooltipTimer = null;
        }

        this.props.clearPreviewAllowed();
        this.setState({
            showTooltip: false,
        });
    };

    render() {
        const { children, isPreviewAllowed } = this.props;
        const previewClass = classnames({
            'not-waiting': !this.state.showTooltip || isPreviewAllowed,
            'waiting': this.state.showTooltip && !isPreviewAllowed
        });

        return (
            <div className={previewClass}>
                <div className="tooltip-container">
                <span
                    className="tooltip-trigger"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {children}
                </span>
                    {this.state.showTooltip && this.props.file.data?.fileId && isPreviewAllowed && (
                        <img
                            className="tooltip"
                            style={{
                                left: `${this.state.tooltipX + 150}px`,
                                top: `${this.state.tooltipY - 200}px`
                            }}
                            src={`https://drive.google.com/thumbnail?authuser=0&sz=w320&id=${this.props.file.data?.fileId}`}
                            alt="Can't reach preview :("
                        />
                    )}
                </div>
            </div>
        );
    }
}

const actions = {
    adjustFilePermissionsById,
    clearPreviewAllowed,
    requestTokenUpdate
};

export default connect(mapStateToProps, actions)(ImageTooltip);
