import Types from './types';

export const addNotification = (notification: INotification, notificationMessage: string) => ({
  payload: { notification, notificationMessage },
  type: Types.ADD_NOTIFICATION
});

export const setAddFileNotification = (event: INotification, category: ICategory, isSectionAvailable: boolean) => ({
  payload: { event, category, isSectionAvailable },
  type: Types.SET_ADD_FILE_NOTIFICATION
});

export const addFileNotification = (fileName: string) => ({
  payload: { fileName },
  type: Types.ADD_FILE_NOTIFICATION
});

export const readNotification = (id: string) => ({
  payload: id,
  type: Types.READ_NOTIFICATION_REQUEST
});

export const readAllNotifications = () => ({
  type: Types.READ_ALL_NOTIFICATIONS_REQUEST
});

export const changePermissionNotification = (event: INotification, changedCategory: ICategory) => ({
  payload: { event, changedCategory },
  type: Types.CHANGE_PERMISSION_NOTIFICATION
});

export const removePermissionNotification = (event: INotification, changedCategory: ICategory) => ({
  payload: { event, changedCategory },
  type: Types.REMOVE_PERMISSION_NOTIFICATION
});
