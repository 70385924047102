import Types from './types';

const initialState: IProjectsState = {
  activeProject: null,
  errors: [],
  isLoadingOnFetch: false,
  isLoadingOnFetchUsers: false,
  isLoadingProject: false,
  pendingNotificProjectId: '',
  projects: new Array<ICategory>(),
  users: new Array<IProjectUser>()
};

export default (state: IProjectsState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.SET_ACTIVE_PROJECT: {
      const newState = {
        ...state
      };
      if (action.payload) {
        newState.activeProject = action.payload;
      } else {
        newState.activeProject = null;
      }

      return newState;
    }

    case Types.FETCH_PROJECTS_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingOnFetch: true,
        isLoadingProject: false
      };
    }

    case Types.NOTIFICATIONS_PROJECT_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingOnFetch: false,
        isLoadingProject: true,
        pendingNotificProjectId: action.payload
      } as IProjectsState;
    }

    case Types.CREATE_PROJECT_REQUEST:
    case Types.DELETE_PROJECT_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingOnFetch: false,
        isLoadingProject: true
      };
    }

    case Types.UPDATE_PROJECT_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetch: false,
        isLoadingProject: true
      };

      newState.projects.find(
        (project) => project.id === action.payload.id
      ).name = action.payload.name;

      return newState;
    }

    case Types.NOTIFICATIONS_PROJECT_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingOnFetch: false,
        pendingNotificProjectId: ''
      };
    }

    case Types.FETCH_PROJECTS_ERROR:
    case Types.CREATE_PROJECT_ERROR:
    case Types.DELETE_PROJECT_ERROR:
    case Types.CREATE_PROJECT_ERROR:
    case Types.CONVERT_PROJECT_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoadingOnFetch: false,
        isLoadingProject: false
      };
    }

    case Types.UPDATE_PROJECT_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoadingOnFetch: false,
        isLoadingProject: false
      };
    }

    case Types.FETCH_PROJECTS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetch: false,
        isLoadingProject: false
      };

      const projects = action.payload as ICategory[];
      newState.projects = projects;

      return newState;
    }

    case Types.CREATE_PROJECT_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetch: false,
        isLoadingProject: false,
        projects: [...state.projects]
      };
      const project = action.payload as ICategory;
      if (project.id) {
        newState.projects.push(action.payload);
      }

      return newState;
    }

    case Types.DELETE_PROJECT_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetch: false,
        isLoadingProject: false,
        projects: [...state.projects]
      };

      newState.projects = state.projects.filter(
        (project) => project.id !== newState.activeProject.id
      );
      return newState;
    }

    case Types.NOTIFICATIONS_PROJECT_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetch: false,
        isLoadingProject: false,
        projects: [...state.projects]
      };

      newState.projects.find(
        (project) => project.id === newState.pendingNotificProjectId
      ).subscribed = !newState.projects.find(
        (project) => project.id === newState.pendingNotificProjectId
      )?.subscribed;

      return newState;
    }

    case Types.UPDATE_PROJECT_SUCCESS: {
      return {
        ...state,
        errors: [],
        isLoadingOnFetch: false,
        isLoadingProject: false
      };
    }

    case Types.CONVERT_PROJECT_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoadingOnFetch: true,
        isLoadingProject: true
      };
    }

    case Types.CONVERT_PROJECT_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetch: false,
        isLoadingProject: false,
        projects: [...state.projects]
      };

      newState.projects = newState.projects.filter(
        (project) => project.id !== newState.activeProject.id
      );
      return newState;
    }

    case Types.FETCH_PROJECT_USERS_REQUEST: {
      return {
        ...state,
        isLoadingOnFetchUsers: true
      };
    }

    case Types.FETCH_PROJECT_USERS_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoadingOnFetchUsers: false
      };

      const users = action.payload.permissions as IProjectUser[];
      if (users.length) {
        newState.users = users;
      }

      return newState;
    }

    default:
      return state;
  }
};
