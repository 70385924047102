import React, { useState } from 'react';
import TooltipSet from '@components/tooltips';

import DragDrop from '@assets/svg/dragDrop.svg?tag';
import styles from './styles.m.scss';

interface IProps {
  isInvalid: boolean;
  updateFile: (file: File) => void;
}

const AddFileUpload = (props: IProps) => {
  const [file, setFile] = useState<File>(null);

  const handleFileUpload = (selectedFiles: FileList) => {
    setFile(selectedFiles[0]);
    props.updateFile(selectedFiles[0]);
  };

  const handleFileDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.type === 'drop') {
      setFile(e.dataTransfer.files[0]);
      props.updateFile(e.dataTransfer.files[0]);
    }
  };

  const getMaxFileSizeInMb = () => {
      let maxFileSize = 100;
      if (process.env.MAX_FILE_SIZE_UPLOAD !== undefined) {
          maxFileSize = parseInt(process.env.MAX_FILE_SIZE_UPLOAD, 10);
      }
      return maxFileSize;
  };

  const label = file ? (
    <p className={styles.nameOfFile}>{file.name}</p>
  ) : (
      <>
        {' '}
        <DragDrop />
        <p className={styles.dragDropTitle}>
          Drag and drop your file or click here for uploading
      </p>
      </>
    );

  const errorMessage = file
    ? `File has to be smaller then ${getMaxFileSizeInMb()}mb`
    : 'Please select a file to upload';

  return (
    <div className={styles.addFileUpload}>
      <input
        id="file"
        type="file"
        className={styles.fileInput}
        onChange={(e) => handleFileUpload(e.target.files)}
      />
      <TooltipSet
        message={errorMessage}
        placement="bottom"
        isOpen={props.isInvalid}
        type="error"
        isModal={true}
      >
        <label
          htmlFor="file"
          className={styles.uploadFile}
          onDrop={handleFileDrop}
          onDragEnter={handleFileDrop}
          onDragOver={handleFileDrop}
        >
          {label}
        </label>
      </TooltipSet>
    </div>
  );
};

export default AddFileUpload;
