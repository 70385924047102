import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import Types from '../reducers/projects/types';
import { openModalWindow } from '@redux/reducers/modal-window/actions';
import { CategoriesModalWindows } from '@components/modal-window/modalWindowsTypes';
import { fetchSections } from '@redux/reducers/sections/actions';
import { clearActiveGroup } from '@redux/reducers/files/actions';
import {fetchCategories, getCategorySharableLink} from '@redux/reducers/categories/actions';

const middleware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);
  if (action.type === Types.FETCH_PROJECTS_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.FETCH_PROJECTS_ERROR,
        onSuccess: Types.FETCH_PROJECTS_SUCCESS,
        url: `/categories?type=project`
      })
    );
  }

  if (action.type === Types.CREATE_PROJECT_REQUEST) {
    store.dispatch(
      api({
        body: { ...action.payload, isProject: true },
        method: 'post',
        onError: Types.CREATE_PROJECT_ERROR,
        onSuccess: Types.CREATE_PROJECT_SUCCESS,
        url: `/categories`
      })
    );
  }

  if (action.type === Types.CREATE_PROJECT_SUCCESS) {
      store.dispatch(getCategorySharableLink(action.payload.id));
  }

  if (action.type === Types.UPDATE_PROJECT_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'put',
        onError: Types.UPDATE_PROJECT_ERROR,
        onSuccess: Types.UPDATE_PROJECT_SUCCESS,
        url: `/categories`
      })
    );
  }

  if (action.type === Types.DELETE_PROJECT_REQUEST) {
    store.dispatch(
      api({
        method: 'delete',
        onError: Types.DELETE_PROJECT_ERROR,
        onSuccess: Types.DELETE_PROJECT_SUCCESS,
        url: `/categories/${action.payload}`
      })
    );
  }

  if (action.type === Types.NOTIFICATIONS_PROJECT_REQUEST) {
    store.dispatch(
      api({
        method: 'put',
        onError: Types.NOTIFICATIONS_PROJECT_ERROR,
        onSuccess: Types.NOTIFICATIONS_PROJECT_SUCCESS,
        url: `/categories/notifications-toggle/${action.payload}`
      })
    );
  }

  if (action.type === Types.DELETE_PROJECT_SUCCESS) {
    store.dispatch(
      openModalWindow(CategoriesModalWindows.successModal, 'category')
    );
  }

  if (action.type === Types.SET_ACTIVE_PROJECT) {
    const project = action.payload as ICategory;
    if (project) {
      store.dispatch(fetchSections(project.id));
    }
  }

  if (action.type === Types.FETCH_PROJECTS_SUCCESS) {
    store.dispatch(clearActiveGroup());
  }

  if (action.type === Types.SYNC_PROJECT_REQUEST) {
    store.dispatch(
      api({
        body: { googleDriveDirectoryId: action.payload.directoryId },
        method: 'put',
        onError: Types.SYNC_PROJECT_ERROR,
        onSuccess: Types.SYNC_PROJECT_SUCCESS,
        url: `/files/sync-drive?categoryId=${action.payload.categoryId}`
      })
    );
  }

  if (action.type === Types.SYNC_PROJECT_SUCCESS) {
    if (store.getState().projectState.activeProject.id === action.payload.categoryId) {
      store.dispatch(fetchSections(action.payload.categoryId));
    }
  }

  if (action.type === Types.FETCH_PROJECT_USERS_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.FETCH_PROJECT_USERS_ERROR,
        onSuccess: Types.FETCH_PROJECT_USERS_SUCCESS,
        url: `/categories/${action.payload.id}/permissions`
      })
    );
  }

  if (action.type === Types.CONVERT_PROJECT_REQUEST) {
    store.dispatch(
      api({
        body: {
          name: action.payload.name,
          users: action.payload.users,
        },
        method: 'put',
        onError: Types.CONVERT_PROJECT_ERROR,
        onSuccess: Types.CONVERT_PROJECT_SUCCESS,
        url: `/categories/${action.payload.id}/convert`
      })
    );
  }

  if (action.type === Types.CONVERT_PROJECT_SUCCESS) {
    store.dispatch(fetchCategories());
  }
};

export default middleware as Middleware;
