enum Types {
  FETCH_CATEGORIES_REQUEST = '[Categories] Fetch request',
  FETCH_CATEGORIES_SUCCESS = '[Categories] Fetch success',
  FETCH_CATEGORIES_ERROR = '[Categories] Fetch error',

  CREATE_CATEGORY_REQUEST = '[Category] Create request',
  CREATE_CATEGORY_SUCCESS = '[Category] Create success',
  CREATE_CATEGORY_ERROR = '[Category] Create error',

  UPDATE_CATEGORY_REQUEST = '[Category] Update request',
  UPDATE_PROJECT_REQUEST = '[Project] Update request',
  UPDATE_CATEGORY_SUCCESS = '[Category] Update success',
  UPDATE_CATEGORY_ERROR = '[Category] Update error',
  UPDATE_CATEGORY_PERMISSION = '[Category] Update permission',
  REMOVE_CATEGORY_PERMISSION = '[Category] Remove permission',

  GET_CATEGORY_SHARABLE_LINK = '[Categories] Get Category Sharable Link',
  GET_CATEGORY_SHARABLE_LINK_SUCCESS = '[Categories] Get Category Sharable Link Success',
  GET_CATEGORY_SHARABLE_LINK_ERROR = '[Categories] Get Category Sharable Link Error',

  DELETE_CATEGORY_REQUEST = '[Category] Delete request',
  DELETE_CATEGORY_SUCCESS = '[Category] Delete success',
  DELETE_CATEGORY_ERROR = '[Category] Delete error',

  NOTIFICATIONS_CATEGORY_REQUEST = '[Category] Notifications​ toggle request',
  NOTIFICATIONS_CATEGORY_SUCCESS = '[Category] Notifications​ toggle success',
  NOTIFICATIONS_CATEGORY_ERROR = '[Category] Notifications​ toggle error',

  SET_ACTIVE_CATEGORY = '[Category] Set active',
  SET_ACTIVE_FILE_TAG_FILTER = '[Category] Set active file tags to filter',

  SYNC_CATEGORY_REQUEST = '[Category] Sync request',
  SYNC_CATEGORY_SUCCESS = '[Category] Sync success',
  SYNC_CATEGORY_ERROR = '[Category] Sync error',
}

export default Types;
