import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { Modal, Fade, Backdrop, Button, Checkbox, FormControlLabel, TableCell, TableRow, Table, TableBody } from '@material-ui/core';
import { useStylesCheckbox } from '@styles/checkbox';
import classNames from 'classnames';

import Close from '@assets/svg/close.svg?tag';
import styles from './styles.m.scss';

type DescriptionType = 'viewing permissions' | 'editorial permissions' | 'inviting';

interface IReduxProps {
  categories: ICategory[];
  projects: ICategory[];
}

interface IOwnProps {
  open: boolean;
  description: DescriptionType;
  user?: IUser;
  categoriesIds?: string[];
  setOpen: (value?: boolean) => void;
  onSaveCategories: (categoriesIds: string[], user?: IUser) => void;
}

interface IProps extends IReduxProps, IOwnProps { }

const AllCategories = (props: IProps) => {
  const { open, setOpen, categories, projects, user, categoriesIds, description } = props;
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState<string[]>([]);
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      const role = description === 'viewing permissions'
        ? 'user'
        : 'moderator';
      const categoryAndProjectFromPropsIds = user.categories
        .map((category) => category.role === role && category.categoryId);
      const categoriesFromPropsIds = categoryAndProjectFromPropsIds
        .filter((item) => props.categories.find((cat) => cat.id === item));
      const projectsFromPropsIds = categoryAndProjectFromPropsIds
        .filter((item) => props.projects.find((proj) => proj.id === item));
      setSelectedCategoriesIds(categoriesFromPropsIds);
      setSelectedProjectsIds(projectsFromPropsIds);
    } else if (categoriesIds) {
      setSelectedCategoriesIds(categoriesIds);
    }
  }, [user, categoriesIds]);

  const handleSelectAllCategories = (_event: React.ChangeEvent<{}>, isSelected: boolean) => {
    if (isSelected) {
      const ids = categories && categories.map((c) => c.id);
      setSelectedCategoriesIds(ids);
    } else {
      setSelectedCategoriesIds([]);
    }
  };

  const handleSelectAllProjects = (_event: React.ChangeEvent<{}>, isSelected: boolean) => {
    if (isSelected) {
      const ids = projects && projects.map((c) => c.id);
      setSelectedProjectsIds(ids);
    } else {
      setSelectedProjectsIds([]);
    }
  };

  // type any is necessary for getting value
  const handleChangeCategories = (event: any, checked: boolean) => {
    if (checked) {
      setSelectedCategoriesIds(selectedCategoriesIds.concat([event.currentTarget.value]));
    } else {
      setSelectedCategoriesIds(selectedCategoriesIds.filter((id) => id !== event.currentTarget.value));
    }
  };

  const handleChangeProjects = (event: any, checked: boolean) => {
    if (checked) {
      setSelectedProjectsIds(selectedProjectsIds.concat([event.currentTarget.value]));
    } else {
      setSelectedProjectsIds(selectedProjectsIds.filter((id) => id !== event.currentTarget.value));
    }
  };

  const classes = useStylesCheckbox({});

  const checkAllCategoriesSelected = categories?.length === selectedCategoriesIds.length;
  const checkAllProjectsSelected = projects?.length === selectedProjectsIds.length;

  const checkboxCategory = (categoryId?: string) => (
    <Checkbox
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      className={classes.root}
      value={categoryId}
      color="primary"
    />
  );

  const allCategoriesCheckbox = (
    <FormControlLabel
      className={styles.formControlCategoryCheckbox}
      checked={checkAllCategoriesSelected}
      onChange={handleSelectAllCategories}
      control={checkboxCategory()}
      label={'All categories'}
    />
  );

  const allProjectsCheckbox = (
    <FormControlLabel
      className={styles.formControlCategoryCheckbox}
      checked={checkAllProjectsSelected}
      onChange={handleSelectAllProjects}
      control={checkboxCategory()}
      label={'All projects'}
    />
  );

  const getCategoryTable = (): React.ReactElement[] => {
    const categoryTable: any[] = [];
    let offset = 2;
    for (let i = 0; i < props.categories?.length; i += offset) {
      const categoriesRows = categories
        ?.slice(i, i + offset)
        .map((item, index) => (
          <TableCell key={index}>
            <FormControlLabel
              className={styles.formControlCategoryCheckbox}
              checked={selectedCategoriesIds.includes(item.id)}
              onChange={handleChangeCategories}
              control={checkboxCategory(item.id)}
              label={item.name}
            />
          </TableCell>
        ));
      if (i === 0) {
        categoriesRows.unshift(<TableCell key={--i}>{allCategoriesCheckbox}</TableCell>);
        ++offset;
      }
      categoryTable.push(<TableRow key={i}>{categoriesRows}</TableRow>);
    }
    return categoryTable;
  };

  const getProjectTable = (): React.ReactElement[] => {
    const projectTable: any[] = [];
    let offset = 2;
    for (let i = 0; i < projects?.length; i += offset) {
      const projectsRows = projects
        ?.slice(i, i + offset)
        .map((item, index) => (
          <TableCell key={index}>
            <FormControlLabel
              className={styles.formControlCategoryCheckbox}
              checked={selectedProjectsIds.includes(item.id)}
              onChange={handleChangeProjects}
              control={checkboxCategory(item.id)}
              label={item.name}
            />
          </TableCell>
        ));
      if (i === 0) {
        projectsRows.unshift(<TableCell key={--i}>{allProjectsCheckbox}</TableCell>);
        ++offset;
      }
      projectTable.push(<TableRow key={i}>{projectsRows}</TableRow>);
    }
    return projectTable;
  };

  const closeAllCategoriesModal = () => {
    if (categoriesIds) {
      setSelectedCategoriesIds(categoriesIds);
    }
    setOpen(false);
  };

  const categoryCheckboxes = <Table><TableBody>{getCategoryTable()}</TableBody></Table>;
  const projectCheckboxes = <Table><TableBody>{getProjectTable()}</TableBody></Table>;

  return (
    <div>
      <Modal
        className={styles.modalAllCategories}
        open={open}
        onClose={closeAllCategoriesModal}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classNames(styles.paperAllCategories, styles.fadeIn)}>
            <h2 className={styles.allCategoriesTitle}>Select categories and projects {description !== 'inviting' && `permissions`}</h2>
            <p className={styles.allCategoriesDescription}>Choose categories and projects for {description}</p>
            <div className={styles.categoriesContent}>
              <Scrollbars
                autoHide={true}
                autoHideTimeout={1000}
                autoHideDuration={200}
                className={styles.categoriesScroll}
              >
                {props.categories.length > 0 && (
                  <>
                    <h3 className={styles.allCategoriesDescription}>Categories:</h3>
                    {categoryCheckboxes}
                  </>
                )}
                {props.projects.length > 0 && (
                  <>
                    <h3 className={styles.allCategoriesDescription}>Projects:</h3>
                    {projectCheckboxes}
                  </>
                )}
              </Scrollbars>
            </div>
            <footer className={styles.btnsAllCategories}>
              <Button
                variant="text"
                className={classNames(styles.btnAllCategories, styles.btnCancel)}
                onClick={closeAllCategoriesModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classNames(styles.btnAllCategories, styles.btnSave)}
                onClick={() => props.onSaveCategories(selectedCategoriesIds.concat(selectedProjectsIds), user)}
              >
                Save
              </Button>
            </footer>
            <Button className={styles.closeBtn} onClick={closeAllCategoriesModal}>
              <Close />
            </Button >
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStore) => {
  const categories = state.categoriesState.categories;
  const projects = state.projectState.projects;
  return {
    categories,
    projects,
  };
};

export default connect(mapStateToProps)(AllCategories);
