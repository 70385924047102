import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateFile } from '@redux/reducers/files/actions';
import {Modal, Backdrop, Fade, IconButton, Input, makeStyles} from '@material-ui/core';
import { isAdminOrModerator } from '@services/user-role';
import Files from '@pages/category/components/file';
import TooltipSet from '@components/tooltips';
import classNames from 'classnames';

import Close from '@assets/svg/close.svg?tag';
import styles from './styles.m.scss';

interface IReduxProps {
  activeGroup: IFile;
  activeCategory: ICategory;
  isSelectFromDrive: boolean;
  updateFile: typeof updateFile;
}

interface IOwnProps {
  sectionId: string;
  open: boolean;
  setOpen: (value?: boolean) => void;
}

interface IProps extends IReduxProps, IOwnProps { }

const ERROR_MESSAGE_GROUP_TITLE_REQUIRED = 'Name of the groups has to have at least 2 letters';
const DEFAULT_TITLE = 'Add a group title';
const DEFAULT_DESCRIPTION = 'Add Group Description';
const useStyles = makeStyles((theme) => ({
  modalDepth: {
    zIndex: theme.zIndex.drawer - 500
  }
}));

const AddGroupFiles = (props: IProps) => {
  const classes = useStyles({});
  const { open, sectionId, setOpen, activeGroup, activeCategory, isSelectFromDrive } = props;
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [titleInvalid, setTitleInvalid] = useState<boolean>(false);
  const [inputType, setTypeInput] = useState<FileInputType>('');

  const isLengthValid = (value: string) => value.trim().length > 1;

  const setInvalidGroupTitle = () => {
    setTypeInput('name');
    setTitleInvalid(true);
  };

  const onChangeGroupTitle = (value: string) => {
    if (value.replace(/\s+/g, '').length < 31) {
      if (titleInvalid) {
        setTitleInvalid(false);
      }
      setTitle(value);
    }
  };

  const onChangeGroupDescription = (value: string) => {
    if (value.replace(/\s+/g, '').length < 51) {
      setDescription(value);
    }
  };

  const toggleInput = (type: FileInputType) => {
    if (activeCategory && isAdminOrModerator(activeCategory.userRole)) {
      setTypeInput(type);
      if (type === 'name' && activeGroup) {
        setTitle(activeGroup.name);
      } else if (type === 'tags' && activeGroup) {
        setDescription(activeGroup.description);
      }
      setTitleInvalid(false);
    }
  };

  const handleUpdateFile = (type: FileInputType) => {
    if (type === 'name' && activeGroup && activeGroup.name !== '' && activeGroup.name !== title) {
      if (isLengthValid(title)) {
        props.updateFile(activeGroup, title, undefined);
        toggleInput('');
      } else {
        setTitleInvalid(true);
      }
    } else if (type === 'tags' && activeGroup && activeGroup.description !== description) {
      props.updateFile(activeGroup, activeGroup.name, undefined);
      toggleInput('');
    } else {
      toggleInput('');
    }
  };

  const handleKeyPress = (type: FileInputType, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event && event.key === 'Enter') {
      handleUpdateFile(type);
    }
  };

  const closeAddGroupFiles = () => {
    setOpen(false);
  };

  const titleLabelForActiveGroup = activeGroup && activeGroup.name !== '' ? activeGroup.name : DEFAULT_TITLE;
  const titleLabelForNewGroup = title !== '' ? title : DEFAULT_TITLE;

  const descriptionLabelForActiveGroup = activeGroup && activeGroup.description !== ''
    ? activeGroup.description : DEFAULT_DESCRIPTION;
  const descriptionLabelForNewGroup = description !== '' ? description : DEFAULT_DESCRIPTION;

  const titleLabel = activeGroup ? titleLabelForActiveGroup : titleLabelForNewGroup;
  const descriptionLabel = activeGroup ? descriptionLabelForActiveGroup : descriptionLabelForNewGroup;

  const titleContent = inputType === 'name' ? (
    <Input
      className={classNames(styles.groupInput, styles.groupTitle)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeGroupTitle(e.target.value)}
      autoFocus={true}
      value={title}
      error={titleInvalid}
      onBlur={() => handleUpdateFile('name')}
      onKeyPress={(event) => handleKeyPress('name', event)}
      placeholder={'Add a group title'}
    />
  ) : (
      <p
        onClick={() => toggleInput('name')}
        className={styles.titleLabel}
      >
        {titleLabel}
      </p>
    );

  const descriptionContent = inputType === 'tags' ? (
    <Input
      className={classNames(styles.groupInput, styles.groupDescription)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeGroupDescription(e.target.value)}
      autoFocus={true}
      value={description}
      onBlur={() => handleUpdateFile('tags')}
      onKeyPress={(event) => handleKeyPress('tags', event)}
      placeholder={'Add Group Description'}
    />
  ) : (
      <p
        onClick={() => toggleInput('tags')}
        className={styles.descriptionLabel}
      >
        {descriptionLabel}
      </p>
    );

  return (
    <div>
      <Modal
        className={classNames(styles.modalGroupFiles)}
        open={open && !isSelectFromDrive}
        onClose={closeAddGroupFiles}
        closeAfterTransition={open}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open && !isSelectFromDrive}>
          <div className={classNames(styles.paperGroupFiles, styles.fadeIn)}>
            <header className={styles.headerFileGroup}>
              <h2 className={styles.headerTitle}>File Group</h2>
              <IconButton className={styles.modalCloseBtn} onClick={closeAddGroupFiles}>
                <Close className={styles.modalClose} />
              </IconButton>
            </header>
            <div className={styles.contentGroupFiles}>
              <div className={styles.fileGroupInputs}>
                <TooltipSet
                  message={ERROR_MESSAGE_GROUP_TITLE_REQUIRED}
                  isOpen={titleInvalid}
                  type="error"
                  isModal={true}
                >
                  {titleContent}
                </TooltipSet>
                {descriptionContent}
              </div>
              <Files
                sectionId={sectionId}
                groupName={title}
                groupDescription={description}
                isContentGroupFilesModal={true}
                setInvalidGroupTitle={setInvalidGroupTitle}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div >
  );
};

const actions = {
  updateFile
};

const selectActiveCategory = ({ dashboardState, categoriesState, projectState }: IStore) => {
  return dashboardState.activeTab === 'projects' ? projectState.activeProject : categoriesState.activeCategory;
};

const mapStateToProps = (state: IStore) => ({
  activeCategory: selectActiveCategory(state),
  activeGroup: state.filesState.activeGroup,
  isSelectFromDrive: state.filesState.isSelectFromDrive
});

export default connect(mapStateToProps, actions)(AddGroupFiles);
