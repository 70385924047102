import Types from './types';

const initialState: IPaymentsState = {
  activePlan: null,
  card: null,
  countInvoices: 0,
  errors: [],
  invoices: {},
  isCardCreatingFailed: false,
  isLoading: false,
};

export default (state: IPaymentsState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.FETCH_CARD_REQUEST:
    case Types.SUBSCRIBE_TO_STRIPE:
    case Types.FETCH_INVOICE_REQUEST:
    case Types.CREATE_PAYMENTS_CARD_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: true
      };
    }

    case Types.FETCH_INVOICE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      const invoices = action.payload as IInvoice[];

      if (invoices) {
        newState.invoices = {};
        invoices.map((invoice) => {
          newState.invoices[invoice.id] = invoice;
        });
        newState.countInvoices = Object.keys(newState.invoices).length;
      }

      return newState;
    }

    case Types.FETCH_CARD_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false
      };

      const response = action.payload;

      if (response && response.id) {
        newState.card = {
          id: response.id,
          last4: response.last4,
          name: response.name
        };
      }

      return newState;
    }

    case Types.CREATE_PAYMENTS_CARD_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isCardCreatingFailed: false,
        isLoading: false
      };

      const response = action.payload;

      if (response && response.id) {
        newState.card = {
          id: response.id,
          last4: response.last4,
          name: response.name
        } as ICard;
      }

      return newState;
    }

    case Types.UPGRADE_PLAN_WITHOUT_CARD: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isUpgradePlanWithoutCard: action.payload.isUpgradePlanWithoutCard,
        updatePlan: action.payload.plan
      } as IPaymentsState;
    }

    case Types.CLEAR_CREATING_CARD_FAILED: {
      return {
        ...state,
        errors: new Array<string>(),
        isCardCreatingFailed: false,
        isLoading: false
      } as IPaymentsState;
    }

    case Types.CREATE_PAYMENTS_CARD_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isCardCreatingFailed: true,
        isLoading: false
      } as IPaymentsState;
    }

    case Types.PAYMENTS_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false
      } as IPaymentsState;
    }

    default:
      return state;
  }
};

export const getInvoices = (state: IPaymentsState): IInvoice[] => {
  return state.invoices ? Object.values(state.invoices) : null;
};
