import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps
} from 'react-router-dom';
import { connect } from 'react-redux';

interface IProps extends RouteProps {
  companyId: string;
}

const PrivateRoute = ({ component, companyId, ...rest }: IProps) => {
  const Component = component;
  const render = (props: RouteComponentProps<any>): React.ReactNode => {
    if (companyId) {
      return <Component {...props} />;
    }
    return <Redirect to="/auth" />;
  };

  return <Route {...rest} render={render} />;
};

const mapStateToProps = (store: IStore) => ({
  companyId:
    store.auth.profile && store.auth.profile.company
      ? store.auth.profile.company.companyId
      : null
});
export default connect(mapStateToProps)(PrivateRoute);
