import Types from './types';
import React from 'react';

export const setNewWizardStep = (newWizardStep: any) => ({
  payload: newWizardStep,
  type: Types.ADD_WIZARD_STEP_REQUEST
});

export const setActiveWizardStep = (activeStep: number) => ({
  payload: activeStep,
  type: Types.SET_WIZARD_ACTIVE_STEP_REQUEST
});

export const setWizardIsActive = (isActive: boolean) => ({
  payload: isActive,
  type: Types.SET_WIZARD_IS_ACTIVE
});
