import React from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { clearCreatingCardFailed } from '@redux/reducers/payments​/actions';
import {
  fetchUsers, deleteUser, switchUserStatus, reinviteUser, shareCategory, shareInvitedCategory,
  clearCategory, clearInvitedCategory, switchUserRole, setStatusUserList, clearInviteError
} from '@redux/reducers/auth/actions';
import {
  getUsersArray, getUsersViewCategoriesAndProjects, getUsersEditCategoriesAndProjects, getActiveUsers, getPendingUsers,
  getDeactivatedUsers
} from '@redux/reducers/auth';
import { openModalWindow, closeModalWindow } from '@redux/reducers/modal-window/actions';
import {
  Button, TableRow, TableCell, Table, TableBody, IconButton,
  FormControlLabel, withStyles, MenuItem, Menu
} from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UpgradePlan from '@components/modal-upgrade-plan';
import InviteNewUser from '@components/modal-invite-new-user';
import ModalWindow from '@components/modal-window';
import AllCategories from '@components/modal-all-categories';
import { InviteUserModalWindow, UpgradeModalWindows, UpdatePlanModalWindow, PaymentDetailsModalWindows } from '@components/modal-window/modalWindowsTypes';
import AutomaticalUpgradePlan from '@components/modal-automatical-upgrade-plan';
import EditPaymentDetails from '@components/modal-edit-payment-details';
import { CheckBox } from './components/checkbox';
import { isModalWindowOpen } from '@services/modal-windows';
import { isAdmin, isAdminOrModerator, isModerator } from '@services/user-role';
import classNames from 'classnames';

import TrashBin from '@assets/svg/trashBin.svg?tag';
import styles from './styles.m.scss';

const MenuUsersItem = withStyles(() => ({
  root: {
    color: '#007BFF',
    fontSize: '14px',
    minHeight: 'unset',
    padding: '5px 15px',
    width: '124px',
  }
}))(MenuItem);

type PermissionsType = 'viewing permissions' | 'editorial permissions';

interface IReduxProps {
  activePlan: IPlan;
  activeUsers: IUser[];
  allUsers: IUser[];
  card: ICard;
  count: number;
  clearCategory: typeof clearCategory;
  clearInvitedCategory: typeof clearInvitedCategory;
  deactivatedUsers: IUser[];
  users: IUser[];
  modalWindows: any;
  statusUserList: UserListStatusType;
  pendingUsers: IUser[];
  viewCategories: IUserCategory;
  editCategories: IUserCategory;
  inviteError: string;
  isCardCreatingFailed: boolean;
  clearInviteError: typeof clearInviteError;
  clearCreatingCardFailed: typeof clearCreatingCardFailed;
  fetchUsers: typeof fetchUsers;
  shareInvitedCategory: typeof shareInvitedCategory;
  setStatusUserList: typeof setStatusUserList;
  switchUserRole: typeof switchUserRole;
  switchUserStatus: typeof switchUserStatus;
  deleteUser: typeof deleteUser;
  openModalWindow: typeof openModalWindow;
  shareCategory: typeof shareCategory;
  reinviteUser: typeof reinviteUser;
  closeModalWindow: typeof closeModalWindow;
}

const mapStateToProps = (state: IStore) => {
  const statusUserList = state.auth.users.statusUserList;
  const allUsers = getUsersArray(state.auth);
  const activeUsers = getActiveUsers(allUsers);
  const pendingUsers = getPendingUsers(allUsers);
  const deactivatedUsers = getDeactivatedUsers(allUsers);
  const users = getStatusUserList(statusUserList, allUsers, activeUsers, deactivatedUsers, pendingUsers);
  const viewCategories = getUsersViewCategoriesAndProjects(state, users);
  const editCategories = getUsersEditCategoriesAndProjects(state, users);
  return {
    activePlan: state.paymentsState.activePlan,
    activeUsers,
    allUsers: users,
    card: state.paymentsState.card,
    count: state.auth.users.count,
    deactivatedUsers,
    editCategories,
    inviteError: state.auth.inviteError,
    isCardCreatingFailed: state.paymentsState.isCardCreatingFailed,
    modalWindows: state.modalWindowState.modalWindows,
    pendingUsers,
    statusUserList,
    users,
    viewCategories,
  };
};

const actions = {
  clearCategory,
  clearCreatingCardFailed,
  clearInviteError,
  clearInvitedCategory,
  closeModalWindow,
  deleteUser,
  fetchUsers,
  openModalWindow,
  reinviteUser,
  setStatusUserList,
  shareCategory,
  shareInvitedCategory,
  switchUserRole,
  switchUserStatus,
};

interface IState {
  activeUser: IUser;
  isAllCategoriesOpen: boolean;
  isAutomaticalUpgradePlanOpen: boolean;
  isUpgradePlanOpen: boolean;
  newActivePlan: IPlan;
  pageUsers: number;
  permissions: PermissionsType;
  rowsPerPageUsers: number;
  selectedCategoriesIds: {
    [userId: string]: {
      [categoryId: string]: string;
    };
  };
  selectedPlan: IPlan;
  usersOptionEl: HTMLElement | null;
}

class UserList extends React.Component<IReduxProps, IState> {
  state = {
    activeUser: null,
    isAllCategoriesOpen: false,
    isAutomaticalUpgradePlanOpen: false,
    isUpgradePlanOpen: false,
    newActivePlan: null,
    pageUsers: 0,
    permissions: 'editorial permissions',
    rowsPerPageUsers: 9,
    selectedCategoriesIds: {},
    selectedPlan: null,
    usersOptionEl: null,
  } as IState;

  componentDidMount = () => {
    this.props.fetchUsers();
  };

  handleChangeUserRole = (user: IUser, checked: boolean) => {
    const role = checked ? 'moderator' : 'user';
    if (user.isActive !== undefined) {
      this.props.switchUserRole(user.id, role, false);
    } else {
      this.props.switchUserRole(user.id, role, true);
    }
  };

  handleChangeCategories = (user: IUser, categoryId: string, role: UserRoleType, checked: boolean) => {
    if (!checked) {
      if (user.isActive !== undefined) {
        this.props.clearCategory(user.id, [categoryId]);
      } else {
        this.props.clearInvitedCategory(user.id, [categoryId]);
      }
    } else {
      if (user.isActive !== undefined) {
        this.props.shareCategory([user.data.email], [categoryId], role);
      } else {
        this.props.shareInvitedCategory(user.id, [categoryId], role);
      }
    }
  };

  handleChangeCategoriesPermissions = (categoriesIds: string[], user: IUser) => {
    this.handleAllCategoriesModal(false);
    this.setState({ activeUser: null });
    const addedCategoriesIds: string[] = [];
    const removedCategoriesIds: string[] = [];
    const role = this.state.permissions === 'editorial permissions'
      ? 'moderator'
      : 'user';
    user.categories.map((category) => {
      const selectCategory = categoriesIds?.find((id) => category.categoryId === id);
      if (selectCategory && category.role !== role) {
        addedCategoriesIds.push(category.categoryId);
      } else if (!selectCategory && category.role === role) {
        removedCategoriesIds.push(category.categoryId);
      }
    });
    categoriesIds.map((categoryId) => {
      const category = user.categories.find((cat) => cat.categoryId === categoryId);
      if (!category) {
        addedCategoriesIds.push(categoryId);
      }
    });
    if (user.isActive !== undefined) {
      if (addedCategoriesIds.length) {
        this.props.shareCategory([user.data.email], addedCategoriesIds, role);
      } else if (!user.categories?.length && categoriesIds?.length) {
        this.props.shareCategory([user.data.email], categoriesIds, role);
      }
      if (removedCategoriesIds.length) {
        this.props.clearCategory(user.id, removedCategoriesIds);
      }
    } else {
      if (addedCategoriesIds.length || (!user.categories?.length && categoriesIds?.length)) {
        this.props.shareInvitedCategory(user.id, addedCategoriesIds, role);
      } else if (!user.categories?.length && categoriesIds?.length) {
        this.props.shareInvitedCategory(user.id, categoriesIds, role);
      }
      if (removedCategoriesIds.length) {
        this.props.clearInvitedCategory(user.id, removedCategoriesIds);
      }
    }
  };

  handleInviteNewUserModal = (value: boolean) => {
    if (this.props.inviteError) {
      this.props.clearInviteError();
    }
    if (value) {
      this.props.openModalWindow(InviteUserModalWindow.inviteModal, 'inviteUser');
    } else {
      this.props.closeModalWindow(InviteUserModalWindow.inviteModal);
    }
  };

  handleAllCategoriesModal = (value: boolean) => {
    this.setState({ isAllCategoriesOpen: value });
    if (!value) {
      this.setState({ activeUser: null });
    }
  };

  setActiveUser = (user: IUser) => {
    this.setState({ activeUser: user });
    this.handleAllCategoriesModal(true);
  };

  handleInviteUser = () => {
    this.handleInviteNewUserModal(true);
  };

  closeUpgradeConfirmModal = (value: boolean) => {
    const { selectedPlan } = this.state;
    this.props.closeModalWindow(UpgradeModalWindows.confirmModal);
    if (value) {
      if (this.state.isUpgradePlanOpen) {
        this.setState({ isUpgradePlanOpen: false });
      }
    }
  };

  closeSuccessUpdatePlanModal = () => {
    this.props.closeModalWindow(UpdatePlanModalWindow.successModal);
  };

  closeErrorModal = () => {
    this.props.closeModalWindow(UpdatePlanModalWindow.errorModal);
  };

  handlePaymentModal = (value: boolean) => {
    if (this.props.isCardCreatingFailed) {
      this.props.clearCreatingCardFailed();
    }
    if (value) {
      if (this.state.isUpgradePlanOpen) {
        this.setState({ isUpgradePlanOpen: false });
      } else if (this.state.isAutomaticalUpgradePlanOpen) {
        this.setState({ isAutomaticalUpgradePlanOpen: false });
      }
      this.props.openModalWindow(PaymentDetailsModalWindows.editPaymentDetailsModal, 'payment');
    } else {
      this.props.closeModalWindow(PaymentDetailsModalWindows.editPaymentDetailsModal);
    }
  };

  onUpdatePlan = (plan: IPlan) => {
    const { activePlan, card } = this.props;
    this.setState({ selectedPlan: plan, newActivePlan: activePlan });
    if (this.state.isAutomaticalUpgradePlanOpen) {
      this.setState({ isAutomaticalUpgradePlanOpen: false });
    }
    if (!card) {
      if (this.state.isUpgradePlanOpen) {
        this.setState({ isUpgradePlanOpen: false });
      }
      this.props.openModalWindow(PaymentDetailsModalWindows.editPaymentDetailsModal, 'payment');
    } else {
      this.props.openModalWindow(UpgradeModalWindows.confirmModal, 'upgrade');
    }
  };

  changeCategory = (isDisabled: boolean, user: IUser, role: UserRoleType, category: ICategoryChecked) => {
    if (!isDisabled) {
      this.handleChangeCategories(user, category.id, role, !category.checked);
    }
  };

  handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ usersOptionEl: event.currentTarget });
  };

  handleUsersStatus = (value: UserListStatusType) => {
    this.props.setStatusUserList(value);
    this.setState({ usersOptionEl: null });
  };

  openAllCategoriesModal = (user: IUser, permissions: PermissionsType) => {
    this.setActiveUser(user);
    this.setState({ permissions });
  };

  getTableUsersHeight = () => {
    const { users } = this.props;
    const rowHeight = 57;
    const spaceHeight = 65;
    const maxHeight = rowHeight * 9 + spaceHeight;
    const usersHeight = users?.length * rowHeight + spaceHeight;
    return usersHeight > maxHeight ? maxHeight : usersHeight;
  };

  render() {
    const { activePlan, card,   viewCategories, editCategories,
       modalWindows, count, users, statusUserList } = this.props;
    const { isUpgradePlanOpen, isAllCategoriesOpen, activeUser,
      isAutomaticalUpgradePlanOpen, selectedPlan, newActivePlan, usersOptionEl, permissions } = this.state;

    const usersRows = users && users.map((user, key) => {
      const getStatus = () => {
        switch (user.isActive) {
          case true: return { color: '#28A745', label: 'Active' };
          case false: return { color: '#FBBB00', label: 'Deactivated' };
          case undefined: return { color: '#C4C4C4', label: 'Pending' };
        }
      };

      const status = getStatus();

      const noUserPhoto = (
        <div className={classNames(styles.userPhoto, styles.photoCameraIcon)}>
          <PhotoCameraIcon />
        </div>
      );

      const userPhoto = user.data
        ? <img src={user.data.photoUrl} alt={'User Photo'} className={styles.userPhoto} />
        : noUserPhoto;

      const userName = user.data ? user.data.displayName : user.email;

      const userData = (
        <div className={styles.userData}>
          {userPhoto}
          <p className={styles.userName}>{userName}</p>
        </div>
      );

      const disabledCheckbox = isAdmin(user.role) || status.label === 'Deactivated';

      const checkBoxUserRole = (
        <CheckBox
          id={user.id}
          checked={isAdminOrModerator(user.role)}
          disabled={disabledCheckbox}
        />
      );

      const formControlCheckboxStyle = classNames(
        styles.formControlCheckbox, disabledCheckbox && styles.disableFormControl,
        status.label === 'Deactivated' && styles.deactiveFormControl
      );

      const userRole = (
        <FormControlLabel
          className={formControlCheckboxStyle}
          onClick={() => !disabledCheckbox && this.handleChangeUserRole(user, !isAdminOrModerator(user.role))}
          control={checkBoxUserRole}
          label={<span onClick={(event) => event.preventDefault()}>Admin</span>}
        />
      );

      const categoryEditorialCheckboxes =
        editCategories &&
        editCategories[user.id]
          .slice(0, 2)
          .map((category, index) => {
            const checkBoxCategory = (
              <CheckBox
                id={category.id}
                checked={category.checked}
                disabled={disabledCheckbox}
              />
            );

            return (
              <FormControlLabel
                key={index}
                className={formControlCheckboxStyle}
                onClick={() => this.changeCategory(disabledCheckbox, user, 'moderator', category)}
                control={checkBoxCategory}
                label={<span onClick={(event) => event.preventDefault()}>{category.name}</span>}
              />
            );
          });

      const categoryViewingCheckboxes =
        viewCategories &&
        viewCategories[user.id]
          .slice(0, 2)
          .map((category, index) => {
            const isDisabledCategory = disabledCheckbox || isAdminOrModerator(user.role);

            const checkBoxCategory = (
              <CheckBox
                id={category.id}
                checked={category.checked || isAdminOrModerator(user.role)}
                disabled={isDisabledCategory}
              />
            );

            const formControlViewCheckboxStyle = classNames(formControlCheckboxStyle,
              isDisabledCategory && styles.disableFormControl);

            return (
              <FormControlLabel
                key={index}
                className={formControlViewCheckboxStyle}
                onClick={() => this.changeCategory(isDisabledCategory, user, 'user', category)}
                control={checkBoxCategory}
                label={<span onClick={(event) => event.preventDefault()}>{category.name}</span>}
              />
            );
          });

      const moreLabel = (permission: PermissionsType) => {
        if (viewCategories && viewCategories[user.id].length > 2) {
          const isDisabled = disabledCheckbox || (permission === 'viewing permissions' && isModerator(user.role));
          return (
            <Button
              className={classNames(styles.moreLabel, isDisabled && styles.disabledMoreLabel)}
              onClick={() => this.openAllCategoriesModal(user, permission)}
              disabled={isDisabled}
            >
              More...
            </Button>
          );
        }
      };

      const userEditorialCategories = (
        <div className={styles.usersCategoriesContent}>
          {categoryEditorialCheckboxes}
          {moreLabel('editorial permissions')}
        </div>
      );

      const userViewingCategories = (
        <div className={styles.usersCategoriesContent}>
          {categoryViewingCheckboxes}
          {moreLabel('viewing permissions')}
        </div>
      );

      const resentInviteBtn = (
        <Button
          variant="text"
          className={styles.btnResentInvite}
          onClick={() => this.props.reinviteUser(user.id)}
        >
          Resend invite
        </Button>
      );

      const userStatus = (
        <div className={styles.userStatus}>
          <div className={styles.userStatusBlock} style={{ background: status.color }}>
            <p className={styles.userStatusLabel}>{status.label}</p>
          </div>
          {status.label === 'Pending' && resentInviteBtn}
        </div >
      );

      const actionLabel = user.isActive === false ? 'Activate' : 'Deactivate';

      const isSwitchUserDisabled = status.label === 'Pending' || isAdmin(user.role)
        || (status.label === 'Deactivated');

      const switchStatusBtn = !isSwitchUserDisabled && (
        <Button
          className={styles.actionLabel}
          onClick={() => this.props.switchUserStatus(user.id)}
        >
          {actionLabel}
        </Button>
      );

      const trashBtn = !isAdmin(user.role) && (
        <IconButton
          className={styles.trashBin}
          onClick={() => this.props.deleteUser(user.id, status.label === 'Pending')}
        >
          <TrashBin />
        </IconButton>
      );

      const userActions = (
        <div className={styles.userActions}>
          {switchStatusBtn}
          {trashBtn}
        </div>
      );

      return (
        <TableRow key={key}>
          <TableCell>{userData}</TableCell>
          <TableCell>{userRole}</TableCell>
          <TableCell>{userEditorialCategories}</TableCell>
          <TableCell>{userViewingCategories}</TableCell>
          <TableCell>{userStatus}</TableCell>
          <TableCell>{userActions}</TableCell>
        </TableRow>
      );
    });

    const usersTable = (
      <Scrollbars autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
        <Table>
          <TableBody className={styles.userTableBody}>
            <TableRow className={styles.headerUserTable}>
              <TableCell>User</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Editorial permissions</TableCell>
              <TableCell>Viewing permissions</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
            {usersRows}
          </TableBody>
        </Table>
      </Scrollbars>
    );

    const noUser = <p className={styles.noUsersLabel}>No {statusUserList?.toLocaleLowerCase()} users.</p>;

    const usersContent = usersRows.find((user) => user !== undefined) ? usersTable : noUser;

    const inviteNewUsersModal =
      isModalWindowOpen(modalWindows, InviteUserModalWindow.inviteModal, 'inviteUser') && (
        <InviteNewUser
          open={isModalWindowOpen(modalWindows, InviteUserModalWindow.inviteModal, 'inviteUser')}
          setOpen={this.handleInviteNewUserModal}
          modalWindows={modalWindows}
        />
      );

    const confirmMessageUpgrade = {
      action: ', upgrade!',
      text: (
        <p>
          You will be able to add more users!
        </p>
      ),
      title: (
        <h1>
          Are you sure you want to upgrade your <br />
          <span>{activePlan} </span> plan
          to <span>{selectedPlan}</span> plan?
        </h1>
      )
    };

    const errorMessageUpdatePlan = {
      action: 'Plan Update Failed!',
      text: (
        <p>
          Your have <span>unconfirmed payments</span>
        </p>
      ),
      title: <h1>Plan Update Failed!</h1>
    };

    const successMessageUpdatePlan = {
      action: 'Plan Update Successfully Requested!',
      text: (
        <p>
          Your account will change from <br />
          the <span>{newActivePlan}</span> plan to the
          <span> {selectedPlan}</span> plan
        </p>
      ),
      title: <h1>Plan Update Successfully Requested!</h1>
    };

    const successInviteUser = {
      action: 'Users Invited!',
      text: <p>Users have been successfully invited</p>,
      title: <h1>Users Invited!</h1>
    };

    return (
      <div className={styles.userListContent} >
        <div className={styles.headerUserList}>
          <Button
            variant="outlined"
            className={classNames(styles.btnHeaderUserList, styles.btnAllUsers)}
            onClick={this.handleOpenOptions}
          >
            {statusUserList}
            <ArrowDropDownIcon className={styles.dropDown} />
          </Button>
          <Menu
            elevation={1}
            anchorEl={usersOptionEl}
            open={Boolean(usersOptionEl)}
            onClose={() => this.setState({ usersOptionEl: null })}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuUsersItem onClick={() => this.handleUsersStatus('All Users')}>
              All Users
            </MenuUsersItem>
            <MenuUsersItem onClick={() => this.handleUsersStatus('Active')}>
              Active
            </MenuUsersItem>
            <MenuUsersItem onClick={() => this.handleUsersStatus('Deactivated')}>
              Deactivated
            </MenuUsersItem>
            <MenuUsersItem onClick={() => this.handleUsersStatus('Pending')}>
              Pending
            </MenuUsersItem>
          </Menu>
          <Button
            variant="contained"
            className={classNames(styles.btnHeaderUserList, styles.btnInviteUser)}
            onClick={this.handleInviteUser}
          >
            Invite user
          </Button>
        </div>
        <div className={styles.usersContent} style={{ height: `${this.getTableUsersHeight()}px` }}>
          {usersContent}
        </div>
        {inviteNewUsersModal}
        <AllCategories
          open={isAllCategoriesOpen}
          user={activeUser}
          description={permissions}
          setOpen={this.handleAllCategoriesModal}
          onSaveCategories={this.handleChangeCategoriesPermissions}
        />
        <ModalWindow
          type="confirm"
          message={confirmMessageUpgrade}
          open={isModalWindowOpen(modalWindows, UpgradeModalWindows.confirmModal, 'upgrade')}
          onClose={this.closeUpgradeConfirmModal}
        />
        <ModalWindow
          type="success"
          message={successMessageUpdatePlan}
          open={isModalWindowOpen(modalWindows, UpdatePlanModalWindow.successModal, 'updatePlan')}
          onClose={this.closeSuccessUpdatePlanModal}
        />
        <ModalWindow
          type="auth_modal"
          message={errorMessageUpdatePlan}
          open={isModalWindowOpen(modalWindows, UpdatePlanModalWindow.errorModal, 'updatePlan')}
          onClose={this.closeErrorModal}
        />
        <ModalWindow
          type="success"
          message={successInviteUser}
          open={isModalWindowOpen(
            modalWindows,
            InviteUserModalWindow.successModal,
            'inviteUser'
          )}
          onClose={() =>
            this.props.closeModalWindow(InviteUserModalWindow.successModal)
          }
        />
      </div >
    );
  }
}

const getStatusUserList = (
  statusUserList: UserListStatusType,
  allUsers: IUser[],
  activeUsers: IUser[],
  deactivatedUsers: IUser[],
  pendingUsers: IUser[]
): IUser[] => {
  switch (statusUserList) {
    case 'Active': return activeUsers;
    case 'Pending': return pendingUsers;
    case 'Deactivated': return deactivatedUsers;
    case 'All Users': return allUsers;
  }
};

export default connect(mapStateToProps, actions)(UserList);
