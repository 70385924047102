import axios from 'axios';
import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import loadScript from 'load-script';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  ListItem,
  Input,
  IconButton,
  Divider,
  ListItemText,
  Button
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { connect } from 'react-redux';
import {
  fetchProjects,
  createProject,
  updateProject,
  setActiveProject,
  deleteProject,
  syncProjectWithDriveDirectory
} from '@redux/reducers/projects/actions';
import {
  openModalWindow,
  closeModalWindow
} from '@redux/reducers/modal-window/actions';
import { isAdmin, isAdminOrModerator } from '@services/user-role';
import Loader from '@components/loader';
import ModalWindow from '@components/modal-window';
import NotifiedOfUpdates from '@components/notified-of-updates';
import { requestTokenUpdate } from '@redux/reducers/auth/actions';
import { CategoriesModalWindows } from '@components/modal-window/modalWindowsTypes';
import TooltipSet from '@components/tooltips';
import Share from '@components/modal-share';

import PersonAdd from '@assets/svg/personAdd.svg?tag';
import TrashBin from '@assets/svg/trashBin.svg?tag';
import Bell from '@assets/svg/bell.svg?tag';
import Pen from '@assets/svg/pen.svg?tag';
import styles from './styles.m.scss';
import {getCategorySharableLink} from '@redux/reducers/categories/actions';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

interface IReduxProps {
  accessToken: string;
  projects: ICategory[];
  activeProject: ICategory;
  roleOfUser: UserRoleType;
  createProject: typeof createProject;
  deleteProject: typeof deleteProject;
  fetchProjects: typeof fetchProjects;
  updateProject: typeof updateProject;
  setActiveProject: typeof setActiveProject;
  getCategorySharableLink: typeof getCategorySharableLink;
  requestTokenUpdate: typeof requestTokenUpdate;
  syncProjectWithDriveDirectory: typeof syncProjectWithDriveDirectory;

  errors: string[];
  isLoadingOnFetch: boolean;
  isLoadingProject: boolean;

  modalWindows: any;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
}

interface IProps extends IReduxProps {
  history: any;
  index: number;
  value: number;
}

interface IState {
  errorMessage: string;
  isCreating: boolean;
  isEditMode: boolean;
  isEditNewActive: boolean;
  isNewProjectValid: boolean;
  isNewNameValid: boolean;
  isOpenShareModal: boolean;
  newName: string;
  pickedFolderId: string;
  notificationEl: HTMLButtonElement | null;
}

const Errors = Object.freeze({
  nameIsNotUnique: 'Name of projects has to be unique',
  nameIsSmall: 'Name of the projects has to have at least 3 letters'
});

class Projects extends Component<IProps, IState> {
  state: IState = {
    errorMessage: '',
    isCreating: false,
    isEditMode: false,
    isEditNewActive: false,
    isNewNameValid: true,
    isNewProjectValid: true,
    isOpenShareModal: false,
    newName: '',
    notificationEl: null,
    pickedFolderId: '',
  };

  _projectInputRef = createRef<HTMLInputElement>();
  _newProjectInputRef = createRef<HTMLInputElement>();

  onApiLoad = () => {
    (window as any).gapi.load('picker');
  };

  componentDidMount = () => {
    loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    this.props.fetchProjects();
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.isLoadingProject !== this.props.isLoadingProject
        && !this.props.isLoadingProject
        && this.state.isCreating) {
      if (!this.props.errors.length) {
        this.toggleNewProject();
      } else {
        this.setState({ errorMessage: Errors.nameIsNotUnique, isNewProjectValid: false });
      }
    } else if (prevProps.isLoadingProject !== this.props.isLoadingProject && !this.props.isLoadingProject) {
      if (!this.props.errors.length) {
        this.setState({ isEditMode: false });
      } else {
        this.setState({ errorMessage: Errors.nameIsNotUnique, isNewNameValid: false });
      }
    }
  };

  isLengthValid = (value: string) => {
    if (!(value.trim().length > 1)) {
      this.setState({ errorMessage: Errors.nameIsSmall });
      return false;
    } else {
      return true;
    }
  };

  isValueUnique = (value: string, isNew: boolean) => {
    if (this.props.projects) {
      const project = this.props.projects.find(
        (p) =>
          p.name === value &&
          (isNew || p.name !== this.props.activeProject.name)
      );
      if (project) {
        this.setState({ errorMessage: Errors.nameIsNotUnique });
        return false;
      }
    }
    return true;
  };

  isFieldValid = (value: string, isNew: boolean) => {
    if (this.isLengthValid(value) && this.isValueUnique(value, isNew)) {
      return true;
    } else {
      return false;
    }
  };

  updateSelected = (selectedProject: ICategory) => {
    if (
      selectedProject &&
      (!this.props.activeProject ||
        selectedProject.id !== this.props.activeProject.id)
    ) {
      this.setState({
        errorMessage: '',
        isEditMode: false,
        isEditNewActive: false,
        isNewNameValid: true,
        isNewProjectValid: true,
        newName: selectedProject.name
      });
    }
  };

  updateSelectedName = (newName: string) => {
    if (newName !== this.props.activeProject.name) {
      this.props.updateProject(this.props.activeProject.id, newName);
    } else {
      this.setState({ isEditMode: false });
    }

    this.setState({
      newName
    });
  };

  deleteCategory = (value: boolean) => {
    this.props.closeModalWindow(CategoriesModalWindows.confirmModal);
    if (value) {
      this.props.deleteProject(this.props.activeProject.id);
    }
  };

  closeModal = () => {
    this.props.closeModalWindow(CategoriesModalWindows.successModal);
    let newLink = '';
    if (this.props.projects.length) {
      newLink = `/categories/${this.props.projects[0].id}`;
    } else {
      this.props.setActiveProject(null);
    }
    this.props.history.push('/dashboard' + newLink);
  };

  iconClick = () => {
    this.setState({
      isEditMode: true,
      newName: this.props.activeProject.name
    });
  };

  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.currentTarget.value.replace(/\s+/g, '').length < 31) {
      this.setState({
        isNewNameValid: true,
        isNewProjectValid: true,
        newName: e.currentTarget.value
      });
    }
  };

  handleProjectRename = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (
        this._projectInputRef !== null &&
        this.isFieldValid(this._projectInputRef.current.value, false)
      ) {
        this.updateSelectedName(this._projectInputRef.current.value);
      } else if (!this._projectInputRef.current.value.trim()) {
        this.updateSelectedName(this.props.activeProject.name);
        this.setState({ isEditMode: false });
      } else {
        this.setState({
          isNewNameValid: false
        });
      }
    }
  };

  handleInputBlur = () => {
    if (this._projectInputRef && !this._projectInputRef.current) {
      this.setState({
        errorMessage: '',
        isEditMode: false,
        isNewNameValid: true
      });
    } else if (
      this._projectInputRef &&
      this.isFieldValid(this._projectInputRef.current.value, false)
    ) {
      this.setState({
        isEditMode: false
      });
      this.updateSelectedName(this._projectInputRef.current.value);
    } else if (!this._projectInputRef.current.value.trim()) {
      this.updateSelectedName(this.props.activeProject.name);
    } else {
      this.setState({
        isNewNameValid: false
      });
    }
  };

  toggleNewProject = () => {
    this.setState((pervState) => ({
      errorMessage: '',
      isCreating: false,
      isEditMode: false,
      isEditNewActive: !pervState.isEditNewActive,
      isNewNameValid: true,
      isNewProjectValid: true,
      newName: ''
    }));
  };

  handleProjectCreate = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      event.type === 'click' ||
      (event.type === 'keypress' &&
        (event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter')
    ) {
      if (
        this._newProjectInputRef &&
        this._newProjectInputRef.current.value &&
        this.isFieldValid(this._newProjectInputRef.current.value, true)
      ) {
        this.props.createProject(this._newProjectInputRef.current.value);
        this.setState({ isCreating: true });
      } else {
        this.setState({
          isNewProjectValid: false
        });
      }
    }
  };

  handleDeleteCategory = () => {
    this.props.openModalWindow(CategoriesModalWindows.confirmModal, 'category');
  };

  setNotificationEl = (value: HTMLButtonElement) => {
    this.setState({ notificationEl: value });
  };

  handleShareModal = (value: boolean) => {
    if (value) {
      this.props.getCategorySharableLink(this.props.activeProject.id);
    }
    this.setState({ isOpenShareModal: value });
  };

  isModalWindowOpen = (type: string): boolean => {
    return !!(this.props.modalWindows
      && this.props.modalWindows[type]
      && this.props.modalWindows[type] === 'category');
  };

  handleError = () => {
    this.props.requestTokenUpdate(true);
  };

  render() {
    const {
      projects,
      activeProject,
      isLoadingOnFetch,
      isLoadingProject,
      value,
      index,
    } = this.props;

    const {
      errorMessage,
      isNewProjectValid,
      isNewNameValid,
      isEditNewActive,
      isEditMode,
      isOpenShareModal,
      newName,
      notificationEl
    } = this.state;

    const confirmMessage = activeProject && {
      action: ', remove it!',
      text: (
        <p>
          This will also remove all files in
          <span> {activeProject.name} </span>
          project from Neatly but will not delete the files from
          <span> Google Drive</span>
        </p>
      ),
      title: (
        <h1>
          Remove<span> {activeProject.name} </span>Project?
        </h1>
      )
    };

    const successMessage = activeProject && {
      action: 'Successfully Removed!',
      text: (
        <p>
          Your <span>{activeProject.name}</span> project has been removed
        </p>
      ),
      title: <h1>Successfully Removed!</h1>
    };

    const projectsList =
      projects &&
      projects.map((item, key) => {
        const selectedClass = {
          [styles.selectedClass]:
            activeProject && activeProject.id === item.id
        };

        const editProjectByRole = isAdminOrModerator(item.userRole) && (
          <IconButton className={styles.icon} onClick={this.iconClick}>
            <Pen />
          </IconButton>
        );

        const deleteProjectByRole = isAdmin(item.userRole) && (
          <IconButton
            className={styles.icon}
            onClick={this.handleDeleteCategory}
          >
            <TrashBin />
          </IconButton>
        );

        const personAddByRole = isAdminOrModerator(item.userRole) && (
          <IconButton
            className={styles.icon}
            onClick={() => this.handleShareModal(true)}
          >
            <PersonAdd />
          </IconButton>
        );

        const icons = activeProject &&
          activeProject.id === item.id &&
          !isEditMode && (
            <>
              {editProjectByRole}
              {deleteProjectByRole}
              <IconButton
                className={styles.icon}
                onClick={(event) => this.setNotificationEl(event.currentTarget)}
              >
                <Bell />
              </IconButton>
              {personAddByRole}
            </>
          );

        const listItem =
          activeProject && activeProject.id === item.id && isEditMode ? (
            <TooltipSet
              message={errorMessage}
              isOpen={!isNewNameValid}
              type="error"
            >
              <Input
                error={!isNewNameValid}
                value={newName}
                autoFocus={true}
                className={styles.input}
                onBlur={this.handleInputBlur}
                onKeyPress={this.handleProjectRename}
                onChange={this.handleInputChange}
                inputRef={this._projectInputRef}
              />
            </TooltipSet>
          ) : (
              <Link to={`/dashboard/categories/${item.id}`}>
                <ListItemText>{item.name}</ListItemText>
              </Link>
            );

        return (
          <ListItem
            key={key}
            className={classNames(styles.listItem, selectedClass)}
            onClick={() => this.updateSelected(item)}
            selected={item && activeProject && activeProject.id === item.id}
          >
            {listItem}
          </ListItem>
        );
      });

    const newProject = isEditNewActive ? (
      <div className={styles.newCategoryWrap}>
        <TooltipSet
          message={errorMessage}
          isOpen={!isNewProjectValid}
          type="error"
        >
          <Input
            error={!isNewProjectValid}
            value={newName}
            onChange={this.handleInputChange}
            autoFocus={true}
            onKeyPress={this.handleProjectCreate}
            className={styles.input}
            placeholder="Add New Project"
            inputRef={this._newProjectInputRef}
          />
        </TooltipSet>
        <IconButton onClick={this.handleProjectCreate}>
          <DoneRoundedIcon color="primary" />
        </IconButton>
        <IconButton onClick={this.toggleNewProject}>
          <ClearRoundedIcon />
        </IconButton>
      </div>
    ) : (
        <Button className={styles.addBtn} onClick={this.toggleNewProject}>
          <AddRoundedIcon fontSize="small" />
          Add New Project
        </Button>
      );

    const content = (isLoadingOnFetch || isLoadingProject) ? (
      <div className={styles.loader}>
        <Loader isLoading={true} />
      </div>
    ) : (
        <>
          {projectsList}
          {newProject}
        </>
      );

    const shareModal = isOpenShareModal && (
      <Share
        categoryId={activeProject && activeProject.id}
        userShareRole={activeProject && activeProject.userRole}
        open={isOpenShareModal}
        setOpen={this.handleShareModal}
        link={activeProject?.id}
      />
    );

    return (
      <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
        { value === index && (
          <>
            <div className={styles.wrapper}>
              <Divider />
              {content}
            </div>
            {shareModal}
            <NotifiedOfUpdates
              popperEl={notificationEl}
              setPopperEl={this.setNotificationEl}
            />
            <ModalWindow
              type="confirm"
              message={confirmMessage}
              open={this.isModalWindowOpen(CategoriesModalWindows.confirmModal)}
              onClose={this.deleteCategory}
              isLoading={false}
            />
            <ModalWindow
              type="success"
              message={successMessage}
              open={this.isModalWindowOpen(CategoriesModalWindows.successModal)}
              onClose={this.closeModal}
              isLoading={isLoadingProject}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  accessToken: state.auth.profile.data.accessToken,
  activeProject: state.projectState.activeProject,
  errors: state.projectState.errors,
  isLoadingOnFetch: state.projectState.isLoadingOnFetch,
  isLoadingProject: state.projectState.isLoadingProject,
  modalWindows: state.modalWindowState.modalWindows,
  projects: state.projectState.projects,
  roleOfUser: state.auth.profile.role
});

const actions = {
  closeModalWindow,
  createProject,
  deleteProject,
  fetchProjects,
  getCategorySharableLink,
  openModalWindow,
  requestTokenUpdate,
  setActiveProject,
  syncProjectWithDriveDirectory,
  updateProject,
};

export default connect(mapStateToProps, actions)(Projects);
