import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from '@router/index';
import history from '@services/history';
import { setStore } from '@services/store';
import configureStore from './store';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@styles/theme';

import '@styles/main.scss';
import {Toaster} from 'react-hot-toast';

type State = {
  persistor: any;
  isLoading: boolean;
  store: Store<IStore>;
};

type Props = {};

export default class Setup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { store, persistor } = configureStore(() =>
      this.setState({ isLoading: false })
    );

    setStore(store);

    this.state = {
      isLoading: false,
      persistor,
      store
    };
  }

  render() {
    return (
      <div>
        <Toaster/>
        <PersistGate loading={null} persistor={this.state.persistor}>
          <Router history={history}>
            <Provider store={this.state.store}>
              <ThemeProvider theme={theme}>
                <AppRouter />
              </ThemeProvider>
            </Provider>
          </Router>
        </PersistGate>
      </div>
    );
  }
}
