import React, { RefObject, useState } from 'react';
import { InputBase, FormControlLabel } from '@material-ui/core';

import Link from '@assets/svg/link.svg?tag';
import styles from './styles.m.scss';
import InputTooltip from '@components/custom-input-tooltip';

interface IProps {
  inputRef: RefObject<HTMLInputElement>;
  nameInputRef: RefObject<HTMLInputElement>;
  linkInvalid: boolean;
  linkNameInvalid: boolean;
  setLinkInvalid: (linkInvalid: boolean) => void;
}

const ERROR_MESSAGE_LINK_NAME_INVALID: string = 'Link name cannot be empty';
const ERROR_MESSAGE_LINK_INVALID: string = 'Please provide resource link in format https://example.com';

const AddFileLink = (props: IProps) => {
  const { inputRef, nameInputRef, linkInvalid, linkNameInvalid, setLinkInvalid } = props;

  const [linkValue, setLinkValue] = useState<string>('');
  const [linkNameValue, setLinkNameValue] = useState<string>('');

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLinkValue(event.target.value);
    if (linkInvalid) {
      setLinkInvalid(false);
    }
  };
  const onNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLinkNameValue(event.target.value);
    if (linkInvalid) {
      setLinkInvalid(false);
    }
  };

  const formControlName = (
    <div className={styles.formLink}>
      <InputTooltip
        message={ERROR_MESSAGE_LINK_NAME_INVALID}
        isOpen={linkNameInvalid}
        type="error"
      >
        <InputBase
          placeholder="Please enter name to your link"
          className={styles.inputLink}
          inputRef={nameInputRef}
          error={linkNameInvalid}
          value={linkNameValue}
          onChange={onNameChange}
        />
      </InputTooltip>
    </div>
  );

  const formControl = (
    <div className={styles.formLink}>
      <InputTooltip
        message={ERROR_MESSAGE_LINK_INVALID}
        isOpen={linkInvalid}
        type="error"
      >
        <InputBase
          placeholder="Please enter URL to your file"
          className={styles.inputLink}
          inputRef={inputRef}
          error={linkInvalid}
          value={linkValue}
          onChange={onChange}
        />
      </InputTooltip>
      <Link className={styles.linkIcon} />
    </div>
  );

  return (
    <div className={styles.addFileLink}>
      <FormControlLabel
        label="Name your link:"
        labelPlacement="start"
        className={styles.labelLink}
        control={formControlName}
      />
      <FormControlLabel
        label="Link to a file:"
        labelPlacement="start"
        className={styles.labelLink}
        control={formControl}
      />
    </div>
  );
};

export default AddFileLink;
