import React from 'react';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.m.scss';

interface IProps {
  isLoading: boolean;
  isNotAbsoloute?: boolean;
  isBackground?: boolean;
}

function Loader(props: IProps) {
  const isLoading = {
    [styles.isLoading]: props.isLoading
  };
  const loaderStyles = classNames(styles.wrapper, isLoading, props.isBackground && styles.backGround,
    props.isNotAbsoloute && styles.notAbsolute);
  return (
    <div className={loaderStyles}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
