import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  RouteComponentProps
} from 'react-router-dom';
import Sockets from '@components/sockets';

import ScrollToTop from './scroll-to-top';
import PrivateRoute from './private-route';
import NotFound from '@pages/not-found';
import Auth from '@pages/auth';
import GooglePage from '@pages/google-page';
import Wrapper from '@components/wrapper';

class AppRouter extends React.PureComponent {
  render() {
    const requireCompany = (props: RouteComponentProps) => (
      <Auth {...props} isCompanyReq={true} />
    );

    const authPass = [
      '/auth',
      '/auth/token',
      '/auth/access-denied',
      '/auth/session-expired',
      '/auth/account-deactivated',
      '/auth/confirm-close',
      '/auth/confirm-invite'
    ];

    const settingCategoryPass = [
      '/settings/profile',
      '/settings/billing-info',
      '/settings/user-list',
      '/settings/tags',
      '/',
      '/dashboard',
      '/dashboard/categories/:categoryId'
    ];

    return (
      <BrowserRouter>
        <ScrollToTop>
          <Sockets>
            <Switch>
              <Route path={authPass} component={Auth} />
              <Route exact={true} path="/company" component={requireCompany} />
              <PrivateRoute exact={true} path={`/docs/:fileId`} component={GooglePage} />
              <PrivateRoute exact={true} path={settingCategoryPass} component={Wrapper} />
              <Route path="/not-found" component={NotFound} />
              <Redirect to="/not-found" />
            </Switch>
          </Sockets>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default AppRouter;
