import Types from './types';

export const openModalWindow = (keyName: string, id: string) => ({
  payload: { keyName, id },
  type: Types.OPEN_MODAL_WINDOW
});

export const closeModalWindow = (keyName: string) => ({
  payload: keyName,
  type: Types.CLOSE_MODAL_WINDOW
});
