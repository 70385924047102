import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import Types from '../reducers/files/types';
import { openModalWindow } from '@redux/reducers/modal-window/actions';
import { FilesModalWindows, ShareModalWindow } from '@components/modal-window/modalWindowsTypes';
import {
  addFileNotification,
  readNotification
} from '@redux/reducers/notifications/actions';
import toast from 'react-hot-toast';

const middlware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);
  if (action.type === Types.FETCH_FILES_REQUEST) {
    store.dispatch(
      api({
        body: action.payload.tags,
        method: 'get',
        onError: Types.FILES_ERROR,
        onSuccess: Types.FETCH_FILES_SUCCESS,
        url: `/files?sectionId=
        ${action.payload.sectionId}
        ${action.payload.groupId ? `&groupId=${action.payload.groupId}` : ''}
        ${action.payload.count ? `&count=${action.payload.count}` : ''}
        ${action.payload.offset ? `&offset=${action.payload.offset}` : ''}
        ${action.payload.tags && action.payload.tags.length > 0 ? `&tags=${action.payload.tags.join(',')}` : ''}`
            .replace(/\n/g, '')
            .replace(/\s/g, '')
      })
    );
  }

  if (action.type === Types.GET_FILE_TAGS_REQUEST ||
      action.type === Types.ADD_NEW_FILE_TAG_SUCCESS ||
      action.type === Types.ADD_NEW_FILE_TAG_ERROR ||
      action.type === Types.DELETE_FILE_TAGS_SUCCESS ||
      action.type === Types.DELETE_FILE_TAGS_ERROR
  ) {
      store.dispatch(
        api({
          method: 'get',
          onError: Types.GET_FILE_TAGS_ERROR,
          onSuccess: Types.GET_FILE_TAGS_SUCCESS,
          url: '/files/file-tags',
        })
      );
  }

  if (action.type === Types.ADD_NEW_FILE_TAG_REQUEST) {
      store.dispatch(
          api({
              body: action.payload.fileTag,
              method: 'post',
              onError: Types.ADD_NEW_FILE_TAG_ERROR,
              onSuccess: Types.ADD_NEW_FILE_TAG_SUCCESS,
              url: '/files/file-tags',
          })
      );
  }

  if (action.type === Types.DELETE_FILE_TAGS_REQUEST) {
      store.dispatch(
          api({
              body: action.payload.tagIds,
              method: 'delete',
              onError: Types.DELETE_FILE_TAGS_ERROR,
              onSuccess: Types.DELETE_FILE_TAGS_SUCCESS,
              url: '/files/file-tags',
          })
      );
  }

  if (action.type === Types.GET_FILE_FROM_NOTIFICATION_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.GET_FILE_FROM_NOTIFICATION_ERROR,
        onSuccess: Types.GET_FILE_FROM_NOTIFICATION_SUCCESS,
        url: `/files/${action.payload.id}`
      })
    );
  }

  if (action.type === Types.GET_FILE_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.GET_FILE_ERROR,
        onSuccess: Types.GET_FILE_SUCCESS,
        url: `/files/${action.payload.id}`
      })
    );
  }

  if (action.type === Types.ADJUST_FILE_PERMISSIONS_REQUEST) {
      store.dispatch(
          api({
              method: 'get',
              onError: Types.NO_ACTION,
              onSuccess: Types.ADJUST_FILE_PERMISSIONS_SUCCESS,
              url: `/files/permissions/${action.payload.id}`
          })
      );
  }

  if (action.type === Types.OPEN_FILE_FROM_DRIVE_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.GET_FILE_ERROR,
        onSuccess: Types.OPEN_FILE_FROM_DRIVE_SUCCESS,
        url: `/files/${action.payload.id}`
      })
    );
  }

  if (action.type === Types.CREATE_DOC_FILE_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.FILES_ERROR,
        onSuccess: Types.CREATE_DOC_FILE_SUCCESS,
        url: `/files/doc`
      })
    );
  }

  if (action.type === Types.CREATE_BY_URL_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.FILES_ERROR,
        onSuccess: Types.CREATE_BY_URL_SUCCESS,
        url: `/files/url`
      })
    );
  }

  if (action.type === Types.CREATE_BY_DRIVE_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.FILES_ERROR,
        onSuccess: Types.CREATE_BY_DRIVE_SUCCESS,
        url: `/files/drive`
      })
    );
  }

  if (action.type === Types.UPLOAD_REQUEST) {
    const data = new FormData();
    data.append('sectionId', action.payload.sectionId);
    data.append('file', action.payload.file);
    if (action.payload.groupId) {
      data.append('groupId', action.payload.groupId);
    }
    if (action.payload.groupTitle) {
      data.append('groupTitle', action.payload.groupTitle);
    }
    if (action.payload.groupDescription) {
      data.append('groupDescription', action.payload.groupDescription);
    }
    store.dispatch(
      api({
        body: data,
        method: 'post',
        onError: Types.FILES_ERROR,
        onSuccess: Types.UPLOAD_SUCCESS,
        url: `/files/upload`
      })
    );
  }

  if (action.type === Types.CHANGE_TO_GROUP_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.FILES_ERROR,
        onSuccess: Types.CHANGE_TO_GROUP_SUCCESS,
        url: `/files/change-to-group`
      })
    );
  }

  if (action.type === Types.UPDATE_FILE_REQUEST) {
    const fileId = action.payload.file.id;
    const name = action.payload.name;
    const tags = action.payload.tags;
    const makeSharableWithLink = action.payload.makeSharableWithLink;

    store.dispatch(
      api({
        body: { fileId, name, tags, makeSharableWithLink },
        method: 'put',
        onError: Types.UPDATE_FILE_ERROR,
        onSuccess: Types.UPDATE_FILE_SUCCESS,
        url: `/files`
      })
    );
  }

  if (action.type === Types.MAKE_SHARABLE_WITH_LINK_REQUEST) {
      const fileId = action.payload.fileId;

      store.dispatch(
          api({
              body: { fileId },
              method: 'put',
              onError: Types.MAKE_SHARABLE_WITH_LINK_ERROR,
              onSuccess: Types.MAKE_SHARABLE_WITH_LINK_SUCCESS,
              url: `/files/make-sharable-with-link`
          })
      );
  }

  if (action.type === Types.INVITE_FILE_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.INVITE_FILE_ERROR,
        onSuccess: Types.INVITE_FILE_SUCCESS,
        url: `/files/share`
      })
    );
  }

  if (action.type === Types.DELETE_FILE_REQUEST) {
    store.dispatch(
      api({
        body: { filesIds: action.payload.filesIds },
        method: 'delete',
        onError: Types.FILES_ERROR,
        onSuccess: Types.DELETE_FILE_SUCCESS,
        url: `/files`
      })
    );
  }

  if (action.type === Types.DELETE_FILE_SUCCESS) {
    const state = store.getState();
    const ids = state.filesState.activeIds;
    if (state.filesState.activeIds.filesIds.length > 1) {
      store.dispatch(
        openModalWindow(FilesModalWindows.successSelectedModal, ids.sectionId)
      );
    } else {
      store.dispatch(
        openModalWindow(FilesModalWindows.successModal, ids.sectionId)
      );
    }
  }

  if (action.type === Types.GET_FILE_FROM_NOTIFICATION_SUCCESS) {
    store.dispatch(addFileNotification(action.payload.name));
  }

  if (
    action.type === Types.GET_FILE_FROM_NOTIFICATION_ERROR ||
    action.type === Types.GET_FILE_ERROR
  ) {
    if (store.getState().notificationsState.eventPending?.id) {
      store.dispatch(
        readNotification(store.getState().notificationsState.eventPending?.id)
      );
    }
  }

  if (action.type === Types.GET_FILE_SUCCESS) {
    if (store.getState().notificationsState.isEventWaiting) {
      store.dispatch(addFileNotification(action.payload.name));
    }
  }

  if (action.type === Types.OPEN_FILE_FROM_DRIVE_SUCCESS) {
    if (window && action.payload.data) {
      window.open(
        `https://drive.google.com/file/d/${action.payload.data.fileId}/view`
      );
    }
  }

  if (action.type === Types.GET_FILES_FEED_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'get',
        onError: Types.GET_FILES_FEED_ERROR,
        onSuccess: Types.GET_FILES_FEED_SUCCESS,
        url: `/files/feed${
          action.payload.sortBy ? '?sortBy=' + action.payload.sortBy : ''
        }`
      })
    );
  }

  if (action.type === Types.GET_FILES_FEED_NEW_AMOUNT_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.GET_FILES_FEED_NEW_AMOUNT_ERROR,
        onSuccess: Types.GET_FILES_FEED_NEW_AMOUNT_SUCCESS,
        url: '/files/new-feed',
      })
    );
  }

  if (action.type === Types.INVITE_FILE_SUCCESS) {
    store.dispatch(openModalWindow(ShareModalWindow.successModal, 'share'));
  }

  if (action.type === Types.INVITE_FILE_ERROR) {
    toast.error('Sharing mail failed. Please try again later.');
  }
};

export default middlware as Middleware;
