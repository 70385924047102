import React, { createRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isAdminOrModerator, isAdmin } from '@services/user-role';
import { createCategory } from '@redux/reducers/categories/actions';
import { createProject } from '@redux/reducers/projects/actions';
import TooltipSet from '@components/custom-input-tooltip';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  Table,
  Divider
} from '@material-ui/core';

import Close from '@assets/svg/close.svg?tag';
import styles from './styles.m.scss';
import Scrollbars from 'react-custom-scrollbars';

interface IReduxProps {
  categories: ICategory[];
  projects: ICategory[];
  roleOfUser: UserRoleType;
  errors: string[];
  isLoading: boolean;
  createCategory: typeof createCategory;
  createProject: typeof createProject;
}

interface IOwnProps {
  open: boolean;
  setOpen: (value?: boolean) => void;
  onSync: (categoryId: string) => void;
}

interface IProps extends IReduxProps, IOwnProps {}

const Errors = Object.freeze({
  nameAlreadyExist: 'Category or project already exist',
  nameIsNotUnique: 'Name has to be unique',
  nameIsSmall: 'Name has to have at least 3 letters',
});

const SelectCategory = (props: IProps) => {
  const [isAddNewCategory, setIsAddNewCategory] = useState<boolean>(false);
  const [isAddNewProject, setIsAddNewProject] = useState<boolean>(false);
  const [titleInvalid, setTitleInvalid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false);

  const _newCategoryInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (isCreatingProject && !props.isLoading) {
      if (props.errors.length) {
        setErrorMessage(Errors.nameAlreadyExist);
        setTitleInvalid(true);
      } else {
        handleCloseInput();
      }

      setIsCreatingProject(false);
    }

  }, [props.isLoading]);

  const handleCloseInput = () => {
    setIsAddNewCategory(false);
    setIsAddNewProject(false);
    setTitle('');
    setTitleInvalid(false);
    setErrorMessage('');
  };

  const categoriesAndProjects = props.categories.concat(props.projects);

  const filteredCategories = props.categories
  .filter((item: ICategory) => isAdminOrModerator(item.userRole));

  const filteredProjects = props.projects
  .filter((item: ICategory) => isAdminOrModerator(item.userRole));

  const isLengthValid = (value: string) => {
    if (!(value.trim().length > 1)) {
      setErrorMessage(Errors.nameIsSmall);
      return false;
    } else {
      return true;
    }
  };

  const isValueUnique = (value: string) => {
    if (categoriesAndProjects) {
      const item = categoriesAndProjects.find((i) => i.name === value);
      if (item) {
        setErrorMessage(Errors.nameIsNotUnique);
        return false;
      }
    }
    return true;
  };

  const isFieldValid = (value: string) => {
    if (isLengthValid(value) && isValueUnique(value)) {
      return true;
    } else {
      return false;
    }
  };

  const handleCategoryCreate = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      event.type === 'click' ||
      (event.type === 'keypress' &&
        (event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter')
    ) {
      if (
        _newCategoryInputRef &&
        _newCategoryInputRef.current.value &&
        isFieldValid(_newCategoryInputRef.current.value)
      ) {
        setTitleInvalid(false);
        setIsAddNewCategory(false);
        setTitle('');
        props.createCategory(_newCategoryInputRef.current.value);
      } else {
        setTitleInvalid(true);
      }
    }
  };

  const handleProjectCreate = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      event.type === 'click' ||
      (event.type === 'keypress' &&
        (event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter')
    ) {
      if (
        _newCategoryInputRef &&
        _newCategoryInputRef.current.value &&
        isFieldValid(_newCategoryInputRef.current.value)
      ) {
        props.createProject(_newCategoryInputRef.current.value);
        setIsCreatingProject(true);
      } else {
        setTitleInvalid(true);
      }
    }
  };

  const onCategoryNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTitleInvalid(false);
    setTitle(e.target.value);
  };

  const addNewCategoryInput = isAddNewCategory && (
    <div className={classNames(styles.inputWrapper, filteredCategories.length === 0 && styles.inputWrapWithoutItems)}>
      <TooltipSet
        message={errorMessage}
        isOpen={titleInvalid}
        type="error"
        className={styles.tooltip}
      >
        <Input
          error={titleInvalid}
          className={styles.newCategoryTitle}
          value={title}
          autoFocus={true}
          onChange={onCategoryNameChange}
          inputRef={_newCategoryInputRef}
          placeholder="Add New Category"
          onKeyPress={(event) => handleCategoryCreate(event)}
        />
      </TooltipSet>
      <IconButton
        onClick={handleCategoryCreate}
        className={classNames(styles.done)}
      >
        <DoneRoundedIcon />
      </IconButton>
      <IconButton
        onClick={handleCloseInput}
        className={classNames(styles.clear)}
      >
        <ClearRoundedIcon />
      </IconButton>
    </div>
  );

  const addNewProjectInput = isAddNewProject && (
    <div className={classNames(styles.inputWrapper, filteredProjects.length === 0 && styles.inputWrapWithoutItems)}>
      <TooltipSet
        message={errorMessage}
        isOpen={titleInvalid}
        type="error"
        className={styles.tooltip}
      >
        <Input
          error={titleInvalid}
          className={styles.newCategoryTitle}
          value={title}
          autoFocus={true}
          onChange={onCategoryNameChange}
          inputRef={_newCategoryInputRef}
          placeholder="Add New Project"
          onKeyPress={(event) => handleProjectCreate(event)}
        />
      </TooltipSet>
      <IconButton
        onClick={handleProjectCreate}
        className={classNames(styles.done)}
      >
        <DoneRoundedIcon />
      </IconButton>
      <IconButton
        onClick={handleCloseInput}
        className={classNames(styles.clear)}
      >
        <ClearRoundedIcon />
      </IconButton>
    </div>
  );

  const addNewCategoryButton = !isAddNewCategory && filteredCategories.length > 0 && (
    <div className={styles.buttonWithTitle}>
      <Button
        className={styles.addBtn}
        onClick={() => setIsAddNewCategory(true)}
        disabled={isAddNewProject}
      >
        <AddRoundedIcon fontSize="small" />
        Add New Category
      </Button>
    </div>
  );

  const addNewProjectButton = !isAddNewProject && filteredProjects.length > 0 && (
    <div className={styles.buttonWithTitle}>
      <Button
        className={styles.addBtn}
        onClick={() => setIsAddNewProject(true)}
        disabled={isAddNewCategory}
      >
        <AddRoundedIcon fontSize="small" />
        Add New Project
      </Button>
    </div>
  );

  const getItemsTable = (type: 'categories' | 'projects'): React.ReactElement[] => {
    const itemsTable: any[] = [];
    const offset = 3;
    const items = type === 'categories' ? filteredCategories : filteredProjects;
    for (let i = 0; i < items?.length; i += offset) {
      const itemsRows = items
        ?.slice(i, i + offset)
        .map((item, index) => (
          <TableCell key={index} className={styles.cell} >
            <FormControlLabel
              className={classNames(
                styles.formControlCategoryCheckbox,
                item.id === selectedCategoryId && styles.activeCheckbox
              )}
              key={item.id}
              value={item.id}
              label={item.name}
              control={<Radio color="primary" className={styles.radio} />}
            />
          </TableCell>
        ));

      itemsTable.push(<TableRow className={styles.tableRow} key={i}>{itemsRows}</TableRow>);
    }
    return itemsTable;
  };

  const categoriesRadioButtons = <Table><TableBody>{getItemsTable('categories')}</TableBody></Table>;
  const projectsRadioButtons = <Table><TableBody>{getItemsTable('projects')}</TableBody></Table>;

  const handleCategorySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategoryId(event.target.value);
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        open={props.open}
        onClose={() => props.setOpen(false)}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={props.open}>
          <div className={classNames(styles.paper, styles.fadeIn)}>
            <Button
              className={styles.closeBtn}
              onClick={() => props.setOpen(false)}
            >
              <Close />
            </Button>
            <header className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Step 2. Choose a Category or Project</h2>
              <p className={styles.modalDescription}>
                Choose an existing or create a new Neatly category or project to map to
                your folder
              </p>
            </header>
            <RadioGroup
              value={selectedCategoryId}
              onChange={handleCategorySelect}
              className={classNames(
                styles.RadioGroup,
                !isAdminOrModerator(props.roleOfUser) && styles.large
              )}
            >
              <div className={styles.modalCategoriesAndProjects}>
                <div className={styles.modalList}>
                  <div className={styles.wrapBtnTitle}>
                    <h4
                      className={classNames(
                          styles.modalSubTitle,
                          filteredCategories.length === 0 && styles.emptyModalSubTitle
                      )}
                    >
                      Categories:
                    </h4>
                    {isAdminOrModerator(props.roleOfUser) && (
                      <>
                      {addNewCategoryButton}
                      {filteredCategories.length > 0 && addNewCategoryInput}
                      </>
                    )}
                  </div>
                  <div className={styles.scrollbarWrap}>
                    {filteredCategories.length > 0 ? (
                      <Scrollbars
                        autoHide={true}
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        className={styles.scrollbar}
                      >
                        {categoriesRadioButtons}
                      </Scrollbars>
                    ) : (
                      <div>
                        {!isAddNewCategory && (
                          <div className={styles.textWrap}>
                            <p className={styles.emptyText}>You don’t have any categories yet.</p>
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => setIsAddNewCategory(true)}
                              className={styles.buttonCreateFirst}
                            >
                              Want to create the first?
                            </Button>
                          </div>
                        )}
                        {filteredCategories.length === 0 && addNewCategoryInput}
                      </div>
                    )}
                  </div>
                </div>
                <Divider className={styles.divider}/>
                <div className={styles.modalList}>
                  <div className={styles.wrapBtnTitle}>
                    <h4
                      className={classNames(
                          styles.modalSubTitle,
                          filteredProjects.length === 0 && styles.emptyModalSubTitle
                      )}
                    >
                      Projects:
                    </h4>
                    {filteredProjects.length > 0 && addNewProjectInput}
                    {addNewProjectButton}
                  </div>
                  <div className={styles.scrollbarWrap}>
                    {filteredProjects.length > 0 ? (
                      <Scrollbars
                        autoHide={true}
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        className={styles.scrollbar}
                      >
                        {projectsRadioButtons}
                      </Scrollbars>
                    ) : (
                      <div>
                        {!isAddNewProject && (
                          <div className={styles.textWrap}>
                            <p className={styles.emptyText}>You don’t have any projects yet.</p>
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => setIsAddNewProject(true)}
                              className={styles.buttonCreateFirst}
                            >
                              Want to create the first?
                            </Button>
                          </div>
                        )}
                        {filteredProjects.length === 0 && addNewProjectInput}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </RadioGroup>
            <footer className={styles.cancelSave}>
              <Button
                className={classNames(styles.btnModal, styles.btnCancel)}
                onClick={() => props.setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={styles.btnModal}
                disabled={false}
                onClick={() => props.onSync(selectedCategoryId)}
              >
                Sync
              </Button>
            </footer>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  categories: state.categoriesState.categories,
  errors: state.projectState.errors,
  isLoading: state.projectState.isLoadingProject,
  projects: state.projectState.projects,
  roleOfUser: state.auth.profile.role
});

const actions = {
  createCategory,
  createProject
};

export default connect(mapStateToProps, actions)(SelectCategory);
