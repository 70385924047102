import React, {PropsWithChildren, useState} from 'react';
import classNames from 'classnames';
import { Button, Modal, Fade, Backdrop } from '@material-ui/core';

import Info from '@assets/svg/info.svg?tag';
import MarkedInfo from '@assets/svg/markedInfo.svg?tag';
import styles from './styles.m.scss';
import Loader from '@components/loader';

export interface IModalMessage {
  title: React.ReactNode;
  text: React.ReactNode;
  action?: string;
}

interface IProps extends PropsWithChildren<any> {
  type: 'confirm' | 'success' | 'form' | 'auth_modal' | 'sync' | 'error';
  message: IModalMessage;
  open: boolean;
  onClose: (value?: boolean) => void;
  isLoading?: boolean;
}

const typesInfoIcon = ['confirm', 'auth_modal', 'sync', 'error'];
const typesAccessDenied = ['auth_modal', 'sync', 'error'];

function ModalWindow(props: IProps) {
  const {type, message, children, onClose, open, isLoading} = props;

  const handleDecline = () => {
      onClose(false);
  };

  const handleConfirm = () => {
    onClose(true);
  };

  const icon =
      type === 'success' ? (
          <MarkedInfo className={styles.icon}/>
      ) : (
          (typesInfoIcon.includes(type)) && (
              <Info className={styles.icon}/>
          )
      );

  const getActionText = (formType: string) => {
    let actionText = message && `Yes${message.action ? message.action : ''}`;
    if (typesAccessDenied.includes(formType)) {
      actionText = 'OK';
    } else if (formType === 'form') {
      actionText = 'Save';
    }
    return actionText;
  };

  const btnAccessDenied = (
      <div className={styles.accessDenied}>
        <Button
            color="primary"
            variant="contained"
            onClick={handleDecline}
            className={styles.okBtn}
        >
          Ok
        </Button>
      </div>
  );

  const btnDefault = type !== 'success' && (
      <div className={styles.actions}>
        <Button onClick={handleDecline} className={styles.declineBtn}>
          Cancel
        </Button>
        <Button
            color="primary"
            variant="contained"
            onClick={handleConfirm}
            className={styles.confirmBtn}
        >
          {getActionText(type)}
        </Button>
      </div>
  );

  const buttons = (typesAccessDenied.includes(type)) ? btnAccessDenied : btnDefault;

  const btnClose = type !== 'auth_modal' && (
      <button className={styles.close} onClick={handleDecline}/>
  );

  const formBody = type === 'form' ? styles.formBody : null;

  return (
      <Modal
          open={open}
          onClose={handleDecline}
          className={styles.wrapper}
          closeAfterTransition={true}
          BackdropComponent={Backdrop}
          BackdropProps={{timeout: 500}}
      >
        { isLoading ? (
            <div className={styles.loader}>
              <Loader isLoading={true} isBackground={false} isNotAbsoloute={true}/>
            </div>
            )
            :
            (
              <Fade in={open}>
                <div className={classNames(styles.modalBody, formBody)}>
                  {btnClose}
                  {icon}
                  {message && message.title}
                  <div>{message && message.text}</div>
                  {children}
                  {buttons}
                </div>
              </Fade>
            )}
      </Modal>
  );
}

export default ModalWindow;
