import axios from 'axios';
import React, {Component, createRef, RefObject, useEffect, useRef} from 'react';
import classNames from 'classnames';
import loadScript from 'load-script';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  ListItem,
  Input,
  IconButton,
  Divider,
  ListItemText,
  Button
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { connect } from 'react-redux';
import {
  fetchCategories,
  createCategory,
  updateCategory,
  setActiveCategory,
  deleteCategory,
  syncCategoryWithDriveDirectory
} from '@redux/reducers/categories/actions';
import {
  openModalWindow,
  closeModalWindow
} from '@redux/reducers/modal-window/actions';
import { isAdmin, isAdminOrModerator } from '@services/user-role';
import Loader from '@components/loader';
import ModalWindow from '@components/modal-window';
import NotifiedOfUpdates from '@components/notified-of-updates';
import { requestTokenUpdate } from '@redux/reducers/auth/actions';
import { CategoriesModalWindows } from '@components/modal-window/modalWindowsTypes';
import TooltipSet from '@components/tooltips';

import PersonAdd from '@assets/svg/personAdd.svg?tag';
import TrashBin from '@assets/svg/trashBin.svg?tag';
import Bell from '@assets/svg/bell.svg?tag';
import Pen from '@assets/svg/pen.svg?tag';
import styles from './styles.m.scss';
import {setActiveWizardStep, setNewWizardStep} from '@redux/reducers/wizard/actions';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';

interface IReduxProps {
  accessToken: string;
  categories: ICategory[];
  activeCategory: ICategory;
  roleOfUser: UserRoleType;
  createCategory: typeof createCategory;
  deleteCategory: typeof deleteCategory;
  fetchCategories: typeof fetchCategories;
  updateCategory: typeof updateCategory;
  setActiveCategory: typeof setActiveCategory;
  requestTokenUpdate: typeof requestTokenUpdate;
  syncCategoryWithDriveDirectory: typeof syncCategoryWithDriveDirectory;

  errors: string[];
  isLoadingCategory: boolean;
  isLoadingOnFetch: boolean;

  modalWindows: any;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;

  setNewWizardStep: typeof setNewWizardStep;
  setActiveWizardStep: typeof setActiveWizardStep;
  wizardState: IWizardState;
}

interface IProps extends IReduxProps {
  history: any;
  index: number;
  value: number;
}

interface IState {
  errorMessage: string;
  isEditMode: boolean;
  isEditNewActive: boolean;
  isNewCategoryValid: boolean;
  isNewNameValid: boolean;
  isOpenShareModal: boolean;
  newName: string;
  pickedFolderId: string;
  notificationEl: HTMLButtonElement | null;
}

const Errors = Object.freeze({
  nameIsNotUnique: 'Name of categories has to be unique',
  nameIsSmall: 'Name of the categories has to have at least 3 letters'
});

class Categories extends Component<IProps, IState> {
  state: IState = {
    errorMessage: '',
    isEditMode: false,
    isEditNewActive: false,
    isNewCategoryValid: true,
    isNewNameValid: true,
    isOpenShareModal: false,
    newName: '',
    notificationEl: null,
    pickedFolderId: '',
  };

  _categoryInputRef = createRef<HTMLInputElement>();
  _newCategoryInputRef = createRef<HTMLInputElement>();
  _wizardRef = createRef<HTMLButtonElement>();

  onApiLoad = () => {
    (window as any).gapi.load('picker');
  };

  componentDidMount = () => {
    loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    this.props.fetchCategories();
  };

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.wizardState.activeStep === this.props.wizardState.activeStep) {
      return;
    }
    if (this.props.wizardState.isWizardActive) {
      this.props.setNewWizardStep({
        content: {
          text: 'First, let\'s make sure that we have a category where we\'ll store similar assets. Give it some intuitive name, like the team with which you\'re going to share this!',
          title: 'Creating a category',
        },
        position: this._wizardRef?.current?.getBoundingClientRect(),
        step: 0,
      });
    }
  }

  isLengthValid = (value: string) => {
    if (!(value.trim().length > 1)) {
      this.setState({ errorMessage: Errors.nameIsSmall });
      return false;
    } else {
      return true;
    }
  };

  isValueUnique = (value: string, isNew: boolean) => {
    if (this.props.categories) {
      const cat = this.props.categories.find(
        (c) =>
          c.name === value &&
          (isNew || c.name !== this.props.activeCategory.name)
      );
      if (cat) {
        this.setState({ errorMessage: Errors.nameIsNotUnique });
        return false;
      }
    }
    return true;
  };

  isFieldValid = (value: string, isNew: boolean) => {
    if (this.isLengthValid(value) && this.isValueUnique(value, isNew)) {
      return true;
    } else {
      return false;
    }
  };

  updateSelected = (selectedCategory: ICategory) => {
    if (
      selectedCategory &&
      (!this.props.activeCategory ||
        selectedCategory.id !== this.props.activeCategory.id)
    ) {
      this.setState({
        errorMessage: '',
        isEditMode: false,
        isEditNewActive: false,
        isNewCategoryValid: true,
        isNewNameValid: true,
        newName: selectedCategory.name
      });
    }
  };

  updateSelectedName = (newName: string) => {
    if (newName !== this.props.activeCategory.name) {
      this.props.updateCategory(this.props.activeCategory.id, newName);
    }
    this.setState({
      newName
    });
  };

  deleteCategory = (value: boolean) => {
    this.props.closeModalWindow(CategoriesModalWindows.confirmModal);
    if (value) {
      this.props.deleteCategory(this.props.activeCategory.id);
    }
  };

  closeModal = (value: boolean) => {
    this.props.closeModalWindow(CategoriesModalWindows.successModal);
    let newLink = '';
    if (this.props.categories.length) {
      newLink = `/categories/${this.props.categories[0].id}`;
    } else {
      this.props.setActiveCategory(null);
    }
    this.props.history.push('/dashboard' + newLink);
  };

  iconClick = () => {
    this.setState({
      isEditMode: true,
      newName: this.props.activeCategory.name
    });
  };

  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.currentTarget.value.replace(/\s+/g, '').length < 31) {
      this.setState({
        isNewCategoryValid: true,
        isNewNameValid: true,
        newName: e.currentTarget.value
      });
    }
  };

  handleCategoryRename = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (
        this._categoryInputRef !== null &&
        this.isFieldValid(this._categoryInputRef.current.value, false)
      ) {
        this.setState({
          isEditMode: false
        });
        this.updateSelectedName(this._categoryInputRef.current.value);
      } else if (!this._categoryInputRef.current.value.trim()) {
        this.updateSelectedName(this.props.activeCategory.name);
      } else {
        this.setState({
          isNewNameValid: false
        });
      }
    }
  };

  handleInputBlur = () => {
    if (this._categoryInputRef && !this._categoryInputRef.current) {
      this.setState({
        errorMessage: '',
        isEditMode: false,
        isNewNameValid: true
      });
    } else if (
      this._categoryInputRef &&
      this.isFieldValid(this._categoryInputRef.current.value, false)
    ) {
      this.setState({
        isEditMode: false
      });
      this.updateSelectedName(this._categoryInputRef.current.value);
    } else if (!this._categoryInputRef.current.value.trim()) {
      this.updateSelectedName(this.props.activeCategory.name);
    } else {
      this.setState({
        isNewNameValid: false
      });
    }
  };

  toggleNewCategory = () => {
    this.setState((pervState) => ({
      errorMessage: '',
      isEditMode: false,
      isEditNewActive: !pervState.isEditNewActive,
      isNewCategoryValid: true,
      isNewNameValid: true,
      newName: ''
    }));
  };

  handleCategoryCreate = (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      event.type === 'click' ||
      (event.type === 'keypress' &&
        (event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter')
    ) {
      if (
        this._newCategoryInputRef &&
        this._newCategoryInputRef.current.value &&
        this.isFieldValid(this._newCategoryInputRef.current.value, true)
      ) {
        this.props.createCategory(this._newCategoryInputRef.current.value);
        if (this.props.wizardState.isWizardActive) {
          this.props.setActiveWizardStep(1);
        }
        this.toggleNewCategory();
      } else {
        this.setState({
          isNewCategoryValid: false
        });
      }
    }
  };

  handleDeleteCategory = () => {
    this.props.openModalWindow(CategoriesModalWindows.confirmModal, 'category');
  };

  setNotificationEl = (value: HTMLButtonElement) => {
    this.setState({ notificationEl: value });
  };

  handleShareModal = (value: boolean) => {
    this.setState({ isOpenShareModal: value });
  };

  isModalWindowOpen = (type: string): boolean => {
    return !!(this.props.modalWindows
      && this.props.modalWindows[type]
      && this.props.modalWindows[type] === 'category');
  };

  handleError = () => {
    this.props.requestTokenUpdate(true);
  };

  render() {
    const {
      categories,
      roleOfUser,
      activeCategory,
      isLoadingCategory,
      isLoadingOnFetch,
      index,
      value,
    } = this.props;

    const {
      errorMessage,
      isNewCategoryValid,
      isNewNameValid,
      isEditNewActive,
      isEditMode,
      isOpenShareModal,
      newName,
      notificationEl,
    } = this.state;

    const confirmMessage = activeCategory && {
      action: ', remove it!',
      text: (
        <p>
          This will also remove all files in
          <span> {activeCategory.name} </span>
          category from Neatly but will not delete the files from
          <span> Google Drive</span>
        </p>
      ),
      title: (
        <h1>
          Remove<span> {activeCategory.name} </span>Category?
        </h1>
      )
    };

    const successMessage = activeCategory && {
      action: 'Successfully Removed!',
      text: (
        <p>
          Your <span>{activeCategory.name}</span> category has been removed
        </p>
      ),
      title: <h1>Successfully Removed!</h1>
    };

    const errorCreateMessage = {
      text: (
        <p>
          You don't have permissions for creating category
        </p>
      ),
      title: <h1>You can't create new category</h1>
    };

    const categoriesList =
      categories &&
      categories.map((item, key) => {
        const selectedClass = {
          [styles.selectedClass]:
            activeCategory && activeCategory.id === item.id
        };

        const editCategoryByRole = isAdminOrModerator(item.userRole) && (
          <IconButton className={styles.icon} onClick={this.iconClick}>
            <Pen />
          </IconButton>
        );

        const deleteCategoryByRole = isAdmin(item.userRole) && (
          <IconButton
            className={styles.icon}
            onClick={this.handleDeleteCategory}
          >
            <TrashBin />
          </IconButton>
        );

        const personAddByRole = isAdminOrModerator(item.userRole) && (
          <IconButton
            className={styles.icon}
            onClick={() => this.handleShareModal(true)}
          >
            <PersonAdd />
          </IconButton>
        );

        const icons = activeCategory &&
          activeCategory.id === item.id &&
          !isEditMode && (
            <>
              {editCategoryByRole}
              {deleteCategoryByRole}
              <IconButton
                className={styles.icon}
                onClick={(event) => this.setNotificationEl(event.currentTarget)}
              >
                <Bell />
              </IconButton>
              {personAddByRole}
            </>
          );

        const listItem =
          activeCategory && activeCategory.id === item.id && isEditMode ? (
            <TooltipSet
              message={errorMessage}
              isOpen={!isNewNameValid}
              type="error"
            >
              <Input
                error={!isNewNameValid}
                value={newName}
                autoFocus={true}
                className={styles.input}
                onBlur={this.handleInputBlur}
                onKeyPress={this.handleCategoryRename}
                onChange={this.handleInputChange}
                inputRef={this._categoryInputRef}
              />
            </TooltipSet>
          ) : (
              <Link to={`/dashboard/categories/${item.id}`}>
                <ListItemText>{item.name}</ListItemText>
              </Link>
            );

        return (
          <ListItem
            key={key}
            className={classNames(styles.listItem, selectedClass)}
            onClick={() => this.updateSelected(item)}
            selected={item && activeCategory && activeCategory.id === item.id}
          >
            {listItem}
          </ListItem>
        );
      });

    const newCategory = isEditNewActive ? (
      <div className={styles.newCategoryWrap}>
        <TooltipSet
          message={errorMessage}
          isOpen={!isNewCategoryValid}
          type="error"
        >
          <Input
            error={!isNewCategoryValid}
            value={newName}
            onChange={this.handleInputChange}
            autoFocus={true}
            onKeyPress={this.handleCategoryCreate}
            className={styles.input}
            placeholder="Add New Category"
            inputRef={this._newCategoryInputRef}
          />
        </TooltipSet>
        <IconButton onClick={this.handleCategoryCreate}>
          <DoneRoundedIcon color="primary" />
        </IconButton>
        <IconButton onClick={this.toggleNewCategory}>
          <ClearRoundedIcon />
        </IconButton>
      </div>
    ) : (
        <Button
            className={styles.addBtn}
            onClick={this.toggleNewCategory}
            ref={this._wizardRef}
        >
          <AddRoundedIcon fontSize="small" />
          Add New Category
        </Button>
      );

    const newCategoryByRole = isAdminOrModerator(roleOfUser) && newCategory;

    const content = (isLoadingOnFetch || isLoadingCategory) ? (
      <div className={styles.loader}>
        <Loader isLoading={true} isBackground={false} isNotAbsoloute={true}/>
      </div>
    ) : (
        <>
          {categoriesList}
          {newCategoryByRole}
        </>
      );
    return (
      <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
      { value === index && (
        <>
          <div className={styles.wrapper}>
            <Divider />
            {content}
          </div>
          <NotifiedOfUpdates
            popperEl={notificationEl}
            setPopperEl={this.setNotificationEl}
          />
          <ModalWindow
            type="confirm"
            message={confirmMessage}
            open={this.isModalWindowOpen(CategoriesModalWindows.confirmModal)}
            onClose={this.deleteCategory}
            isLoading={false}
          />
          <ModalWindow
            type="success"
            message={successMessage}
            open={this.isModalWindowOpen(CategoriesModalWindows.successModal)}
            onClose={this.closeModal}
            isLoading={isLoadingCategory}
          />
          <ModalWindow
            type="error"
            message={errorCreateMessage}
            open={this.isModalWindowOpen(CategoriesModalWindows.errorModal)}
            onClose={() => this.props.closeModalWindow(CategoriesModalWindows.errorModal)}
            isLoading={isLoadingCategory}
          />
        </>
      )}
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  accessToken: state.auth.profile.data.accessToken,
  activeCategory: state.categoriesState.activeCategory,
  categories: state.categoriesState.categories,
  errors: state.categoriesState.errors,
  isLoadingCategory: state.categoriesState.isLoadingCategory,
  isLoadingOnFetch: state.categoriesState.isLoadingOnFetch,
  modalWindows: state.modalWindowState.modalWindows,
  roleOfUser: state.auth.profile.role,
  wizardState: state.wizardState
});

const actions = {
  closeModalWindow,
  createCategory,
  deleteCategory,
  fetchCategories,
  openModalWindow,
  requestTokenUpdate,
  setActiveCategory,
  setActiveWizardStep,
  setNewWizardStep,
  syncCategoryWithDriveDirectory,
  updateCategory,
};

export default connect(mapStateToProps, actions)(Categories);
