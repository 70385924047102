import Types from './types';

export const fetchInvoice = (count?: number, offset?: number) => {
  return {
    payload: { count, offset },
    type: Types.FETCH_INVOICE_REQUEST
  };
};

export const fetchCard = () => {
  return {
    type: Types.FETCH_CARD_REQUEST
  };
};

export const createPaymentCard = (cardTokenId: string) => {
  return {
    payload: { cardTokenId },
    type: Types.CREATE_PAYMENTS_CARD_REQUEST
  };
};

export const upgradePlanWithoutCard = (
  isUpgradePlanWithoutCard: boolean,
  plan?: IPlan
) => {
  return {
    payload: { isUpgradePlanWithoutCard, plan },
    type: Types.UPGRADE_PLAN_WITHOUT_CARD
  };
};

export const subscribeToStripe = () => {
  return {
    type: Types.SUBSCRIBE_TO_STRIPE
  };
};

export const clearCreatingCardFailed = () => ({
  type: Types.CLEAR_CREATING_CARD_FAILED
});
