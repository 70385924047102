enum Types {
  SET_ADD_FILE_NOTIFICATION = '[Notifications] Set add file notification',
  ADD_FILE_NOTIFICATION = '[Notifications] Add file notification',
  ADD_NOTIFICATION = '[Notifications] Add notification',
  CHANGE_PERMISSION_NOTIFICATION = '[Notifications] Change permission notification',
  REMOVE_PERMISSION_NOTIFICATION = '[Notifications] Remove permission notification',
  SET_PERMISSIONS_CHANGED_NOTIFICATION = '[Notifications] Set permissions changed notification',

  READ_NOTIFICATION_REQUEST = '[Users] Read notification request',
  READ_NOTIFICATION_CALLBACK = '[Users] Read notification callback',

  READ_ALL_NOTIFICATIONS_REQUEST = '[Users] Read all notifications request',
  READ_ALL_NOTIFICATIONS_SUCCESS = '[Users] Read all notifications success',
  READ_ALL_NOTIFICATIONS_ERROR = '[Users] Read all notifications error',
}

export default Types;
