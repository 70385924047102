import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import Types from '../reducers/auth/types';
import { setToken, fetchProfile } from '@redux/reducers/auth/actions';
import { openModalWindow, closeModalWindow } from '@redux/reducers/modal-window/actions';
import { AccountModalWindows, InviteUserModalWindow, ShareModalWindow } from '@components/modal-window/modalWindowsTypes';
import axios from 'axios';
import configUrls from '../../config';

interface IConfig {
    data?: object;
    headers: object;
}

const middlware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);

  if (action.type === Types.SET_TOKEN) {
    store.dispatch(fetchProfile());
  }

  if (action.type === Types.ACCESS_VIA_LINK) {
      store.dispatch(
          api({
              body: action.payload,
              method: 'post',
              onError: Types.ACCESS_VIA_LINK_ERROR,
              onSuccess: Types.ACCESS_VIA_LINK_SUCCESS,
              url: `/categories/access-share-link`
          })
      );
  }

  if (action.type === Types.PROFILE_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.AUTH_ERROR,
        onSuccess: Types.PROFILE_SUCCESS,
        url: `/auth/profile`
      })
    );
  }

  if (action.type === Types.FETCH_USERS_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.AUTH_ERROR,
        onSuccess: Types.FETCH_USERS_SUCCESS,
        url: `/auth/users`
      })
    );
  }

  if (action.type === Types.FETCH_USERS_EMAIL_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.AUTH_ERROR,
        onSuccess: Types.FETCH_USERS_EMAIL_SUCCESS,
        url: `/auth/users-emails`
      })
    );
  }

  if (action.type === Types.CREATE_COMPANY_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.AUTH_ERROR,
        onSuccess: Types.CREATE_COMPANY_SUCCESS,
        url: `/auth/finish`
      })
    );
  }

  if (action.type === Types.CREATE_COMPANY_SUCCESS) {
    store.dispatch(setToken(action.payload.token));
    store.dispatch(fetchProfile());
  }

  if (action.type === Types.CLOSE_ACCOUNT_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.AUTH_ERROR,
        onSuccess: Types.CLOSE_ACCOUNT_SUCCESS,
        url: `/auth/request-close`
      })
    );
  }

  if (action.type === Types.CLOSE_ACCOUNT_SUCCESS) {
    store.dispatch(openModalWindow(AccountModalWindows.successModal, 'auth'));
  }

  if (action.type === Types.INVITE_USERS_REQUEST) {
    store.dispatch(
      api({
          method: 'get',
          onError: Types.INVITE_USERS_NOT_ALLOWED,
          onSuccess: Types.INVITE_USERS_ALLOWED,
          url: `/auth/check-invite-users`
      })
    );
  }

  if (action.type === Types.INVITE_USERS_ALLOWED) {
      store.dispatch(
          api({
              body: action.payload,
              method: 'post',
              onError: Types.INVITE_USERS_ERROR,
              onSuccess: Types.INVITE_USERS_SUCCESS,
              url: `/auth/invite-users`
          })
      );
  }

  if (action.type === Types.REINVITE_USER_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.AUTH_ERROR,
        onSuccess: Types.REINVITE_USER_SUCCESS,
        url: `/auth/reinvite-user`
      })
    );
  }

  if (action.type === Types.SWITCH_USER_ROLE_REQUEST) {
    store.dispatch(
      api({
        body: { userId: action.payload.userId, role: action.payload.role },
        method: 'put',
        onError: Types.SWITCH_USER_ROLE_ERROR,
        onSuccess: Types.SWITCH_USER_ROLE_SUCCESS,
        url: `/auth${action.payload.url}/switch-role`
      })
    );
  }

  if (action.type === Types.SWITCH_USER_STATUS_REQUEST) {
    store.dispatch(
      api({
        method: 'put',
        onError: Types.SWITCH_USER_STATUS_ERROR,
        onSuccess: Types.SWITCH_USER_STATUS_SUCCESS,
        url: `/auth/switch-status/${action.payload}`
      })
    );
  }

  if (action.type === Types.SHARE_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.SHARE_CATEGORY_ERROR,
        onSuccess: Types.SHARE_CATEGORY_SUCCESS,
        url: `/categories/invite`
      })
    );
  }

  if (action.type === Types.SHARE_INVITED_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'put',
        onError: Types.SHARE_INVITED_CATEGORY_ERROR,
        onSuccess: Types.SHARE_INVITED_CATEGORY_SUCCESS,
        url: `/auth/invited-categories`
      })
    );
  }

  if (action.type === Types.CLEAR_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'put',
        onError: Types.CLEAR_CATEGORY_ERROR,
        onSuccess: Types.CLEAR_CATEGORY_SUCCESS,
        url: `/categories/invite-change`
      })
    );
  }

  if (action.type === Types.CLEAR_INVITED_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'put',
        onError: Types.CLEAR_INVITED_CATEGORY_ERROR,
        onSuccess: Types.CLEAR_INVITED_CATEGORY_SUCCESS,
        url: `/auth/clear-categories`
      })
    );
  }

  if (action.type === Types.DELETE_USER_REQUEST) {
    store.dispatch(
      api({
        method: 'delete',
        onError: Types.DELETE_USER_ERROR,
        onSuccess: Types.DELETE_USER_SUCCESS,
        url: `/auth${action.payload.url}/${action.payload.id}`
      })
    );
  }

  if (action.type === Types.INVITE_USERS_SUCCESS) {
    store.dispatch(closeModalWindow(InviteUserModalWindow.inviteModal));
    store.dispatch(openModalWindow(InviteUserModalWindow.successModal, 'inviteUser'));
  }

  if (action.type === Types.SHARE_CATEGORY_SUCCESS) {
    store.dispatch(openModalWindow(ShareModalWindow.successModal, 'share'));
  }
};

export default middlware as Middleware;
