import React, { useRef, useEffect, useState } from 'react';
import { Card, CardActionArea, CardActions, CardContent, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles.m.scss';
import {connect} from 'react-redux';
import {setActiveWizardStep, setWizardIsActive} from '@redux/reducers/wizard/actions';
import {isAdminOrModerator} from '@services/user-role';
import {setNewUser} from '@redux/reducers/auth/actions';

interface IReduxProps {
    auth: IAuth;
    wizard: IWizardState;
    setWizardIsActive: typeof setWizardIsActive;
    setActiveWizardStep: typeof setActiveWizardStep;
    setNewUser: typeof setNewUser;
}

const TOTAL_STEPS = 5;
const FINAL_STEP = 5;

const WizardTooltip: React.FC<IReduxProps> = (props) => {
    const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
    const [tooltipArrowStyle, setTooltipArrowStyle] = useState<React.CSSProperties>({});

    useEffect(() => {
        const calculateTooltipPosition = () => {
            if (
                props.wizard.activeStep < 0 ||
                !props.wizard.wizardSteps[props.wizard.activeStep] ||
                !props.wizard.wizardSteps[props.wizard.activeStep].position
            ) {
                return;
            }
            const elementPosition = props.wizard.wizardSteps[props.wizard.activeStep].position;
            switch (props.wizard.activeStep) {
                case 0:
                    setTooltipStyle({
                        left: elementPosition.right - 20,
                        top: elementPosition.top
                    });
                    setTooltipArrowStyle({
                        borderBottom: '10px solid transparent',
                        borderRight: '25px solid',
                        borderTop: '10px solid transparent',
                        left: elementPosition.right - 30,
                        top: elementPosition.top + 5
                    });
                    break;
                case 1:
                    setTooltipStyle({
                        left: elementPosition.left,
                        top: elementPosition.bottom
                    });
                    setTooltipArrowStyle({
                        borderBottom: '25px solid',
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        left: elementPosition.left + 15,
                        top: elementPosition.bottom - 10
                    });
                    break;
                case 2:
                    setTooltipStyle({
                        left: elementPosition.left,
                        top: elementPosition.bottom + 5
                    });
                    setTooltipArrowStyle({
                        borderBottom: '25px solid',
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        left: elementPosition.left + 15,
                        top: elementPosition.bottom - 10
                    });
                    break;
                case 3:
                    setTooltipStyle({
                        right: elementPosition.left - 10,
                        top: elementPosition.top + 5
                    });
                    setTooltipArrowStyle({
                        borderBottom: '10px solid transparent',
                        borderRight: '25px solid',
                        borderTop: '10px solid transparent',
                        left: elementPosition.right,
                        top: elementPosition.top + 10
                    });
                    break;
                case 4:
                    setTooltipStyle({
                        left: 'calc(50% + 360px)',
                        top: 'calc(50% - 300px)',
                    });
                    setTooltipArrowStyle({
                        borderBottom: '0px',
                        borderLeft: '10px solid transparent',
                        borderRight: '10px solid transparent',
                        borderTop: '25px solid',
                        left: 'calc(50% + 370px)',
                        top: 'calc(50% - 120px)',
                    });
                    break;
                case 5:
                    setTooltipStyle({
                        left: elementPosition.right - 20,
                        top: elementPosition.top - 130
                    });
                    setTooltipArrowStyle({
                        borderBottom: '10px solid transparent',
                        borderRight: '25px solid',
                        borderTop: '10px solid transparent',
                        left: elementPosition.right - 30,
                        top: elementPosition.top + 5
                    });
                    break;
            }
        };
        calculateTooltipPosition();
        window.addEventListener('resize', calculateTooltipPosition);
        return () => window.removeEventListener('resize', calculateTooltipPosition);
    }, [props.wizard]);

    const turnOffWizard = () => {
      props.setWizardIsActive(false);
      props.setActiveWizardStep(-1);
      props.setNewUser(false);
    };

    return ( props.wizard.isWizardActive &&
        (
            <div className={styles.tooltipContainer} style={tooltipStyle}>
                <div
                    className={classNames(styles.tooltipArrow)}
                    style={tooltipArrowStyle}
                />
                <Card className={classNames(styles.tooltip)}>
                    <CardContent className={classNames(styles.tooltipContent)}>
                        <Typography gutterBottom={true} variant="h5" component="h2">
                            {props.wizard.wizardSteps[props.wizard.activeStep]?.content?.title}
                        </Typography>
                        {props.wizard.activeStep < FINAL_STEP && (
                            <Typography className={classNames(styles.tooltipStep)} color="textSecondary">
                                {props.wizard.activeStep + 1}/{TOTAL_STEPS}
                            </Typography>
                        )}
                        <Typography variant="body2" component="p" className={classNames(styles.secondaryContent)}>
                            {props.wizard.wizardSteps[props.wizard.activeStep]?.content?.text}
                        </Typography>
                    </CardContent>
                    {props.wizard.activeStep === FINAL_STEP && (
                        <CardActionArea onClick={turnOffWizard} className={classNames(styles.cardActionArea)}>
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    component="p"
                                    className={classNames(styles.gotItButton)}
                                >
                                    Got it!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    )}
                </Card>
            </div>
        )
    );
};

const mapStateToProps = (state: IStore) => ({
    auth: state.auth,
    wizard: state.wizardState
});

const actions = {
    setActiveWizardStep,
    setNewUser,
    setWizardIsActive,
};

export default connect(mapStateToProps, actions)(WizardTooltip);
