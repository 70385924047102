import { combineReducers, AnyAction } from 'redux';
import { reducer as form } from 'redux-form';
import auth from './auth';
import wizardState from './wizard';
import sectionsState from './sections';
import categoriesState from './categories';
import filesState from './files';
import notificationsState from './notifications';
import paymentsState from './payments​';
import modalWindowState from './modal-window';
import projectState from './projects';
import dashboardState from './dashboard';
import Types from './auth/types';

const appReducer = combineReducers<IStore>({
  auth,
  categoriesState,
  dashboardState,
  filesState,
  form,
  modalWindowState,
  notificationsState,
  paymentsState,
  projectState,
  sectionsState,
  wizardState,
});

const rootReducer = (state: IStore, action: AnyAction) => {
  if (action.type === Types.USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
