import React, { useState, createRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { syncCategoryWithDriveDirectory } from '@redux/reducers/categories/actions';
import { handleSelectFromDriveModal } from '@redux/reducers/files/actions';
import { Modal, Fade, Backdrop, Button } from '@material-ui/core';
import AddFileMain from './content/main';
import AddFileLink from './content/link';
import AddFileUpload from './content/upload';

import Close from '@assets/svg/close.svg?tag';
import styles from './styles.m.scss';
import Plausible from 'plausible-tracker';
import {setActiveWizardStep, setNewWizardStep} from '@redux/reducers/wizard/actions';

export type ContentType = 'main' | 'link' | 'upload';

interface IReduxProps {
  activeCategory: ICategory;
  sectionId: string;
  isSelectFromDrive: boolean;
  wizard: IWizardState;
  syncCategoryWithDriveDirectory: typeof syncCategoryWithDriveDirectory;
  setActiveWizardStep: typeof setActiveWizardStep;
  setNewWizardStep: typeof setNewWizardStep;
  handleSelectFromDriveModal: typeof handleSelectFromDriveModal;
}

interface IOwnProps {
  open: boolean;
  setOpen: (value?: boolean | IPickerResponse) => void;
  save: (name: string, url: string) => void;
  saveFile: (file: File) => void;
  typeFile: GoogleDocumentType;
  createGoogleDocFile: (name: string, type: GoogleDocumentType) => void;
  handleError: () => void;
  onCloseFromWizard?: () => void;
  accessToken?: string;
}

interface IProps extends IReduxProps, IOwnProps {}

const AddFile = (props: IProps) => {
  const {
    open,
    setOpen,
    save,
    typeFile,
    createGoogleDocFile,
    isSelectFromDrive
  } = props;
  const [type, setType] = useState<ContentType>('main');
  const [googleDocFileType, setGoogleDocFileType] = useState<
    GoogleDocumentType
  >('');
  const fileLinkRef = createRef<HTMLInputElement>();
  const fileLinkNameRef = createRef<HTMLInputElement>();
  const googleFileNameRef = createRef<HTMLInputElement>();
  const [file, setFile] = useState<File>(null);
  const [isLinkInvalid, setIsLinkInvalid] = useState<boolean>(false);
  const [isLinkNameInvalid, setIsLinkNameInvalid] = useState<boolean>(false);
  const [isGoogleNameInvalid, setIsGoogleNameInvalid] = useState<boolean>(
    false
  );
  const [pickedDirectoryId, setPickedDirectoryId] = useState<string>('');
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  const setFileType = (newType: ContentType) => {
    if (googleDocFileType) {
      setGoogleDocFileType('');
    }
    setType(newType);
  };

  const getMaxFileSize = () => {
    let maxFileSize = 100_000_000;
    if (process.env.MAX_FILE_SIZE_UPLOAD !== undefined) {
      maxFileSize = parseInt(process.env.MAX_FILE_SIZE_UPLOAD, 10) * 1_000_000;
    }
    return maxFileSize;
  };

  const validateFile = (newFile: File) => {
    if (newFile && newFile.size < getMaxFileSize()) {
      return true;
    }
    return false;
  };

  const handleSave = () => {
    plausible.trackEvent('Add File', {
      props: {
        type
      }
    });
    if (type === 'link') {
      if (
        fileLinkRef.current &&
        isValidUrl(fileLinkRef.current.value) &&
        fileLinkNameRef.current &&
        isValidName(fileLinkNameRef.current.value)
      ) {
        save(fileLinkNameRef.current.value, fileLinkRef.current.value);
        if (props.wizard.isWizardActive) {
          props.setActiveWizardStep(5);
        }
      } else {
        if (!fileLinkRef.current || !isValidUrl(fileLinkRef.current.value)) {
          setIsLinkInvalid(true);
        }
        if (
          !fileLinkNameRef.current ||
          !isValidName(fileLinkNameRef.current.value)
        ) {
          setIsLinkNameInvalid(true);
        }
      }
    } else if (validateFile(file) && type === 'upload') {
      props.saveFile(file);
      if (props.wizard.isWizardActive) {
        props.setActiveWizardStep(5);
      }
    } else if (type === 'main') {
      if (
        googleDocFileType &&
        googleFileNameRef.current &&
        isValidName(googleFileNameRef.current.value)
      ) {
        createGoogleDocFile(googleFileNameRef.current.value, googleDocFileType);
        if (props.wizard.isWizardActive) {
          props.setActiveWizardStep(5);
        }
      } else {
        setIsGoogleNameInvalid(true);
      }
    } else {
      setIsSaveDisabled(true);
    }
  };

  const updateFile = (newFile: File) => {
    if (validateFile(newFile)) {
      setFile(newFile);
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const isValidUrl = (url: string) => {
    try {
      const valid = new URL(url);
      return !!valid.hostname.match(/\w+\.\w+/);
    } catch (error) {
      return false;
    }
  };

  const isValidName = (name: string) => {
    return !!name.trim();
  };

  const closeAddFile = () => {
    setOpen(false);
    props.setActiveWizardStep(2);
  };

  const resetLinkErrors = (linkInvalid: boolean) => {
    setIsLinkInvalid(linkInvalid);
    setIsLinkNameInvalid(linkInvalid);
    setIsSaveDisabled(linkInvalid);
  };

  const onSyncCategories = (googleFolderId: string) => {
    setPickedDirectoryId('');
    props.setOpen(false);
    props.handleSelectFromDriveModal(false);
    if (!props) {
      return;
    }
    props.syncCategoryWithDriveDirectory(props.activeCategory.id, googleFolderId, props.sectionId);
  };

  let description;
  const checkType = (contentType: string) => {
    if (contentType === 'main') {
      description = 'Choose below to add a file';
      return (
        <AddFileMain
          accessToken={props.accessToken}
          typeFile={typeFile}
          activeFileType={googleDocFileType}
          changeType={setGoogleDocFileType}
          handleSelectFromDriveModal={props.handleSelectFromDriveModal}
          closeModal={setOpen}
          handleError={props.handleError}
          setType={setFileType}
          inputRef={googleFileNameRef}
          isGoogleNameInvalid={isGoogleNameInvalid}
          wizard={props.wizard}
          resetTooltip={() => setIsGoogleNameInvalid(false)}
          setPickedDirectoryId={onSyncCategories}
          setNewWizardStep={props.setNewWizardStep}
          setActiveWizardStep={props.setActiveWizardStep}
        />
      );
    } else if (contentType === 'link') {
      description =
        'Add a file from another service such as Dropbox, Airtable, WordPress, etc.';
      return (
        <AddFileLink
          inputRef={fileLinkRef}
          nameInputRef={fileLinkNameRef}
          linkInvalid={isLinkInvalid}
          linkNameInvalid={isLinkNameInvalid}
          setLinkInvalid={resetLinkErrors}
        />
      );
    } else if (contentType === 'upload') {
      return (
        <AddFileUpload updateFile={updateFile} isInvalid={isSaveDisabled} />
      );
    }
  };

  const content = checkType(type);

  const useAnotherTypeOfAdding = () => {
    setFileType('main');
    if (props.wizard.isWizardActive) {
      props.setActiveWizardStep(3);
    }
  };

  const footer = (type === 'link' ||
    type === 'upload' ||
    (type === 'main' && googleDocFileType)) && (
    <footer className={styles.cancelSave}>
      {type !== 'main' && (
        <Button className={styles.linkToMain} onClick={useAnotherTypeOfAdding}>
          Use another type of adding
        </Button>
      )}
      <Button
        className={classNames(styles.btnModal, styles.btnCancel)}
        disabled={props.wizard.isWizardActive}
        onClick={closeAddFile}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        className={styles.btnModal}
        disabled={isSaveDisabled}
        onClick={handleSave}
      >
        Save
      </Button>
    </footer>
  );

  return (
      <Modal
        className={styles.modal}
        open={open && !isSelectFromDrive}
        onClose={closeAddFile}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open && !isSelectFromDrive}>
          <div className={classNames(styles.paper, styles.fadeIn)}>
            <header className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Add File(s)</h2>
              <p className={styles.modalDescription}>{description}</p>
            </header>
            {content}
            {footer}
            <Button className={styles.closeBtn} onClick={props.onCloseFromWizard || closeAddFile}>
              <Close />
            </Button>
          </div>
        </Fade>
      </Modal>
  );
};

const mapStateToProps = (state: IStore) => ({
  isSelectFromDrive: state.filesState.isSelectFromDrive,
  wizard: state.wizardState
});

const actions = {
  handleSelectFromDriveModal,
  setActiveWizardStep,
  setNewWizardStep,
  syncCategoryWithDriveDirectory,
};

export default connect(mapStateToProps, actions)(AddFile);
