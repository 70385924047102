import React, { useState, useEffect, createRef } from 'react';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import { fetchProjectUsers, convertProject } from '@redux/reducers/projects/actions';
import { getUsersEmail, fetchUsers } from '@redux/reducers/auth/actions';
import { getUsersArray } from '@redux/reducers/auth';
import {
  clearCreatingCardFailed,
  upgradePlanWithoutCard
} from '@redux/reducers/payments​/actions';
import {
  closeModalWindow,
  openModalWindow
} from '@redux/reducers/modal-window/actions';
import {
  OutlinedInput,
  Divider,
  IconButton,
  Modal,
  Backdrop,
  TextField,
  Fade,
  FormControlLabel,
  Button,
  withStyles,
  Menu,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import { isEmailValidate } from '@utils/validations';
import TooltipSet from '@components/custom-input-tooltip';
import ModalWindow from '@components/modal-window';
import EditPaymentDetails from '@components/modal-edit-payment-details';
import AutomaticalUpgradePlan from '@components/modal-automatical-upgrade-plan';
import {
  PaymentDetailsModalWindows,
  UpdatePlanModalWindow,
  UpgradeModalWindows
} from '@components/modal-window/modalWindowsTypes';
import Loader from '@components/loader';
import { isModalWindowOpen } from '@services/modal-windows';

import { useStylesCheckbox } from '@styles/checkbox';
import Pen from '@assets/svg/pen.svg?tag';
import TrashBin from '@assets/svg/trashBin.svg?tag';
import Upload from '@assets/svg/upload.svg?tag';
import styles from './styles.m.scss';

interface IReduxProps {
  activePlan: IPlan;
  allUsers: IUserEmail[];
  errors: string[];
  users: IProjectUser[];
  companyUsers: IUser[];
  activeProject: ICategory;
  projects: ICategory[];
  categories: ICategory[];
  isLoading: boolean;
  isLoadingProjects: boolean;
  isLoadingOnFetchUsers: boolean;
  isCardCreatingFailed: boolean;
  isOwner: boolean;
  modalWindows: any;
  card: ICard;
  convertProject: typeof convertProject;
  fetchProjectUsers: typeof fetchProjectUsers;
  fetchUsers: typeof fetchUsers;
  getUsersEmail: typeof getUsersEmail;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
  upgradePlanWithoutCard: typeof upgradePlanWithoutCard;
  clearCreatingCardFailed: typeof clearCreatingCardFailed;
}

interface IOwnProps {
  open: boolean;
  setOpen: (value?: boolean) => void;
}

const mapStateToProps = (state: IStore) => {
  const companyUsers = getUsersArray(state.auth);
  return {
    activePlan: state.paymentsState.activePlan,
    activeProject: state.projectState.activeProject,
    allUsers: state.auth.usersEmails,
    card: state.paymentsState.card,
    categories: state.categoriesState.categories,
    companyUsers,
    errors: state.projectState.errors,
    isCardCreatingFailed: state.paymentsState.isCardCreatingFailed,
    isLoading: state.auth.isLoading,
    isLoadingOnFetchUsers: state.projectState.isLoadingOnFetchUsers,
    isLoadingProjects: state.projectState.isLoadingProject,
    isOwner: state.auth.profile.company.owner,
    modalWindows: state.modalWindowState.modalWindows,
    projects: state.projectState.projects,
    users: state.projectState.users,
  };
};

const actions = {
  clearCreatingCardFailed,
  closeModalWindow,
  convertProject,
  fetchProjectUsers,
  fetchUsers,
  getUsersEmail,
  openModalWindow,
  upgradePlanWithoutCard,
};

interface IProps extends IReduxProps, IOwnProps { }

const Errors = Object.freeze({
  nameIsNotUnique: 'Name has to be unique',
  nameIsSmall: 'Name has to have at least 3 letters',
});

const MenuFileItem = withStyles(() => ({
  root: {
    color: '#757575',
    minHeight: 'unset',
    padding: '5px 15px',
    width: '130px'
  }
}))(MenuItem);

const AutocompleteItem = withStyles(() => ({
  option: {
    padding: '5px'
  },
  paper: {
    borderRadius: '0',
    fontSize: '13px',
    margin: '0',
    padding: '0'
  }
}))(Autocomplete);

type UploadEmailsError =
  | 'invalid'
  | 'no-unique'
  | 'file-extension'
  | 'reading-file';

type UserRoleMenuBar = 'moderator' | 'user';

const ConvertProject = (props: IProps) => {
  const {
    allUsers,
    errors,
    open,
    users,
    activeProject,
    isLoadingOnFetchUsers,
    isLoadingProjects,
    isOwner,
    fetchProjectUsers: fetchUsersProject,
    setOpen,
  } = props;
  const [newName, setNewName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorEmailMessage, setErrorEmailMessage] = useState('');
  const [errorSubmitMessage, setErrorSubmitMessage] = useState('');
  const [isNewNameValid, setIsNewNameValid] = useState(true);
  const [newUsers, setNewUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isAddUsersMode, setIsAddUsersMode] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<UserRoleMenuBar>('user');
  const [optionEl, setOptionEl] = useState<HTMLElement | null>(null);
  const [isUpgradePlanOpen, setIsUpgradePlanOpen] = useState(false);
  const [isAutomaticallyPlanOpen, setIsAutomaticallyPlanOpen] = useState(false);
  const [newActivePlan, setNewActivePlan] = useState<IPlan>(null);
  const [selectedPlan, setSelectedPlan] = useState<IPlan>(null);
  const [isConverting, setIsConverting] = useState<boolean>(false);

  const _projectInputRef = createRef<HTMLInputElement>();
  const _emailInputRef = createRef<HTMLInputElement>();
  const _userListInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (open && activeProject) {
      fetchUsersProject(activeProject.id);
      setNewName(activeProject.name);
      props.getUsersEmail();

      if (isOwner) {
        props.fetchUsers();
      }
    }
  }, [open, activeProject]);

  useEffect(() => {
    if (!isLoadingOnFetchUsers) {
      setNewUsers(users);
    }
  }, [users]);

  useEffect(() => {
    if (isConverting && !isLoadingProjects) {
      if (errors.includes('USER_EXISTS') || errors.includes('USERS_INVITED')) {
        setErrorSubmitMessage('Some of the users already use Neatly with another company');
      } else {
        props.setOpen(false);
      }

      setIsConverting(false);
    }
  }, [isLoadingProjects]);

  const checkboxClass = useStylesCheckbox({});

  const setUploadError = (email: string, errorType: UploadEmailsError) => {
    const finalEmail = email.length > 25 ? email.slice(0, 25) + '...' : email;
    switch (errorType) {
      case 'invalid':
        setErrorEmailMessage(`${finalEmail} is not valid email address`);
        break;
      case 'no-unique':
        setErrorEmailMessage(`${finalEmail} is not unique email address`);
        break;
      case 'file-extension':
        setErrorEmailMessage('Supported only Excel files');
        break;
      case 'reading-file':
        setErrorEmailMessage('Can\'t read the file');
        break;
    }
  };

  const validateEmails = (emails: string[]) => {
    const emailsSet = new Set(emails);
    emailsSet.forEach((email) => {
      email.toLowerCase();
      if (!isEmailValidate(email)) {
        setUploadError(email, 'invalid');
      }

      const isExistEmail = newUsers.find((u) => u.email === email);
      if (isExistEmail) {
        setUploadError(email, 'no-unique');
      }
    });
  };

  const onAddUser = () => {
    if (
      _emailInputRef.current &&
      isEmailValidate(_emailInputRef.current.value)
    ) {
      const email = _emailInputRef.current.value;
      const existEmailInSelected = selectedUsers.find((u) => u.email === email);
      const existEmailInNewUsers = newUsers.find((u) => u.email === email);

      if (existEmailInNewUsers || existEmailInSelected) {
        setUploadError(email, 'no-unique');
      } else {
        setSelectedUsers([
          ...selectedUsers,
          { email }
        ]);
      }
    } else {
      setUploadError(_emailInputRef.current.value, 'invalid');
    }

    selectedUsers.forEach((u) => validateEmails([u.email]));
  };

  const onAddUsersDone = () => {
    const addedUsers = selectedUsers.map((u) => {
      return {
        email: u.email,
        role: selectedUserRole,
      };
    });

    setNewUsers([...newUsers, ...addedUsers]);
    setSelectedUsers([]);
    setIsAddUsersMode(false);
  };

  const onAddUsersCancel = () => {
    setSelectedUserRole('user');
    setSelectedUsers([]);
    setIsAddUsersMode(false);
    setErrorEmailMessage('');
  };

  const processData = (dataString: string): string[] => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list: string[] = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: string[] = [];
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') {
              d = d.substring(1, d.length - 1);
            }
            if (d[d.length - 1] === '"') {
              d = d.substring(d.length - 2, 1);
            }
          }
          if (headers[j]) {
            obj.push(d);
          }
        }

        if (obj.filter((x) => x).length > 0) {
          list.push(...obj.filter((x) => x));
        }
      }
    }
    return list;
  };

  const processXLSXFile = (evt: ProgressEvent<FileReader>) => {
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    const wsName = wb.SheetNames[0];
    const ws = wb.Sheets[wsName];
    const data = XLSX.utils.sheet_to_csv(ws);
    const emails = processData(data);

    validateEmails(emails);
    const uniqueEmails = emails.filter((e) => !selectedUsers.find((u) => u.email === e)).map((u) => ({ email: u }));
    setSelectedUsers([...selectedUsers, ...uniqueEmails]);
  };

  const processTextFile = (evt: ProgressEvent<FileReader>) => {
    const contents = evt.target.result as string;
    const emails: string[] = contents.trim().split(/\r?\n/);

    validateEmails(emails);
    const uniqueEmails = emails.filter((e) => !selectedUsers.find((u) => u.email === e)).map((u) => ({ email: u }));
    setSelectedUsers([...selectedUsers, ...uniqueEmails]);
  };

  const placeholderUsersTextField =
    selectedUsers && selectedUsers.length === 0
      ? 'Enter names or email addresses...'
      : '';

  const usersTextField = (params: RenderInputParams) => (
    <TextField
      {...params}
      error={!!errorEmailMessage}
      placeholder={placeholderUsersTextField}
      fullWidth={true}
      className={styles.usersTextField}
      inputRef={_emailInputRef}
      onKeyPress={handleAddEmailForFile}
    />
  );

  const isChecked = (type: 'view' | 'edit', user: IProjectUser) => {
    if (user.role === 'admin') {
      return true;
    }

    return type === 'view' ? user.role === 'user' : user.role === 'moderator';
  };

  const handleAddEmailForFile = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (errorEmailMessage) {
      setErrorEmailMessage('');
    }
    if (event && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      onAddUser();
    }
  };

  const isLengthValid = (value: string) => {
    if (!(value.trim().length > 1)) {
      setErrorMessage(Errors.nameIsSmall);
      setIsNewNameValid(false);
      return false;
    }

    return true;
  };

  const isValueUnique = (value: string, isNew: boolean) => {
    const items = props.categories.concat(props.projects);

    if (items.length) {
      const item = items.find((i) =>
        i.name === value && isNew
      );

      if (item) {
        setErrorMessage(Errors.nameIsNotUnique);
        setIsNewNameValid(false);
        return false;
      }
    }
    return true;
  };

  const isFieldValid = (value: string, isNew: boolean) => {
    if (isLengthValid(value) && isValueUnique(value, isNew)) {
      return true;
    }

    return false;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewName(e.currentTarget.value);
    const isNew = e.currentTarget.value !== activeProject?.name;
    if (isFieldValid(e.currentTarget.value, isNew)) {
      setErrorMessage('');
      setIsNewNameValid(true);
    }
  };

  const handleDeleteUser = (user: IProjectUser) => {
    setErrorSubmitMessage('');
    setNewUsers(newUsers.filter((item) => item.email !== user.email));
  };

  const handleChangePermission = (user: IProjectUser, userRole: UserRoleType) => {
    const updatedUsers = newUsers.map((u) => {
      if (u.email === user.email) {
        u.role = userRole;
      }

      return u;
    });

    setNewUsers(updatedUsers);
  };

  const name = (
    <TooltipSet
      message={errorMessage}
      isOpen={!isNewNameValid}
      type="error"
      isModal={true}
    >
      <OutlinedInput
        error={!isNewNameValid}
        value={newName}
        autoFocus={true}
        className={styles.input}
        onChange={handleInputChange}
        inputRef={_projectInputRef}
        labelWidth={0}
      />
    </TooltipSet>
  );

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    if (file.type === 'text/plain') {
      reader.onload = (evt) => processXLSXFile(evt);
      reader.readAsText(file);
    } else {
      reader.onload = (evt) => processTextFile(evt);
      reader.readAsBinaryString(file);
    }
  };

  const categoryName = (
    <div className={styles.nameWrap}>
      <h3 className={styles.sectionTitle}>Category name</h3>
      <div className={styles.editNameWrap}>
        {name}
      </div>
    </div>
  );

  const onSubmit = () => {
    const usersNotInCompany = newUsers.filter(
      (nu) => !props.companyUsers.find((u) => (u.email === nu.email || u.data?.email === nu.email))
    );
    if (isOwner) {
      setIsAutomaticallyPlanOpen(true);
    } else {
      const usersForConvert = newUsers.map((u) => ({
        email: u.email,
        role: u.role,
      }));

      props.convertProject(activeProject.id, newName, usersForConvert);
      setIsConverting(true);
    }
  };

  let usersList;

  if (props.open) {
    usersList = newUsers.map((user, index) => {
      const deleteUserByAdmin = (isOwner || !user.userId) &&
        user.role !== 'admin' && (
        <IconButton
          className={styles.icon}
          onClick={() => handleDeleteUser(user)}
        >
          <TrashBin/>
        </IconButton>
      );
      const icon = (
        <span
          className={classNames(checkboxClass.icon, checkboxClass.checkedIcon)}
        />
      );

      const checkbox = (checked: boolean) => (
        <Checkbox
          checked={checked}
          checkedIcon={icon}
          disabled={user.role === 'admin'}
          icon={<span className={classNames(checkboxClass.icon)} />}
          className={checkboxClass.root}
          value="edit"
          color="primary"
        />
      );

      const checkboxes =
        isOwner &&
        (user.role !== 'admin' ? (
          <>
            <FormControlLabel
              key={`${index}$view`}
              control={checkbox(isChecked('view', user))}
              label="view"
              onClick={() => handleChangePermission(user, 'user')}
              className={classNames(
                styles.checkbox,
                user.role === 'admin' && styles.disabledCheckbox
              )}
            />
            <FormControlLabel
              key={`${index}$edit`}
              control={checkbox(isChecked('edit', user))}
              label="edit"
              onClick={() => handleChangePermission(user, 'moderator')}
              className={classNames(
                styles.checkbox,
                user.role === 'admin' && styles.disabledCheckbox
              )}
            />
          </>
        ) : (
          <p className={styles.owner}>owner</p>
        ));

      return (
        <div className={styles.userWrap} key={index}>
          <div className={styles.userEmail}>{user.email}</div>
          <div className={styles.iconWrap}>
            {checkboxes}
            {deleteUserByAdmin}
          </div>
        </div>
      );
    });
  }

  const getOnOptionsText = () => (
    <div className="nonUser" onClick={onAddUser}>
      isn't a user...{' '}
      <Button className="button">invite to Join Neatly</Button>
    </div>
  );

  const onChangeSelectedUsers = (_event: React.ChangeEvent<{}>, value: any[]) => {
    if (errorEmailMessage) {
      setErrorEmailMessage('');
    }

    value.forEach((u) => validateEmails([u.email]));
    setSelectedUsers(value);
  };

  const handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionEl(event.currentTarget);
  };

  const handleUserRole = (value: UserRoleMenuBar) => {
    setSelectedUserRole(value);
  };

  const iconUserRole =
    selectedUserRole === 'moderator' ? (
      <CreateIcon />
    ) : (
      selectedUserRole === 'user' && <VisibilityIcon />
    );

  const optionBtnForAdmin = (
    <Button className={styles.handleBtn} onClick={handleOpenOptions}>
      {iconUserRole}
      <ArrowDropDownIcon className={styles.dropDown} />
    </Button>
  );

  const optionBtnForModerator = (
    <Button className={styles.handleBtn} disabled={true}>
      {iconUserRole}
    </Button>
  );

  const allUsersWithoutExists = allUsers.filter((u) => !newUsers.find((nu) => nu.email === u.email));
  const optionBtnByRole = isOwner ? optionBtnForAdmin : optionBtnForModerator;

  const selectMenuItem = (value: UserRoleMenuBar): React.ReactNode =>
    selectedUserRole === value ? (
      <CheckIcon className={styles.checkIcon} />
    ) : null;

  const addNewUsers = isAddUsersMode && (
    <div className={styles.addMoreUsers}>
      <Divider />
      <div className={styles.addMoreUsersContent}>
        <div className={styles.contentInputEmail}>
          <h3 className={styles.sectionTitle}>Add people</h3>
          <TooltipSet
            message={errorEmailMessage}
            isOpen={!!errorEmailMessage}
            type="error"
            isModal={true}
          >
            <div className={styles.inputBlock}>
                <AutocompleteItem
                  value={selectedUsers}
                  className={styles.autocomplete}
                  multiple={true}
                  debug={true}
                  options={allUsersWithoutExists}
                  getOptionLabel={(option) => option.email}
                  renderInput={(params) => usersTextField(params)}
                  noOptionsText={getOnOptionsText()}
                  onChange={onChangeSelectedUsers}
                />
              {optionBtnByRole}
              <Menu
                elevation={1}
                anchorEl={optionEl}
                open={Boolean(optionEl)}
                onClose={() => setOptionEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{ vertical: 'top', horizontal: 'right'}}
              >
                <MenuFileItem onClick={() => handleUserRole('moderator')}>
                  View &amp; edit
                  {selectMenuItem('moderator')}
                </MenuFileItem>
                <MenuFileItem onClick={() => handleUserRole('user')}>
                  View
                  {selectMenuItem('user')}
                </MenuFileItem>
              </Menu>
            </div>
          </TooltipSet>
        </div>
        <footer className={styles.addMoreUsersDone}>
          <div className={styles.btnWrap}>
            <Button
              className={styles.uploadUserListBtn}
              startIcon={<Upload className={styles.uploadSvg} />}
              onClick={() =>
                _userListInputRef.current &&
                _userListInputRef.current.click()
              }
            >
              <span className={styles.uploadUsersLabel}>
                Upload a user list{' '}
              </span>
            </Button>
            <input
              id="file"
              type="file"
              accept=".csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ref={_userListInputRef}
              style={{ display: 'none' }}
              onChange={(e) => handleFileUpload(e)}
            />
            <div className={styles.btnDoneCancel}>
              <Button
                onClick={onAddUsersCancel}
                className={styles.btnCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={!selectedUsers.length || errorEmailMessage !== ''}
                className={classNames(
                  styles.btnDone,
                  selectedUsers.length === 0 && styles.disabledBtn
                )}
                onClick={onAddUsersDone}
              >
                Done
              </Button>
            </div>
          </div>
        </footer>
      </div>
      <Divider />
    </div>
  );

  const usersComp = isLoadingOnFetchUsers ? (
    <div className={styles.loader}>
      <Loader isLoading={true} />
    </div>
  ) : (
    <div style={{height: `${newUsers.length * 45}px`}}>
      <Scrollbars
        autoHide={true}
        autoHideTimeout={1000}
        autoHideDuration={200}
        className={styles.scrollbars}
      >
        {usersList}
      </Scrollbars>
    </div>
  );

  const addMoreUsersButton = !isAddUsersMode && (
    <div className={styles.buttonWithTitle}>
      <Button
        onClick={() => setIsAddUsersMode(true)}
      >
        <AddRoundedIcon fontSize="small" />
        Add more users
      </Button>
    </div>
  );

  const handlePaymentModal = (value: boolean) => {
    if (props.isCardCreatingFailed) {
      props.clearCreatingCardFailed();
    }
    if (value) {
      props.upgradePlanWithoutCard(false);
      if (isUpgradePlanOpen) {
        setIsUpgradePlanOpen(false);
      } else if (isAutomaticallyPlanOpen) {
        setIsAutomaticallyPlanOpen(false);
      }
      props.openModalWindow(
        PaymentDetailsModalWindows.editPaymentDetailsModal,
        'payment'
      );
    } else {
      props.closeModalWindow(
        PaymentDetailsModalWindows.editPaymentDetailsModal
      );
    }
  };

  const onUpdatePlan = (plan: IPlan) => {
    const { activePlan, card } = props;
    setNewActivePlan(activePlan);
    setSelectedPlan(plan);
    if (isAutomaticallyPlanOpen) {
      setIsAutomaticallyPlanOpen(false);
    }
    if (!card) {
      props.upgradePlanWithoutCard(true, plan);
      props.openModalWindow(
        PaymentDetailsModalWindows.editPaymentDetailsModal,
        'payment'
      );
    } else {
      props.openModalWindow(UpgradeModalWindows.confirmModal, 'upgrade');
    }
  };

  const closeUpgradeConfirmModal = (value: boolean) => {
    props.closeModalWindow(UpgradeModalWindows.confirmModal);
  };

  const confirmMessageUpgrade = {
    action: ', upgrade!',
    text: <p>You will be able to add more users!</p>,
    title: (
      <h1>
        Are you sure you want to upgrade your <br />
        <span>{props.activePlan} </span> plan to{' '}
        <span>{selectedPlan}</span> plan?
      </h1>
    )
  };

  const successMessageUpdatePlan = {
    action: 'Plan Update Successfully Requested!',
    text: (
      <p>
        Your account will change from <br />
        the <span>{newActivePlan}</span> plan to the
        <span> {selectedPlan}</span> plan
      </p>
    ),
    title: <h1>Plan Update Successfully Requested!</h1>
  };

  return (
    <>
      <Modal
        className={styles.modalConvertProject}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div
            className={classNames(styles.paperConvertProject, styles.fadeIn)}
          >
            {!props.isLoading ? (
              <>
                <div className={styles.convertContent}>
                  <h2 className={styles.convertTitle}>
                    Convert project to category
                  </h2>
                  {categoryName}
                </div>
                <Divider />
                <div className={styles.convertContent}>
                  <h3 className={styles.sectionTitle}>User access</h3>
                  {usersComp}
                  {errorSubmitMessage && (
                    <p className={styles.errorSubmitMessage}>
                      {errorSubmitMessage}
                    </p>
                  )}
                  {addMoreUsersButton}
                </div>
                {addNewUsers}
                <footer
                  className={classNames(
                    styles.convertContent,
                    styles.convertBtn
                  )}
                >
                  <Button
                    className={styles.btnConvertCancel}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={styles.btnConvertDone}
                    onClick={onSubmit}
                  >
                    Convert
                  </Button>
                </footer>
              </>
            ) : (
              <div className={styles.loader}>
                <Loader isLoading={true} />
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <ModalWindow
        type="confirm"
        message={confirmMessageUpgrade}
        open={isModalWindowOpen(
          props.modalWindows,
          UpgradeModalWindows.confirmModal,
          'upgrade'
        )}
        onClose={closeUpgradeConfirmModal}
      />
      <ModalWindow
        type="success"
        message={successMessageUpdatePlan}
        open={isModalWindowOpen(
          props.modalWindows,
          UpdatePlanModalWindow.successModal,
          'updatePlan'
        )}
        onClose={() =>
          props.closeModalWindow(UpdatePlanModalWindow.successModal)
        }
      />
    </>
  );
};

export default connect(mapStateToProps, actions)(ConvertProject);
