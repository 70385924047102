enum Types {
    FETCH_PROJECTS_REQUEST = '[Projects] Fetch request',
    FETCH_PROJECTS_SUCCESS = '[Projects] Fetch success',
    FETCH_PROJECTS_ERROR = '[Projects] Fetch error',

    CREATE_PROJECT_REQUEST = '[Project] Create request',
    CREATE_PROJECT_SUCCESS = '[Project] Create success',
    CREATE_PROJECT_ERROR = '[Project] Create error',

    UPDATE_PROJECT_REQUEST = '[Project] Update request',
    UPDATE_PROJECT_SUCCESS = '[Project] Update success',
    UPDATE_PROJECT_ERROR = '[Project] Update error',

    DELETE_PROJECT_REQUEST = '[Project] Delete request',
    DELETE_PROJECT_SUCCESS = '[Project] Delete success',
    DELETE_PROJECT_ERROR = '[Project] Delete error',

    NOTIFICATIONS_PROJECT_REQUEST = '[Project] Notifications​ toggle request',
    NOTIFICATIONS_PROJECT_SUCCESS = '[Project] Notifications​ toggle success',
    NOTIFICATIONS_PROJECT_ERROR = '[Project] Notifications​ toggle error',

    SET_ACTIVE_PROJECT = '[Project] Set active',

    SYNC_PROJECT_REQUEST = '[Project] Sync request',
    SYNC_PROJECT_SUCCESS = '[Project] Sync success',
    SYNC_PROJECT_ERROR = '[Project] Sync error',

    FETCH_PROJECT_USERS_REQUEST = '[Project] Fetch users request',
    FETCH_PROJECT_USERS_SUCCESS = '[Project] Fetch users success',
    FETCH_PROJECT_USERS_ERROR = '[Project] Fetch users error',

    CONVERT_PROJECT_REQUEST = '[Project] Convert request',
    CONVERT_PROJECT_SUCCESS = '[Project] Convert success',
    CONVERT_PROJECT_ERROR = '[Project] Convert error',
  }

export default Types;
