import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import styles from './styles.m.scss';
import { deleteFileTags, fetchTags} from '@redux/reducers/files/actions';
import {Button, Chip, CircularProgress, Input, Modal, styled, TextField} from '@material-ui/core';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import {FileTagModal} from '@components/modal-window/modalWindowsTypes';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { openModalWindow } from '@redux/reducers/modal-window/actions';
import ModalAddTag from '@components/modal-add-tag';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: undefined,
): (a: IFileTag, b: IFileTag) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a.name, b.name, orderBy)
        : (a, b) => -descendingComparator(a.name, b.name, orderBy);
}

function stableSort(array: IFileTag[], comparator: (a: IFileTag, b: IFileTag) => number) {
    const stabilizedThis = array?.map((el, index) => [el, index] as [IFileTag, number]);
    if (!stabilizedThis) { return []; }
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) { return order; }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface IHeadCell {
    id: string;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}

const headCells: IHeadCell[] = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Tag Name' },
    { id: 'description', numeric: true, disablePadding: false, label: 'Tag Description' },
];

interface IEnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: IEnhancedTableProps) {
    const {  onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface IEnhancedTableToolbarProps {
    numSelected: number;
    addNewFileTagOpenModal: any;
    deleteTags: any;
    totalNumOfTags: number;
}

const EnhancedTableToolbar = (props: IEnhancedTableToolbarProps) => {
    const { numSelected, addNewFileTagOpenModal, deleteTags, totalNumOfTags } = props;

    return (
        <Toolbar
            className={classNames(styles.root, {
                [styles.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={styles.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={styles.title} variant="h6" id="tableTitle" component="div">
                    Tags
                </Typography>
            )}
            <div className={styles.wrapper}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete" onClick={deleteTags}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={totalNumOfTags >= 20 ? 'You reached maximum amount of tags.' : 'Add new tag'}
                        placement="top"
                    >
                        <span>
                            <Button
                                className={styles.addNewTagBtn}
                                onClick={addNewFileTagOpenModal}
                                startIcon={<AddIcon/>}
                                disabled={totalNumOfTags >= 20}
                            >
                                Add New
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>
        </Toolbar>
    );
};

interface IProps {
    fileTags: IFileTag[];
    fetchTags: typeof fetchTags;
    fileTagsLoader: boolean;
    deleteFileTags: typeof deleteFileTags;
    openModalWindow: typeof openModalWindow;
}

interface IState {
    order: Order;
    orderBy: string;
    selected: string[];
    page: number;
    rowsPerPage: number;
}

class FileTagList extends React.Component<IProps, IState> {

    state: IState = {
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: 5,
        selected: [],
    };

    componentDidMount = () => {
        this.props.fetchTags();
    };

    handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    };

    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = this.props.fileTags.map((tag) => tag._id);
            this.setState({ selected: newSelected });
            return;
        }
        this.setState({ selected: [] });
    };

    handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state.selected.slice(0, selectedIndex),
                this.state.selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    };

    handleChangePage = (event: unknown, page: number) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    };

    isSelected = (name: string) => this.state.selected.indexOf(name) !== -1;

    deleteFileTags = (): void => {
        this.setState({selected: []});
        this.props.deleteFileTags(this.state.selected);
    };

    render() {
        const { order, orderBy, selected, page, rowsPerPage } = this.state;

        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage,
            this.props.fileTags?.length - this.state.page * this.state.rowsPerPage);

        return (
            <div className={styles.wrapper}>
                <Paper className={styles.paper}>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        addNewFileTagOpenModal={() => this.props.openModalWindow(FileTagModal.addNewModal, 'fileTag')}
                        deleteTags={this.deleteFileTags}
                        totalNumOfTags={this.props.fileTags?.length}
                    />
                    <TableContainer>
                        <Table
                            className={styles.table}
                            aria-labelledby="tableTitle"
                            size="small"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={this.props.fileTags?.length}
                            />
                            <TableBody
                                className={classNames(styles.tableBody, this.props.fileTagsLoader && styles.isLoading)}
                            >
                                {stableSort(this.props.fileTags, getComparator(order, undefined))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: IFileTag, index: number) => {
                                        const isItemSelected = this.isSelected(row._id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover={true}
                                                onClick={(event) => this.handleClick(event, row._id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row._id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                                    <Chip
                                                        label={row.name}
                                                        style={{ backgroundColor: row.color }}
                                                        size={'small'}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">{row.description}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                {this.props.fileTagsLoader && (
                                    <TableRow>
                                        <TableCell>
                                            <CircularProgress className={styles.loader}/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.fileTags?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>
                <ModalAddTag/>
            </div>
        );
    }
}

const mapStateToProps = (state: IStore) => {
    return {
        fileTags: state.filesState.tags,
        fileTagsLoader: state.filesState.fileTagsLoader,
        modalWindows: state.modalWindowState.modalWindows,
    };
};

const actions = {
    deleteFileTags,
    fetchTags,
    openModalWindow,
};

export default compose(
    withRouter,
    connect(mapStateToProps, actions)
)(FileTagList);
