import React, {ReactNode, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './styles.m.scss';
import WizardTooltip from '@components/new-user-wizard/tooltip';
import {Backdrop, Button, Card, CardActions, CardContent, Fade, Modal, Typography} from '@material-ui/core';
import {setActiveWizardStep, setNewWizardStep, setWizardIsActive} from '@redux/reducers/wizard/actions';
import {isAdminOrModerator} from '@services/user-role';

interface IOwnProps {
    children?: ReactNode;
}

interface IReduxProps {
    authState: IAuth;
    wizardState: IWizardState;
    setActiveWizardStep: typeof setActiveWizardStep;
    setWizardIsActive: typeof setWizardIsActive;
}

interface IProps extends IReduxProps, IOwnProps {}

const NewUserWizard = (props: IProps) => {
    const [isNewUserModalOpen, setIsNewUserModalOpen] = useState<boolean>(false);

    useEffect(() => {
        // Initialize wizard, re-render children to have updated positions
    }, [props.wizardState.activeStep, props.wizardState.isWizardActive]);

    useEffect(() => {
        setIsNewUserModalOpen(props.authState.isNewUser);
    }, []);

    const openWizard = () => {
        props.setActiveWizardStep(0);
        props.setWizardIsActive(true);
        setIsNewUserModalOpen(false);
    };

    const welcomeModal =
        props.authState.isNewUser &&
        isAdminOrModerator(props.authState.profile.role) &&
        isNewUserModalOpen &&
        (
            <Modal
                className={styles.newUserModal}
                open={isNewUserModalOpen}
                closeAfterTransition={true}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={true}>
                    <Card className={classNames(styles.cardModal)}>
                        <CardContent>
                            <Typography gutterBottom={true} variant="h5" component="h2">
                                Welcome to Neatly ✨
                            </Typography>
                            <Typography variant="body2" component="p" className={classNames(styles.secondaryText)}>
                                Let's get you started by organizing some of your files from Google Drive!
                            </Typography>
                            <CardActions>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classNames(styles.imReadyButton)}
                                    onClick={openWizard}
                                >
                                    I'm ready!
                                </Button>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Fade>
            </Modal>
    );

    return (
        <>
            <div>
                {props.children}
                {welcomeModal}
                <WizardTooltip/>
            </div>
        </>
    );
};

const mapStateToProps = (state: IStore) => {
    return {
        authState: state.auth,
        wizardState: state.wizardState
    };
};

const actions = {
    setActiveWizardStep,
    setWizardIsActive
};

export default connect(mapStateToProps, actions)(NewUserWizard);
