import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import Types from '../reducers/categories/types';
import { openModalWindow } from '@redux/reducers/modal-window/actions';
import { CategoriesModalWindows, SyncModalWindow } from '@components/modal-window/modalWindowsTypes';
import { fetchSections } from '@redux/reducers/sections/actions';
import { clearActiveGroup } from '@redux/reducers/files/actions';
import {getCategorySharableLink} from '@redux/reducers/categories/actions';

const middlware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);
  if (action.type === Types.FETCH_CATEGORIES_REQUEST) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.FETCH_CATEGORIES_ERROR,
        onSuccess: Types.FETCH_CATEGORIES_SUCCESS,
        url: `/categories?type=category`
      })
    );
  }

  if (action.type === Types.CREATE_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: { ...action.payload, isProject: false },
        method: 'post',
        onError: Types.CREATE_CATEGORY_ERROR,
        onSuccess: Types.CREATE_CATEGORY_SUCCESS,
        url: `/categories`
      })
    );
  }

  if (action.type === Types.CREATE_CATEGORY_SUCCESS) {
      store.dispatch(getCategorySharableLink(action.payload.id));
  }

  if (action.type === Types.GET_CATEGORY_SHARABLE_LINK) {
      store.dispatch(
          api({
              method: 'get',
              onError: Types.GET_CATEGORY_SHARABLE_LINK_ERROR,
              onSuccess: Types.GET_CATEGORY_SHARABLE_LINK_SUCCESS,
              url: `/categories/share-link/${action.payload.categoryId}`
          })
      );
  }

  if (action.type === Types.UPDATE_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'put',
        onError: Types.UPDATE_CATEGORY_ERROR,
        onSuccess: Types.UPDATE_CATEGORY_SUCCESS,
        url: `/categories`
      })
    );
  }

  if (action.type === Types.UPDATE_CATEGORY_SUCCESS) {
      store.dispatch(fetchSections(action.payload.id));
  }

  if (action.type === Types.DELETE_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        method: 'delete',
        onError: Types.DELETE_CATEGORY_ERROR,
        onSuccess: Types.DELETE_CATEGORY_SUCCESS,
        url: `/categories/${action.payload}`
      })
    );
  }

  if (action.type === Types.NOTIFICATIONS_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        method: 'put',
        onError: Types.NOTIFICATIONS_CATEGORY_ERROR,
        onSuccess: Types.NOTIFICATIONS_CATEGORY_SUCCESS,
        url: `/categories/notifications-toggle/${action.payload}`
      })
    );
  }

  if (action.type === Types.DELETE_CATEGORY_SUCCESS) {
    store.dispatch(
      openModalWindow(CategoriesModalWindows.successModal, 'category')
    );
  }

  if (action.type === Types.SET_ACTIVE_CATEGORY) {
    const category = action.payload as ICategory;
    if (category) {
      store.dispatch(fetchSections(category.id));
    }
  }

  if (action.type === Types.FETCH_CATEGORIES_SUCCESS) {
    store.dispatch(clearActiveGroup());
  }

  if (action.type === Types.SYNC_CATEGORY_REQUEST) {
    store.dispatch(
      api({
        body: { googleDriveDirectoryId: action.payload.directoryId, sectionId: action.payload.sectionId },
        method: 'put',
        onError: Types.SYNC_CATEGORY_ERROR,
        onSuccess: Types.SYNC_CATEGORY_SUCCESS,
        url: `/files/sync-drive?categoryId=${action.payload.categoryId}`
      })
    );
  }

  if (action.type === Types.SYNC_CATEGORY_SUCCESS) {
    if (action.payload.folderIsEmpty) {
      store.dispatch(openModalWindow(SyncModalWindow.successModal, 'sync'));
    }
    if (store.getState().categoriesState.activeCategory.id === action.payload.categoryId) {
      store.dispatch(fetchSections(action.payload.categoryId));
    }
  }
};

export default middlware as Middleware;
