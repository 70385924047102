import Types from './types';

const initialState: IDashboardState = {
  activeTab: 'categories'
};

export default (state: IDashboardState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.SET_ACTIVE_TAB: {
      const newState = {
        ...state
      };

      if (action.payload) {
        newState.activeTab = action.payload;
      } else {
        newState.activeTab = 'categories';
      }

      return newState;
    }

    default:
      return state;
  }
};
