import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import Types from '../reducers/sections/types';
import { openModalWindow } from '@redux/reducers/modal-window/actions';
import { SectionsModalWindows } from '@components/modal-window/modalWindowsTypes';
import {
  fetchFiles,
  deleteAllFilesInSection
} from '@redux/reducers/files/actions';

const middlware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);

  if (
    action.type === Types.FETCH_SECTIONS_REQUEST ||
    action.type === Types.FETCH_TEMP_SECTIONS_REQUEST
  ) {
    store.dispatch(
      api({
        method: 'get',
        onError: Types.FETCH_SECTIONS_ERROR,
        onSuccess:
          action.type === Types.FETCH_TEMP_SECTIONS_REQUEST
            ? Types.FETCH_TEMP_SECTIONS_SUCCESS
            : Types.FETCH_SECTIONS_SUCCESS,
        url: `/sections?categoryId=${action.payload}`
      })
    );
  }

  if (action.type === Types.CREATE_SECTION_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'post',
        onError: Types.SECTIONS_ERROR,
        onSuccess: Types.CREATE_SECTION_SUCCESS,
        url: `/sections`
      })
    );
  }

  if (action.type === Types.UPDATE_SECTION_REQUEST) {
    store.dispatch(
      api({
        body: action.payload,
        method: 'put',
        onError: Types.UPDATE_SECTION_ERROR,
        onSuccess: Types.UPDATE_SECTION_SUCCESS,
        url: `/sections`
      })
    );
  }

  if (action.type === Types.DELETE_SECTION_REQUEST) {
    store.dispatch(
      api({
        method: 'delete',
        onError: Types.DELETE_SECTION_ERROR,
        onSuccess: Types.DELETE_SECTION_SUCCESS,
        url: `/sections/${action.payload}`
      })
    );
    store.dispatch(deleteAllFilesInSection(action.payload));
  }

  if (action.type === Types.FETCH_SECTIONS_SUCCESS) {
    store
      .getState()
      .sectionsState.sections.map((section) =>
        store.dispatch(fetchFiles(section.id, '', 5))
      );
  }

  if (action.type === Types.DELETE_SECTION_SUCCESS) {
    store.dispatch(
      openModalWindow(SectionsModalWindows.successModal, 'section')
    );
  }
};

export default middlware as Middleware;
