import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loader from '@components/loader';
import { Scrollbars } from 'react-custom-scrollbars';
import {Input, IconButton, Button, TextField, Chip, Box, Tooltip} from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ModalWindow from '@components/modal-window';
import ModalConvertProject from '@components/modal-convert-project';
import {
  createSection,
  deleteSection,
  fetchSections, toggleSectionVisibility,
  updateSection,
} from '@redux/reducers/sections/actions';
import {
  openModalWindow,
  closeModalWindow
} from '@redux/reducers/modal-window/actions';
import {
  getCategorySharableLink,
  setActiveCategory,
  setActiveFileTagFilter,
  updateCategory,
  updateProject,
  updateSectionOrder
} from '@redux/reducers/categories/actions';
import { setActiveProject } from '@redux/reducers/projects/actions';
import { isAdmin, isAdminOrModerator } from '@services/user-role';
import {
  SectionsModalWindows,
  ConvertProjectModalWindow, FileTagModal, InviteUserModalWindow,
} from '@components/modal-window/modalWindowsTypes';
import Files from './components/file';
import TooltipSet from '@components/tooltips';

import TrashBin from '@assets/svg/trashBin.svg?tag';
import Pen from '@assets/svg/pen.svg?tag';
import styles from './styles.m.scss';
import { setActiveTab } from '@redux/reducers/dashboard/action';
import { CategoriesModalWindows } from '@components/modal-window/modalWindowsTypes';
import PersonAdd from '@assets/svg/personAdd.svg?tag';
import Bell from '@assets/svg/bell.svg?tag';
import Share from '@components/modal-share';
import NotifiedOfUpdates from '@components/notified-of-updates';
import toast from 'react-hot-toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {fetchFiles, fetchTags} from '@redux/reducers/files/actions';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ModalAddTag from '@components/modal-add-tag';
import {clearInviteError} from '@redux/reducers/auth/actions';
import NeedHelp from '@pages/category/components/need-help';
import {setActiveWizardStep, setNewWizardStep} from '@redux/reducers/wizard/actions';
import {ExpandLess, ExpandMore, DragIndicator} from '@material-ui/icons';

interface IState {
  activeId: string;
  deleteId: string;
  errorMessage: string;
  isNewSectionContent: boolean;
  isOpenConvertModal: boolean;
  newId: string;
  prevTitle: string;
  title: string;
  titleInvalid: boolean;
  isOpenShareModal: boolean;
  notificationEl: HTMLButtonElement | null;
  filterEl: boolean;
  draggedSectionIndex: number | null;
  dragPositionIndex: number | null;
}

interface IRouteParams {
  categoryId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
  activeCategory: ICategory;
  activeTab: 'categories' | 'projects';
  projects: ICategory[];
  categories: ICategory[];
  collapsedSections: any;
  deleteSection: typeof deleteSection;
  sections: ISection[];
  filesState: IFilesState;
  isWizardActive: boolean;
  createSection: typeof createSection;
  fetchSections: typeof fetchSections;
  fetchTags: typeof fetchTags;
  inviteError: string;
  fetchFiles: typeof fetchFiles;
  updateSection: typeof updateSection;
  updateSectionOrder: typeof updateSectionOrder;
  updateCategory: typeof updateCategory;
  updateProject: typeof updateProject;
  setActiveCategory: typeof setActiveCategory;
  setActiveFileTagFilter: typeof setActiveFileTagFilter;
  setActiveProject: typeof setActiveProject;
  setActiveTab: typeof setActiveTab;
  setActiveWizardStep: typeof setActiveWizardStep;
  setNewWizardStep: typeof setNewWizardStep;
  getCategorySharableLink: typeof getCategorySharableLink;
  toggleSectionVisibility: typeof toggleSectionVisibility;
  errors: string[];
  isLoadingOnFetch: boolean;
  isLoadingCreating: boolean;
  isAssetTableView: boolean;
  roleOfUser: UserRoleType;
  isOwner: boolean;

  modalWindows: any;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
  clearInviteError: typeof clearInviteError;
}

const defaultTitle = '1. Add Section (Ex: brand)';

const Errors = Object.freeze({
  nameIsNotUnique: 'Name of sections has to be unique',
  nameIsSmall: 'Name of the sections has to have at least 2 letters',
  sectionNotCreated: 'Please enter section name first'
});

const emptyState: IState = {
  activeId: '',
  deleteId: '',
  dragPositionIndex: null,
  draggedSectionIndex: null,
  errorMessage: '',
  filterEl: false,
  isNewSectionContent: false,
  isOpenConvertModal: false,
  isOpenShareModal: false,
  newId: new Date().getTime().toString(),
  notificationEl: null,
  prevTitle: '',
  title: defaultTitle,
  titleInvalid: false,
};

class Category extends Component<IProps, IState> {
  state = { ...emptyState };

  _titleInputRef = createRef<HTMLInputElement>();
  _newTitleInputRef = createRef<HTMLInputElement>();
  _newSectionRef = createRef<Scrollbars>();
  _wizardRef = createRef<HTMLDivElement>();

  fromPath = () => {
    const { activeCategory, categories, match, history, projects, activeTab } = this.props;
    const { categoryId } = match.params;

    if (categoryId && (!activeCategory || categoryId !== activeCategory.id)) {
      const aCategory = categoryId && categories.find((c: ICategory) => c.id === categoryId);
      const aProject = categoryId && projects.find((p: ICategory) => p.id === categoryId);

      if (aCategory) {
        this.props.setActiveCategory(aCategory);
        if (activeTab !== 'categories') {
          this.props.setActiveTab('categories');
        }
      } else if (aProject) {
        this.props.setActiveProject(aProject);
        if (activeTab !== 'projects') {
          this.props.setActiveTab('projects');
        }
      }
    } else if (!activeCategory && activeTab === 'categories' && categories.length) {
      history.push(`/dashboard/categories/${categories[0].id}`);
    } else if (!activeCategory && activeTab === 'projects' && projects.length) {
      history.push(`/dashboard/categories/${projects[0].id}`);
    }
  };

  componentDidMount = () => {
    this.fromPath();
  };

  componentDidUpdate(prevProps: IProps) {
    const { activeCategory } = this.props;
    if (prevProps.location !== this.props.location) {
      this.fromPath();
    }
    if (this.props.isWizardActive) {
      this.props.setNewWizardStep({
        content: {
          text: 'Sections are groups of similar files inside a category. Let\'s make it obvious for your colleagues with a name that describes the kind of assets that we\'re going to store here, like “Brand Assets.”',
          title: 'Naming a section',
        },
        position: this._wizardRef?.current?.getBoundingClientRect(),
        step: 1,
      });
    }

    if (
      activeCategory !== null &&
      prevProps.activeCategory !== null &&
      activeCategory.id !== '' &&
      prevProps.activeCategory.id !== activeCategory.id
    ) {
      this.setState({ ...emptyState });
      return;
    }
  }

  componentWillUnmount() {
    this.props.setActiveCategory(null);
    this.props.setActiveProject(null);
  }

  isLengthValid = (value: string) => {
    const check = value.trim();
    if (check.length < 2) {
      this.setState({ errorMessage: Errors.nameIsSmall });
      return false;
    } else {
      return true;
      }
  };

  isValueUnique = (value: string) => {
    const resultIndex = this.props.sections.map((e) => e.name).indexOf(value);
    if (resultIndex !== -1) {
      this.setState({ errorMessage: Errors.nameIsNotUnique });
      return false;
    } else {
      return true;
    }
  };

  isValueNotChanged = (value: string, isNew: boolean) => {
    const resultIndex = this.props.sections.map((e) => e.name).indexOf(value);
    if (resultIndex === 0 && !isNew) {
      return true;
    } else {
      return false;
    }
  };

  isFieldValid = (value: string, isNew: boolean) => {
    if (
      this.isLengthValid(value) &&
      (this.isValueUnique(value) || this.isValueNotChanged(value, isNew))
    ) {
      return true;
    } else {
      return false;
    }
  };

  toggleTitleEdit = (section: ISection) => {
    const { newId } = this.state;
    const { activeCategory } = this.props;
    if (activeCategory && isAdminOrModerator(activeCategory.userRole)) {
      this.setState({
        activeId: newId === section.id ? newId : section.id,
        prevTitle: '',
        title: section.name === defaultTitle ? '' : section.name
      });
    }
  };

  handleTitleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.currentTarget.value.replace(/\s+/g, '').length < 31) {
      this.setState({
        title: e.currentTarget.value,
        titleInvalid: false
      });
    }
  };

  handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, onClick: any) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  handleTitleCancel = () => {
    this.setState((prevState) => {
      return {
        activeId: '',
        title: prevState.prevTitle === '' ? defaultTitle : prevState.prevTitle,
        titleInvalid: false
      };
    });
  };

  handleTitleCreate = () => {
    const value: string = this._newTitleInputRef.current.value;
    if (this.isFieldValid(value, true)) {
      this.props.createSection(this.props.activeCategory.id, value);
      if (this.props.isWizardActive) {
        this.props.setActiveWizardStep(2);
      }
      this.setState({
        activeId: '',
        isNewSectionContent: false,
        newId: new Date().getTime().toString(),
        title: value
      });
    } else {
      this.setState({
        titleInvalid: true
      });
    }
  };

  handleTitleDelete = (item: ISection) => {
    this.setState({ deleteId: item.id, title: item.name });
    this.props.openModalWindow(SectionsModalWindows.confirmModal, 'section');
  };

  handleTitleSave = () => {
    const { activeId } = this.state;
    const value: string = this._titleInputRef.current.value;
    if (this.isFieldValid(value, false)) {
      if (this.props.activeCategory.id === activeId) {
        if (this.props.projects.map((project) => project.id).includes(activeId)) {
          // for some reason project names are also updated here, and that is not fixable easily any other way
          this.props.updateProject(activeId, value);
        } else {
          this.props.updateCategory(activeId, value);
        }
      } else if (this.props.sections.map((e) => e.name).indexOf(value) !== 0) {
        this.props.updateSection(activeId, value);
      }
      this.setState({
        activeId: '',
        title: value
      });
    } else {
      this.setState({ titleInvalid: true });
    }
  };

  handleNewSection = () => {
    const { isNewSectionContent } = this.state;
    if (isNewSectionContent === false) {
      this.setState({
        activeId: '',
        isNewSectionContent: true,
        prevTitle: '',
        title: defaultTitle
      });
    } else {
      toast.error('Add name to an already created section!');
    }
    setTimeout(() => this._newSectionRef.current.scrollToTop(), 0);
  };

  handleEmptySection = () => {
    const { title } = this.state;
    this.setState({
      errorMessage: Errors.sectionNotCreated,
      titleInvalid: true
    });
    if (title !== '' && title !== defaultTitle) {
      this.toggleTitleEdit({ id: this.state.newId, name: title });
    } else {
      this.toggleTitleEdit({ id: this.state.newId, name: '' });
    }
  };

  handleConvertProject = () => {
    this.props.openModalWindow(
      ConvertProjectModalWindow.confirmModal,
      'convert'
    );
  };

  filterByTags = (selectedTags: IFileTag[]) => {
    this.props.sections.forEach((section) => {
      this.props.setActiveFileTagFilter(selectedTags);
      this.props.fetchFiles(section.id, '', 5, 0, selectedTags.map((tag) => tag._id));
    });
  };

  handleConvertModal = (value: boolean) => {
    this.setState({
      isOpenConvertModal: value
    });
  };

  handleDeleteCategory = () => {
    this.props.openModalWindow(CategoriesModalWindows.confirmModal, 'category');
  };

  deleteSection = (value: boolean) => {
    this.props.closeModalWindow(SectionsModalWindows.confirmModal);
    if (value) {
      this.props.deleteSection(this.state.deleteId);
      this.setState({ deleteId: '' });
    }
  };

  setNotificationEl = (value: HTMLButtonElement) => {
    this.setState({ notificationEl: value });
  };

  setFilterEl = () => {
    this.setState((prevState) => ({
      filterEl: !prevState.filterEl
    }), () => {
      if (this.state.filterEl) {
        this.props.fetchTags();
      }
    });
  };

  closeModal = () => {
    this.props.closeModalWindow(SectionsModalWindows.successModal);
  };

  closeConvertModal = () => {
    this.props.closeModalWindow(ConvertProjectModalWindow.successModal);
  };

  openConvertModal = (value: boolean) => {
    this.props.closeModalWindow(ConvertProjectModalWindow.confirmModal);
    if (value) {
      this.setState({ isOpenConvertModal: true });
    }
  };

  isModalWindowOpen = (type: string): boolean => {
    return !!(
      this.props.modalWindows &&
      this.props.modalWindows[type] &&
      (
          this.props.modalWindows[type] === 'section' ||
          this.props.modalWindows[type] === 'convert'
      )
    );
  };

  handleShareModal = (value: boolean) => {
    if (value) {
      this.props.getCategorySharableLink(this.props.activeCategory.id);
    }
    this.setState({ isOpenShareModal: value });
  };

  getAutocompleteOptions = () => {
    const defaultTag = {
      default: true,
      info: 'Define new tag...'
    };
    if (this.props.isOwner) {
      return [...this.props.filesState?.tags, defaultTag];
    }

    return this.props.filesState.tags;
  };

  handleSectionExpansion = (sectionId: string, isCollapsed: boolean) => {
    this.props.toggleSectionVisibility(sectionId, isCollapsed);
  };

  handleDragSectionStart = (sectionIndex: number) => {
    this.setState({
      draggedSectionIndex: sectionIndex
    });
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>, draggedOverIndex: number) => {
    e.stopPropagation();
    e.preventDefault();
    const targetRect = e.currentTarget.getBoundingClientRect();
    const targetMidY = targetRect.top + (targetRect.height / 2);

    if (e.clientY > targetMidY) {
      this.setState({ dragPositionIndex: draggedOverIndex});
    } else if (draggedOverIndex === 0) {
      this.setState({ dragPositionIndex: -1});
    }
  };

  handleDrop = () => {
    let dragPositionIndex = this.state.dragPositionIndex;
    const draggedSectionIndex = this.state.draggedSectionIndex;

    if (draggedSectionIndex > dragPositionIndex) {
      dragPositionIndex += 1;
    }

    if (dragPositionIndex === null || draggedSectionIndex === null || dragPositionIndex === draggedSectionIndex) {
      this.setState({ draggedSectionIndex: null, dragPositionIndex: null });
      return;
    }

    const updatedSections = [...this.props.sections.map((section) => section.id)];

    const draggedSection = updatedSections.splice(draggedSectionIndex, 1)[0];
    updatedSections.splice(dragPositionIndex, 0, draggedSection);

    this.setState({ draggedSectionIndex: null, dragPositionIndex: null });
    this.props.updateSectionOrder(updatedSections, this.props.activeCategory);
  };

  handleContainerDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const sectionsCount = this.props.sections.length;

    const containerRect = e.currentTarget.getBoundingClientRect();
    const containerMidY = containerRect.top + containerRect.height / 2;

    if (e.clientY < containerMidY) {
      this.setState({ dragPositionIndex: -1 });
    } else {
      this.setState({ dragPositionIndex: sectionsCount });
    }
  };
  render() {
    const {
      activeId,
      errorMessage,
      isNewSectionContent,
      title,
      titleInvalid,
      newId
    } = this.state;

    const {
      sections,
      isLoadingOnFetch,
      categories,
      projects,
      isLoadingCreating,
    } = this.props;

    let { activeCategory } = this.props;

    if (activeCategory) {
      activeCategory = [...categories, ...projects].find((item: ICategory) => item.id === activeCategory.id);
    }

    const confirmMessage = {
      action: ', remove it!',
      text: (
        <p>
          This will also remove all files in <span>Sales Materials</span>{' '}
          section from Neatly but will not delete the files from{' '}
          <span>Google Drive</span>
        </p>
      ),
      title: (
        <h1>
          Remove <span>{title}</span> Section?
        </h1>
      )
    };

    const successMessage = {
      action: 'Successfully Removed!',
      text: (
        <p>
          Your <span>{title}</span> section has been removed
        </p>
      ),
      title: <h1>Successfully Removed!</h1>
    };

    const confirmConvertMessage = {
      action: ', convert it!',
      text: (
        <p>
          The project and its files will be moved to the category tab This
          cannot be reversed
        </p>
      ),
      title: (
        <h1>
          Convert <span>{activeCategory?.name}</span> to category
        </h1>
      )
    };

    const successConvertMessage = {
      action: 'Successfully Converted',
      text: <p>Your project has been converted</p>,
      title: <h1>Successfully Converted!</h1>
    };

    const shareModal = this.state.isOpenShareModal && (
      <Share
        categoryId={activeCategory && activeCategory.id}
        userShareRole={activeCategory && activeCategory.userRole}
        open={this.state.isOpenShareModal}
        setOpen={this.handleShareModal}
        link={activeCategory.id}
      />
    );

    const editIconBtnByRole = (item: ISection) =>
      activeCategory &&
      isAdminOrModerator(activeCategory.userRole) && (
        <IconButton
          onClick={() => this.toggleTitleEdit(item)}
          className={classNames(styles.editBtn, styles.onHover)}
        >
          <Pen className={styles.pen} />
        </IconButton>
      );

    const titleSection = (item: ISection) => (
      <>
        <div
          onClick={() => this.toggleTitleEdit(item)}
          className={styles.titleBox}
        >
          <h1>{item.name}</h1>
        </div>
        {editIconBtnByRole(item)}
      </>
    );

    const inputSection = (onClick: any, inputRef: any) => (
      <>
        <TooltipSet message={errorMessage} isOpen={titleInvalid} type="error">
          <Input
            error={titleInvalid}
            className={styles.sectionTitle}
            value={title}
            autoFocus={true}
            onChange={this.handleTitleChange}
            inputRef={inputRef}
            placeholder={defaultTitle}
            onKeyPress={(event) => this.handleOnKeyPress(event, onClick)}
          />
        </TooltipSet>
        <IconButton onClick={onClick} className={classNames(styles.done)}>
          <DoneRoundedIcon />
        </IconButton>
        <IconButton
          onClick={this.handleTitleCancel}
          className={classNames(styles.clear)}
        >
          <ClearRoundedIcon />
        </IconButton>
      </>
    );

    const newSectionBtn = isAdminOrModerator(activeCategory?.userRole) && (
      <Button
        variant="outlined"
        color="primary"
        className={styles.newSection}
        onClick={this.handleNewSection}
      >
        New Section
      </Button>
    );

    const inviteToCategoryBtn = isAdminOrModerator(activeCategory?.userRole) && (
        <Button
            variant="outlined"
            color="primary"
            className={styles.newSection}
            onClick={() => this.handleShareModal(true)}
        >
          Invite Others
        </Button>
    );

    const successInviteUser = {
      action: 'Users Invited!',
      text: <p>Users have been successfully invited</p>,
      title: <h1>Users Invited!</h1>
    };

    const convertProjectBtn = this.props.activeTab === 'projects' &&
      activeCategory &&
      this.props.isOwner && (
        <Button
          variant="outlined"
          color="primary"
          className={styles.convertBtn}
          onClick={this.handleConvertProject}
        >
          Convert project to category
        </Button>
      );

    const filterAutocomplete = activeCategory && this.state.filterEl &&
        (
            <Autocomplete
                className={styles.filterAutocomplete}
                onChange={(event, value) => this.filterByTags(value)}
                multiple={true}
                id="tags-small"
                options={this.getAutocompleteOptions()}
                loading={this.props.filesState.fileTagsLoader}
                renderOption={(option) => (
                    option.default ?
                        (
                            <React.Fragment>
                              <Tooltip title={this.props.filesState.tags?.length >= 20 ? 'You have a maximum of 20 tags defined already.' : ''}>
                                <button
                                    style={{width: '100%'}}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.props.openModalWindow(FileTagModal.addNewModal, 'fileTag');
                                    }}
                                    disabled={this.props.filesState.tags?.length >= 20}
                                >
                                  <div style={{ color: 'silver' }}>
                                    <AddIcon />
                                  </div>
                                  <div style={{ color: 'silver' }}>
                                    {option.info}
                                  </div>
                                </button>
                              </Tooltip>
                            </React.Fragment>
                        ) :
                        (
                            <React.Fragment>
                              {option.name}
                            </React.Fragment>
                        )
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option: IFileTag, index: number) => (
                        <Chip
                            key={index}
                            size="small"
                            label={option.name}
                            {...getTagProps({ index })}
                            style={{ backgroundColor: option.color }} // Set color here
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={styles.textField}
                        label="Find by tags"
                        autoFocus={true}
                    />
                )}
            />
        );

    const collapseSectionOption = (section: ISection) => (
        <div className={styles.collapseSection}>
          { this.props.collapsedSections?.[section.id] ?
              (
                  <IconButton
                      onClick={() => this.handleSectionExpansion(section.id, false)}
                  >
                    <ExpandMore/>
                  </IconButton>
              )
              :
              (
                  <IconButton
                      onClick={() => this.handleSectionExpansion(section.id, true)}
                  >
                    <ExpandLess/>
                  </IconButton>
              )
          }
        </div>
    );

    const sectionButton = (item: ISection) => (
      <div className={styles.groupButtons}>
        <div
          className={styles.deleteSection}
          onClick={() => this.handleTitleDelete(item)}
        >
          <TrashBin />
          <p>Delete Section</p>
        </div>
      </div>
    );

    const sectionButtonByRole = (item: ISection) =>
      activeCategory &&
      isAdminOrModerator(activeCategory.userRole) &&
      sectionButton(item);

    const sectionItem = (item: ISection) =>
      activeId !== item.id
        ? titleSection(item)
        : inputSection(this.handleTitleSave, this._titleInputRef);

    const newItem =
      activeId !== newId
        ? titleSection({id: newId, name: defaultTitle})
        : inputSection(this.handleTitleCreate, this._newTitleInputRef);

    const sectionItemContent = (item: ISection) => (
      <>
        {collapseSectionOption(item)}
        {sectionItem(item)}
        {sectionButtonByRole(item)}
      </>
    );

    const newItemContent = (node: JSX.Element) => (
      <div className={styles.sectionWrapper}>
        <div className={styles.titleWrap} ref={this._wizardRef}>{node}</div>
        <Files sectionId={''} handleEmptySection={this.handleEmptySection} />
      </div>
    );

    const sectionsList =
      sections &&
      activeCategory &&
        sections.map((item, index) => (
            <div
                key={index}
            >
              {this.state.dragPositionIndex === -1 &&
                  index === 0 && (
                      <div className={styles.separator} />
                  )
              }
              <div
                  className={`${styles.sectionArea} ${this.state.draggedSectionIndex === index ? styles.dragging : ''}`}
                  draggable={isAdminOrModerator(this.props.activeCategory.userRole)}
                  onDragStart={() => this.handleDragSectionStart(index)}
                  onDragOver={(e) => this.handleDragOver(e, index)}
                  onDrop={() => this.handleDrop()}
              >
                {isAdminOrModerator(this.props.activeCategory.userRole) &&
                    (
                        <div className={styles.changeSectionSize}>
                          <DragIndicator style={{ transform: 'rotate(90deg)' }} />
                        </div>
                    )
                }
                <div className={styles.sectionWrapper}>
                  <div className={styles.titleWrap}>{sectionItemContent(item)}</div>
                  {!this.props.collapsedSections?.[item.id] && (
                      <div className={this.props.isAssetTableView ? styles.tableContent : styles.imageContent}>
                        <Files sectionId={item.id} />
                      </div>
                  )}
                </div>
              </div>
              {this.state.dragPositionIndex !== null &&
                  this.state.dragPositionIndex === index &&
                  this.state.dragPositionIndex !== this.state.draggedSectionIndex && (
                      <div className={styles.separator} />
                  )
              }
            </div>
        ));

    const newSection =
      activeCategory &&
      (isNewSectionContent || !sections.length) &&
      isAdminOrModerator(activeCategory.userRole) &&
      newItemContent(newItem);

    const noSectionLabel = sectionsList &&
      !sectionsList.length &&
      !newSection && <p className={styles.noSectionsLabel}>No sections yet.</p>;

    const content = isLoadingOnFetch ? (
      <Loader isLoading={true} isNotAbsoloute={true} />
    ) : (
      activeCategory && (
        <Scrollbars ref={this._newSectionRef}>
          {noSectionLabel}
          {newSection}
          {sectionsList}
        </Scrollbars>
      )
    );

    const convertModal = this.state.isOpenConvertModal && (
      <ModalConvertProject
        open={this.state.isOpenConvertModal}
        setOpen={this.handleConvertModal}
      />
    );

    const deleteCategoryByRole = isAdmin(activeCategory?.userRole) && (
      <IconButton
        className={styles.icon}
        onClick={this.handleDeleteCategory}
      >
        <TrashBin />
      </IconButton>
    );

    const personAddByRole = isAdminOrModerator(activeCategory?.userRole) && (
      <IconButton
        className={styles.icon}
        onClick={() => this.handleShareModal(true)}
      >
        <PersonAdd />
      </IconButton>
    );

    return (
      <>
        <Loader isLoading={isLoadingCreating} isBackground={true} />
        <div
            className={styles.wrapper}
            onDragOver={(e) => this.handleContainerDragOver(e)}
            onDrop={this.handleDrop}
        >
          <div className={styles.categoryTitle}>
            {activeCategory && <div className={styles.titleWrap}>{sectionItem(activeCategory)}</div>}
            <div className={styles.categoryActions}>
              {convertProjectBtn}
              {filterAutocomplete}
              <div className={styles.categoryCommonActions}>
                <IconButton
                    onClick={() => this.setFilterEl()}
                    style={{ fontSize: '10rem' }} // You can adjust the size as per your requirement
                >
                  <SearchIcon/>
                </IconButton>
                <IconButton
                  className={styles.icon}
                  onClick={(event) => this.setNotificationEl(event.currentTarget)}
                >
                  <Bell />
                </IconButton>
                {personAddByRole}
                {deleteCategoryByRole}
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            {newSectionBtn}
            {inviteToCategoryBtn}
          </div>
          {content}
          {convertModal}
          <NotifiedOfUpdates
            popperEl={this.state.notificationEl}
            setPopperEl={this.setNotificationEl}
          />
          <ModalWindow
              type="success"
              message={successInviteUser}
              open={this.isModalWindowOpen(InviteUserModalWindow.successModal)}
              onClose={() =>
                  this.props.closeModalWindow(InviteUserModalWindow.successModal)
              }
          />
          <ModalWindow
            type="confirm"
            message={confirmMessage}
            open={this.isModalWindowOpen(SectionsModalWindows.confirmModal)}
            onClose={this.deleteSection}
          />
          <ModalWindow
            type="success"
            message={successMessage}
            open={this.isModalWindowOpen(SectionsModalWindows.successModal)}
            onClose={this.closeModal}
          />
          <ModalWindow
            type="confirm"
            message={confirmConvertMessage}
            open={this.isModalWindowOpen(
              ConvertProjectModalWindow.confirmModal
            )}
            onClose={this.openConvertModal}
          />
          <ModalWindow
            type="success"
            message={successConvertMessage}
            open={this.isModalWindowOpen(
              ConvertProjectModalWindow.successModal
            )}
            onClose={this.closeConvertModal}
          />
          <ModalAddTag/>
          <NeedHelp/>
        </div>
        {shareModal}
      </>
    );
  }
}

const selectActiveCategory = ({
                                dashboardState,
                                categoriesState,
                                projectState
                              }: IStore) => {
  return dashboardState.activeTab === 'projects'
    ? projectState.activeProject
    : categoriesState.activeCategory;
};

const mapStateToProps = (state: IStore) => ({
  activeCategory: selectActiveCategory(state),
  activeTab: state.dashboardState.activeTab,
  categories: state.categoriesState.categories,
  collapsedSections: state.sectionsState.collapsedSections,
  errorsSection: state.sectionsState.errors,
  filesState: state.filesState,
  inviteError: state.auth.inviteError,
  isAssetTableView: state.auth.profile.preferences?.assetTableView,
  isLoadingCreating: state.filesState.isLoadingCreating,
  isLoadingOnFetch: state.sectionsState.isLoadingOnFetch,
  isOwner: state.auth.profile.company.owner,
  isWizardActive: state.wizardState.isWizardActive,
  modalWindows: state.modalWindowState.modalWindows,
  projects: state.projectState.projects,
  roleOfUser: state.auth.profile.role,
  sections: state.sectionsState.sections
});

const actions = {
  clearInviteError,
  closeModalWindow,
  createSection,
  deleteSection,
  fetchFiles,
  fetchSections,
  fetchTags,
  getCategorySharableLink,
  openModalWindow,
  setActiveCategory,
  setActiveFileTagFilter,
  setActiveProject,
  setActiveTab,
  setActiveWizardStep,
  setNewWizardStep,
  toggleSectionVisibility,
  updateCategory,
  updateProject,
  updateSection,
  updateSectionOrder,
};

export default compose(withRouter, connect(mapStateToProps, actions))(Category);
