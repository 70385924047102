import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware, compose, createStore } from 'redux';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';

import reducer from '@redux/reducers';
import api from '@redux/middlewares/api';
import categories from '@redux/middlewares/categories';
import sections from '@redux/middlewares/sections';
import auth from '@redux/middlewares/auth';
import files from '@redux/middlewares/files';
import notifications from '@redux/middlewares/notifications';
import payments from '@redux/middlewares/payments';
import projects from '@redux/middlewares/projects';

const persistConfig: PersistConfig = {
  blacklist: ['modalWindowState'],
  key: 'root',
  storage
};

export default function configureStore(onCompletion?: () => void) {
  const middlewares = [thunk, api, auth, categories, sections, files, notifications, payments, projects];

  if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
  }

  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const pReducer = persistReducer(persistConfig, reducer);
  const store = createStore(pReducer, enhancer);
  const persistor = persistStore(store, undefined, onCompletion);

  return { store, persistor };
}
