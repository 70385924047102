type EnumLiteralsOf<T extends object> = T[keyof T];

export type CategoriesModalWindows = EnumLiteralsOf<typeof CategoriesModalWindows>;

export type SectionsModalWindows = EnumLiteralsOf<typeof SectionsModalWindows>;

export type FilesModalWindows = EnumLiteralsOf<typeof FilesModalWindows>;

export type AccountModalWindows = EnumLiteralsOf<typeof AccountModalWindows>;

export type PaymentDetailsModalWindows = EnumLiteralsOf<typeof PaymentDetailsModalWindows>;

export type UpgradeModalWindows = EnumLiteralsOf<typeof UpgradeModalWindows>;

export type UpdatePlanModalWindow = EnumLiteralsOf<typeof UpdatePlanModalWindow>;

export type InviteUserModalWindow = EnumLiteralsOf<typeof InviteUserModalWindow>;

export type ShareModalWindow = EnumLiteralsOf<typeof ShareModalWindow>;

export type ConvertProjectModalWindow = EnumLiteralsOf<typeof ConvertProjectModalWindow>;

export type SyncModalWindow = EnumLiteralsOf<typeof  SyncModalWindow>;

export type InviteCoWorkerModalWindow = EnumLiteralsOf<typeof InviteCoWorkerModalWindow>;

export type StripeCheckoutRedirectionModalWindow = EnumLiteralsOf<typeof StripeCheckoutRedirectionModalWindow>;

export const SyncModalWindow = Object.freeze({
  successModal: 'syncSuccessModal' as 'syncSuccessModal'
});

export const CategoriesModalWindows = Object.freeze({
  confirmModal: 'categoriesConfirmModal' as 'categoriesConfirmModal',
  errorModal: 'categoriesErrorModal' as 'categoriesErrorModal',
  successModal: 'categoriesSuccessModal' as 'categoriesSuccessModal'
});

export const SectionsModalWindows = Object.freeze({
  confirmModal: 'sectionsConfirmModal' as 'sectionsConfirmModal',
  successModal: 'sectionsSuccessModal' as 'sectionsSuccessModal'
});

export const FilesModalWindows = Object.freeze({
  confirmModal: 'filesConfirmModal' as 'filesConfirmModal',
  successModal: 'filesSuccessModal' as 'filesSuccessModal',
  successSelectedModal: 'successSelectedModal' as 'successSelectedModal',
});

export const AccountModalWindows = Object.freeze({
  confirmModal: 'accountConfirmModal' as 'accountConfirmModal',
  successModal: 'accountSuccessModal' as 'accountSuccessModal'
});

export const PaymentDetailsModalWindows = Object.freeze({
  editPaymentDetailsModal: 'paymentEditPaymentDetailsModal' as 'paymentEditPaymentDetailsModal',
  successModal: 'paymentDetailsSuccessModal' as 'paymentDetailsSuccessModal'
});

export const UpgradeModalWindows = Object.freeze({
  confirmModal: 'upgradeConfirmModal' as 'upgradeConfirmModal'
});

export const DowngradeModalWindows = Object.freeze({
  confirmModal: 'downgradeConfirmModal' as 'downgradeConfirmModal'
});

export const UpdatePlanModalWindow = Object.freeze({
  errorModal: 'updatePlanErrorModal' as 'updatePlanErrorModal',
  successModal: 'updatePlanSuccessModal' as 'updatePlanSuccessModal',
});

export const InviteUserModalWindow = Object.freeze({
  inviteModal: 'InviteModal' as 'InviteModal',
  successModal: 'InviteUserSuccessModal' as 'InviteUserSuccessModal',
});

export const ShareModalWindow = Object.freeze({
  errorModal: 'shareErrorModal' as 'updatePlanErrorModal',
  successModal: 'shareSuccessModal' as 'shareSuccessModal',
});

export const ConvertProjectModalWindow = Object.freeze({
  confirmModal: 'convertProjectConfirmModal' as 'convertProjectConfirmModal',
  successModal: 'convertProjectSuccessModal' as 'convertProjectSuccessModal'
});

export const InviteCoWorkerModalWindow = Object.freeze({
  confirmModal: 'inviteCoWorkerConfirmModal' as 'inviteCoWorkerConfirmModal',
});

export const StripeCheckoutRedirectionModalWindow = Object.freeze({
  actionNeededModal: 'fillInPaymentDetailsModal' as 'fillInPaymentDetailsModal',
});

export const FileTagModal = Object.freeze({
  addNewModal: 'addNewFileTagModal' as 'addNewFileTagModal',
});
