import React, { createRef, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Loader from '@components/loader';
import { useParams, Link } from 'react-router-dom';
import { getFileById } from '@redux/reducers/files/actions';
import { AppBar, makeStyles, Button } from '@material-ui/core';

import Logo from '@assets/logo.svg?tag';
import styles from './styles.m.scss';

interface IFileData {
  fileId: string;
  fileType: string;
}

interface IProps {
  file: IFileData;
  isLoading: boolean;
  getFileErrors: string[];
  getFileById: typeof getFileById;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  }
}));

function GooglePage(props: IProps) {
  const { fileId } = useParams();
  const classes = useStyles({});
  const closeRef = createRef<HTMLAnchorElement>();

  useEffect(() => {
    props.getFileById(fileId);
  }, []);

  const getIframe = () => {
    if (props.isLoading) {
      return <Loader isLoading={true} />;
    } else if (props.file) {
      return (
        <iframe
          src={`https://docs.google.com/${props.file.fileType}/d/${props.file.fileId}`}
          className={styles.googlePage}
          frameBorder="0"
        />
      );
    } else if (
      !props.file &&
      props.getFileErrors &&
      props.getFileErrors.length
    ) {
      return (
        <>
          <h1>Cannot reach the file</h1>
          <p>
            Seems like the file you are looking for is unavailable at this
            moment
          </p>
        </>
      );
    } else {
      return <></>;
    }
  };

  const closeWindow = () => {
    closeRef.current.href = window.location.href;
    closeRef.current.click();
    window.close();
  };

  return (
    <div className={styles.wrapper}>
      <AppBar
        position="fixed"
        className={classNames(styles.header, classes.appBar)}
      >
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.confirmClose}>
          <p className={styles.decsConfirm}>
            Your changes will saved automatically. Close the tab or click the button to exit
          </p>
          <Button
            variant="contained"
            className={styles.confirmBtn}
            onClick={closeWindow}
          >
            Confirm
          </Button>
        </div>
      </AppBar>
      <div className={styles.main}>{getIframe()}</div>
      <Link style={{ display: 'none' }} innerRef={closeRef} to="" />
    </div>
  );
}
const mapStateToProps = (state: IStore) => {
  let file: IFileData;
  if (state.filesState.file) {
    file = {
      fileId: state.filesState.file.data.fileId,
      fileType: state.filesState.file.data.fileType
    };
    file.fileType =
      file.fileType === 'spreadsheet' ? 'spreadsheets' : file.fileType;
  }
  return {
    file,
    getFileErrors: state.filesState.errors,
    isLoading: state.filesState.isLoadingCreating,
  };
};

const actions = {
  getFileById
};

export default connect(mapStateToProps, actions)(GooglePage);
