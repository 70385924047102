import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { getNotificationsArray } from '@redux/reducers/notifications';
import { readNotification, readAllNotifications } from '@redux/reducers/notifications/actions';
import {Popover, IconButton, Badge} from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import classNames from 'classnames';

import CloseNotification from '@assets/svg/closeNotification.svg?tag';
import Settings from '@assets/svg/settings.svg?tag';
import BlueCircle from '@assets/svg/blueСircle.svg?tag';
import styles from './styles.m.scss';

interface IReduxProps {
  notifications: INotification[];
  readAllNotifications: typeof readAllNotifications;
  readNotification: typeof readNotification;
}

interface IOwnProps {
  popperEl: HTMLElement | null;
  setPopperEl: (value: HTMLElement | null) => void;
}

interface IProps extends IReduxProps, IOwnProps { }

const Notifications = (props: IProps) => {
  const { popperEl, setPopperEl, notifications } = props;

  const clearNotification = (id: string) => {
    props.readNotification(id);
    if (notifications.length < 2) {
      setPopperEl(null);
    }
  };

  const clearAllNotifications = () => {
    props.readAllNotifications();
    setPopperEl(null);
  };

  const notificatoinsRows = notifications && notifications.map((notification, index) => {
    if (notification) {
      const noPhoto = (
        <div className={classNames(styles.notificationPhoto, styles.noPhoto)}>
          <BlueCircle className={styles.blueСircle} />
          <Settings className={styles.setting} />
        </div>
      );

      const notificationPhoto = notification.photoUrl
        ? <img src={notification.photoUrl} alt="User photo" className={styles.notificationPhoto} />
        : noPhoto;

      const options = {
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
      };

      const notificationDate = new Date(notification.date).toLocaleDateString('en-US', options);

      return (
        <div className={styles.notificationRow} key={index} >
          {notificationPhoto}
          < div className={styles.notificationMessageBox} >
            <p className={styles.notificationMessage}>{notification.notificationMessage}</p>
            <p className={styles.notificationTime}>{notificationDate}</p>
          </div >
          <IconButton
            className={styles.closeNotification}
            onClick={() => clearNotification(notification.id)}
          >
            <CloseNotification />
          </IconButton>
        </div >
      );
    }
  });

  const getNotificationsBoxHeight = () => {
    if (notifications && notifications.length) {
      const rowHeight = 92;
      const anotherSpace = 15;
      if (notifications.length > 1) {
        return rowHeight * 2 + anotherSpace;
      } else {
        return rowHeight * notifications.length + anotherSpace;
      }
    }
  };

  const notificatoinsBox = (
    <div style={{ height: `${getNotificationsBoxHeight()}px` }}>
      <Scrollbars autoHide={true} autoHideTimeout={1000} autoHideDuration={200}>
        {notificatoinsRows}
      </Scrollbars>
    </div>
  );

  const notificationLabel = notifications && notifications.length ?
    (
      <div className={styles.newNotificationBox} onClick={clearAllNotifications}>
        <p className={styles.newNotificationCountLabel}>New {notifications.length}</p>
      </div>
    ) : null;

  const noNotifications = <p className={styles.noNotificationsLabel}>No notifications.</p>;

  const notificationsContent = notifications && notifications.length
    ? notificatoinsBox
    : noNotifications;

  const popperAnchorOrigin: PopoverOrigin = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  const popperTransformOrigin: PopoverOrigin = {
    horizontal: 'right',
    vertical: 'top',
  };

  return (
    <Popover
      anchorOrigin={popperAnchorOrigin}
      transformOrigin={popperTransformOrigin}
      open={!!popperEl}
      anchorEl={popperEl}
      onClose={() => setPopperEl(null)}
      className={styles.paperNotifications}
    >
      <div className={classNames(styles.paperNotifications, styles.fadeIn)} style={{ height: `${getNotificationsBoxHeight() + 58}px` }}>
        <header className={styles.notificationsHeader}>
          <p className={styles.newNotificationLabel}>Notifications</p>
          {notificationLabel}
        </header>
        {notificationsContent}
      </div>
    </Popover >
  );
};

const action = {
  readAllNotifications,
  readNotification
};

const mapStateToProps = (state: IStore) => {
  const notifications = getNotificationsArray(state.notificationsState);
  return {
    notifications
  };
};

export default connect(mapStateToProps, action)(Notifications);
