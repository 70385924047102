import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from './styles.m.scss';

export default function NotFound() {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.errorCode}>404</h1>
      <h2 className={styles.title}>page not found!</h2>
      <p className={styles.errorMessage}>This page cannot be reached</p>
      <Link to="/dashboard" className={styles.goHomeLink}>
        <Button variant="contained" color="primary" className={styles.goHome}>
          Go to home page
        </Button>
      </Link>
    </div>
  );
}
