import { Store, Dispatch, Middleware } from 'redux';
import api from '@redux/actions/api';
import Types from '../reducers/notifications/types';
import { getFileFromNotificationById, getFileById } from '@redux/reducers/files/actions';
import {changeCategoryPermission, removeCategoryPermission} from '@redux/reducers/categories/actions';

const middlware = (store: Store<IStore>) => (next: Dispatch) => (
  action: IAction
) => {
  next(action);

  if (action.type === Types.SET_ADD_FILE_NOTIFICATION) {
    if (action.payload) {
      const fileData: IFileNotificationData = JSON.parse(action.payload.event.message as string);
      if (action.payload.isSectionAvailable) {
        store.dispatch(getFileFromNotificationById(fileData.fileId));
      } else {
        store.dispatch(getFileById(fileData.fileId));
      }
    }
  }

  if (action.type === Types.READ_NOTIFICATION_REQUEST) {
    store.dispatch(
      api({
        method: 'put',
        onError: Types.READ_NOTIFICATION_CALLBACK,
        onSuccess: Types.READ_NOTIFICATION_CALLBACK,
        url: `/notifications/read/${action.payload}`
      })
    );
  }

  if (action.type === Types.READ_ALL_NOTIFICATIONS_REQUEST) {
    store.dispatch(
      api({
        method: 'put',
        onError: Types.READ_ALL_NOTIFICATIONS_ERROR,
        onSuccess: Types.READ_ALL_NOTIFICATIONS_SUCCESS,
        url: `/notifications/read/all`
      })
    );
  }

  if (action.type === Types.CHANGE_PERMISSION_NOTIFICATION) {
      store.dispatch(
          changeCategoryPermission(action.payload.event, action.payload.changedCategory)
      );
  }

  if (action.type === Types.REMOVE_PERMISSION_NOTIFICATION) {
      store.dispatch(
          removeCategoryPermission(action.payload.event, action.payload.changedCategory)
      );
    }
};

export default middlware as Middleware;
