import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from '../assetImageView/styles.m.scss';
import FileTags from '@pages/category/components/file/file-tags';
import ShareIcon from '@material-ui/icons/Share';
import {IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {isAdminOrModerator} from '@services/user-role';
import {OpenInNew} from '@material-ui/icons';
import Loader from '@components/loader';

interface IProps {
   accessToken: string;
   key: number;
   item: IFile;
   imageHeight: number;
   openSectionItem: (item: IFile) => void;
   shareHandle: (value: boolean, fileId?: string) => void;
   removeHandle: (isForAll?: boolean) => void;
   handleOpenOptions: (
        event: React.MouseEvent<HTMLButtonElement>,
        file: IFile
    ) => void;
   userRole: UserRoleType;
   updateDefaultImageHeight: (elementImageHeight: number) => void;
}

function AssetImageView(props: IProps) {
    const [dimensions, setDimensions] = useState({ width: 0, height: props.imageHeight });
    const [imageUrl, setImageUrl] =
        useState(`https://drive.google.com/thumbnail?sz=w320&id=${props.item.data?.fileId}&accessToken=${props.accessToken}`);
    const [isImageLoading, setIsImageLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            setDimensions({
                height: dimensions.height,
                width: props.imageHeight === Number.MAX_SAFE_INTEGER ?
                    img.width : (img.width / img.height * props.imageHeight),
            });
            props.updateDefaultImageHeight(img.height);
        };
    }, [imageUrl, props.imageHeight]);

    const handleImageError = () => {
        setImageUrl('../../../assets/svg/fileCorrupted.svg');
    };

    const handleImageLoad = () => {
        setIsImageLoading(false);
    };

    const linkImageUrl = '../../../assets/svg/link.svg';
    const groupFileUrl = '../../../assets/svg/folder.svg';

    const getBackgroundImg = (isGroup: boolean, fileType: string) => {
        if (isGroup) {
            return groupFileUrl;
        }

        if (fileType === 'link') {
            return linkImageUrl;
        }

        return imageUrl;
    };

    return (
        <div className={styles.imagePreview} key={props.key}>
                <div
                    style={{
                        backgroundImage: `url(${getBackgroundImg(props.item.isGroup, props.item.data?.fileType)})`,
                        height: `${props.imageHeight}px`,
                        width: `${dimensions.width}px`
                    }}
                    className={classNames(styles.imageDiv)}
                    onClick={() => props.openSectionItem(props.item)}
                >
                    <img
                        src={imageUrl}
                        alt=""
                        style={{ display: 'none' }}
                        onError={handleImageError}
                        onLoad={handleImageLoad}
                    />
                    {isImageLoading ?
                        (<Loader isLoading={true}/>)
                        :
                        (
                            <div className={styles.options}>
                                <div className={styles.fileOptions}>
                                    {isAdminOrModerator(props.userRole) && (
                                        <div className={styles.actions}>
                                            <IconButton
                                                className={styles.icon}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    props.handleOpenOptions(e, props.item);
                                                    props.shareHandle(true, props.item.id);
                                                }}
                                            >
                                                <ShareIcon />
                                            </IconButton>
                                            <IconButton
                                                className={styles.icon}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    props.handleOpenOptions(e, props.item);
                                                    props.removeHandle(false);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                                className={styles.icon}
                                                onClick={() => props.openSectionItem(props.item)}
                                            >
                                                <OpenInNew />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.fileTagsOptions} onClick={(e) => e.stopPropagation()}>
                                    <FileTags file={props.item} />
                                </div>
                            </div>
                        )
                    }
                </div>
            <div className={classNames(styles.imageTitle)}>
                {props.item.name.length < 30 ? props.item.name : props.item.name.slice(0, 30) + '...'}
            </div>
        </div>
    );
}

export default AssetImageView;
