import React, { FC } from 'react';
import styles from './styles.m.scss';
import classNames from 'classnames';
import {Button} from '@material-ui/core';

interface IProps {
    onSubscribeClick: () => void;
}

const NoBillingInfo: FC<IProps> = ({ onSubscribeClick }) => {
    return (
        <div className={styles.noBillingInfoDiv}>
            <p>You don't have any subscriptions yet. </p>
            <p>You won't be able to add any new users to your company or share any files until you subscribe.</p>
            <Button
                variant="contained"
                className={classNames(styles.subscribeBtn)}
                onClick={onSubscribeClick}
                style={{ backgroundColor: '#007bff', color: 'white' }}
            >
                Subscribe Now
            </Button>
        </div>
    );
};

export default NoBillingInfo;
