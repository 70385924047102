import React from 'react';
import { Checkbox } from '@material-ui/core';
import { useStylesCheckbox } from '@styles/checkbox';
import classNames from 'classnames';

interface ICheckBoxProps {
  id: string;
  checked: boolean;
  disabled: boolean;
}

export const CheckBox = (props: ICheckBoxProps) => {
  const classes = useStylesCheckbox({});

  return (
    <Checkbox
      checked={props.checked}
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      disabled={props.disabled}
      icon={<span className={classes.icon} />}
      className={classes.root}
      value={props.id}
      color="primary"
    />
  );
};
