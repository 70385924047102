import React from 'react';
import './styles.css';
import Google from '@assets/svg/google.svg?tag';

function GoogleSignInButton() {
    return (
        <button className="gsi-material-button" style={{ width: 400 }}>
            <div className="gsi-material-button-state"/>
            <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                       <Google/>
                </div>
                <span className="gsi-material-button-contents">Sign in with Google</span>
                <span style={{ display: 'none' }}>Sign in with Google</span>
            </div>
        </button>
    );
}

export default GoogleSignInButton;
