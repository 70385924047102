import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import configUrls from '../../config';
import {CircularProgress, FormGroup} from '@material-ui/core';
import AddCompany from './components/add-company';
import Loader from '@components/loader';
import ModalWindow from '@components/modal-window';
import {
  createCompany,
  logOut,
  requestTokenUpdate,
  setAccountDeactivated,
  setActionId,
  setInvitedCompanyId,
  setNewUser,
  setToken,
  accessViaSharedLink, setSharedLink
} from '@redux/reducers/auth/actions';
import Logo from '@assets/logo.svg?tag';
import styles from './styles.m.scss';
import GoogleSignInButton from '@pages/auth/components/google-sign-in';

interface IProps extends RouteComponentProps {
  accessViaSharedLink: typeof accessViaSharedLink;
  createCompany: typeof createCompany;
  requestTokenUpdate: typeof requestTokenUpdate;
  setToken: typeof setToken;
  setAccountDeactivated: typeof setAccountDeactivated;
  setInvitedCompanyId: typeof setInvitedCompanyId;
  setSharedLink: typeof setSharedLink;
  setActionId: typeof setActionId;
  setNewUser: typeof setNewUser;
  logOut: typeof logOut;
  invitedCompanyId: string;
  userEmail: string;
  isCompanyReq: boolean;
  isLoading: boolean;
  isTokenOutdated: boolean;
  isAccountDeactivated: boolean;
  lastRoute: {
    [lastRouteId: string]: string;
  };
  profileId: string;
  companyId: string;
  token: string;
  sharedLinkData: string;
}

declare const VERSION: string;

function Auth(props: IProps) {
  const history = useHistory();
  const [openAccessDeniedModal, handleAccessDeniedModal] = useState<boolean>(false);
  const [openAccessDeniedNotPaidModal, handleAccessDeniedNotPaidModal] = useState<boolean>(false);
  const [openSessionExpiredModal, handleSessionExpiredModal] = useState<boolean>(false);
  const [openAccountDeactivatedModal, handleAccountDeactivatedModal] = useState<boolean>(false);
  const [openAnotherAccountModal, setAnotherAccountModal] = useState<boolean>(false);
  const [accountCloseModal, setAccountCloseModal] = useState<number>(-1);
  const [inviteConfirmModal, setInviteConfirmModal] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const { location, token, invitedCompanyId , userEmail} = props;
    if (location.pathname === '/auth/access-denied') {
      handleAccessDeniedModal(true);
    } else if (location.pathname === '/auth/invalid-subscription') {
      handleAccessDeniedNotPaidModal(true);
    } else if (location.pathname === '/auth/session-expired') {
      handleSessionExpiredModal(true);
    } else if (location.pathname === '/auth/account-deactivated' || props.isAccountDeactivated) {
      handleAccountDeactivatedModal(true);
    } else if (location.pathname === '/auth/confirm-close/') {
      const status = queryString.parse(location.search).status;
      let intStatus = parseInt(status as string, 10);
      if (intStatus < 1 || intStatus > 3) {
        intStatus = 4;
      }
      setAccountCloseModal(intStatus);
    } else if (location.pathname === '/auth/confirm-invite/') {
      const status = queryString.parse(location.search).status;
      const intStatus = parseInt(status as string, 10);
      setInviteConfirmModal(intStatus);
    } else if (location.pathname.includes('/auth/shared-link')) {
      const encryptedData = queryString.parse(location.search).data as string;
      props.setSharedLink(encryptedData);
    } else if (queryString.parse(location.search).companyId && props.profileId && props.companyId) {
      if (props.companyId !== queryString.parse(location.search).companyId) {
        setAnotherAccountModal(true);
      } else {
        history.push('/dashboard');
      }
    } else if (queryString.parse(location.search).companyId && queryString.parse(location.search).action) {
      props.setInvitedCompanyId(queryString.parse(location.search).companyId as string);
      props.setActionId(queryString.parse(location.search).action as string);
    } else if (queryString.parse(location.search).companyId) {
      props.setInvitedCompanyId(queryString.parse(location.search).companyId as string);
    } else {
      const newToken = queryString.parse(location.search).v;
      if (location.pathname === '/auth/token' && newToken && !token) {
        props.setToken(newToken as string);
      } else if (!props.isLoading) {
        if (props.userEmail) {
          (window as any).profitwell('start', {
            user_email: props.userEmail
          });
        }
        if (props.companyId && token) {
          if (props.invitedCompanyId && props.companyId !== props.invitedCompanyId) {
            setAnotherAccountModal(true);
          } else if (props.sharedLinkData) {
            props.accessViaSharedLink(props.sharedLinkData, props.userEmail);
          } else {
            history.push(
              props.lastRoute && props.lastRoute[props.profileId]
                ? props.lastRoute[props.profileId]
                : '/dashboard'
            );
          }
        } else if (token.length > 0 && invitedCompanyId) {
          props.createCompany(invitedCompanyId, null);
          props.setNewUser(true);
        } else if (token.length > 0 && props.sharedLinkData) {
          props.accessViaSharedLink(props.sharedLinkData, props.userEmail);
        } else if (token.length > 0 && !props.isCompanyReq) {
          history.push('/company');
          props.setNewUser(true);
        }
      }
    }
  });

  const onCloseModal = (value: boolean) => {
    if (value === false) {
      window.location.href = '/auth';
    }
    if (openAccessDeniedModal) {
      handleAccessDeniedModal(value);
    } else if (openAccessDeniedNotPaidModal) {
      handleAccessDeniedNotPaidModal(value);
    } else if (openSessionExpiredModal) {
      setAccountCloseModal(-1);
    }
  };

  const onCloseSessionExpiredModal = (value: boolean) => {
    history.push('/auth');
    handleSessionExpiredModal(value);
    if (!value && props.profileId) {
      props.logOut();
    }
  };

  const onCloseAccountDeactivatedModal = (value: boolean) => {
    history.push('/auth');
    handleAccountDeactivatedModal(value);
    if (!value) {
      props.setAccountDeactivated();
      if (props.profileId) {
        props.logOut();
      }
    }
  };

  const onCloseAnotherAccountModal = (_value: boolean) => {
    props.logOut();
    setAnotherAccountModal(false);
    history.push('/auth');
  };

  const setUpLoader = () => {
    setLoading(true);
  };

  const getAccessDeniedMessage = () => {
    if (openAccessDeniedNotPaidModal) {
      return messageNotPaid;
    }

    if (openAccessDeniedModal) {
      return messageInsufficientPermissions;
    }

    return messageAccessDenied;
  };

  const requestCompany = props.isCompanyReq ? (
    <AddCompany confirmAction={props.createCompany} ownerEmail={props.userEmail}/>
  ) : (
      <FormGroup className={styles.mainWrap} onClick={setUpLoader}>
        <h1>Sign In</h1>
        <p>Neatly helps employees to find their files and documents</p>
        <a
          className={styles.googleSignup}
          href={configUrls.apiURL + '/auth/google'}
        >
          <GoogleSignInButton/>
        </a>
      </FormGroup>
    );

  const messageInsufficientPermissions = {
    text: (
      <p>
        In order to start using neatly please give all{' '}
        <span>required permissions</span> during sign in.
      </p>
    ),
    title: <h1>Problem occurred while logging in</h1>
  };

  const messageNotPaid = {
    text: (
        <p>
          Looks like your company has an{' '}
          <span>invalid subscription</span>, please notify the company owner.
        </p>
    ),
    title: <h1>Problem occurred while logging in</h1>
  };

  const messageAccessDenied = {
    text: (
        <p>
          An unknown error has occurred, please try again.
        </p>
    ),
    title: <h1>Problem occurred while logging in</h1>
  };

  const messageSessionExpired = {
    text: (
      <p>
        In order to continue please <span>sign in</span> again.
      </p>
    ),
    title: <h1>Your session has expired</h1>
  };

  const messageUserNotActive = {
    text: (
      <p>
        Your Neatly account <span>was deactivated</span> by company owner.
      </p>
    ),
    title: <h1>Account deactivated</h1>
  };

  const messageAnotherAccount = {
    text: (
      <p>
        Seems like you already have an account <span>in another company</span>.
      </p>
    ),
    title: <h1>An account in another company</h1>
  };

  const closeAccountMessages = [
    { text: '', title: '' },
    {
      text: (
        <p>
          Your <span>Neatly</span> account successfully closed.
        </p>
      ),
      title: <h1>Your account closed</h1>
    },
    {
      text: (
        <p>
          Seems like you already deleted your <span>Neatly</span> account.
        </p>
      ),
      title: <h1>Your account do not exist</h1>
    },
    {
      text: (
        <p>
          Action to close your <span>Neatly</span> account is outdated. Please
          try again.
        </p>
      ),
      title: <h1>Close account action is outdated</h1>
    },
    {
      text: (
        <p>
          Some error occurred when tried to close your <span>Neatly</span> account. Please try again later.
        </p>
      ),
      title: <h1>Unexpected error</h1>
    }
  ];

  const confirmInviteMessages = [
    { text: '', title: '' },
    {
      text: (
        <p>
          The invite to join your <span>Neatly</span> company successfully confirmed.
        </p>
      ),
      title: <h1>Invitation successfully confirmed</h1>
    },
    {
      text: (
        <p>
          Please upgrade your company plan to add more users.
        </p>
      ),
      title: <h1>Company upgrade required</h1>
    },
    {
      text: (
        <p>
          Invitation request to join your <span>Neatly</span> company is outdated. Please
          try again.
        </p>
      ),
      title: <h1>Invitation request is outdated</h1>
    },
    {
      text: (
        <p>
          Invited user already uses <span>Neatly</span>.
        </p>
      ),
      title: <h1>Invited user already exists</h1>
    }
  ];

  return props.isLoading || loading ? (
      <Loader isLoading={true}/>
  ) : (
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Logo/>
        </div>
        <div className={styles.label}>v{VERSION}</div>
        <div className={styles.main}>{requestCompany}</div>
        <ModalWindow
            type="auth_modal"
            message={getAccessDeniedMessage()}
            open={openAccessDeniedModal || openAccessDeniedNotPaidModal}
            onClose={onCloseModal}
        />
        <ModalWindow
            type="auth_modal"
            message={messageSessionExpired}
            open={openSessionExpiredModal}
            onClose={onCloseSessionExpiredModal}
        />
        <ModalWindow
            type="auth_modal"
            message={messageUserNotActive}
            open={openAccountDeactivatedModal}
            onClose={onCloseAccountDeactivatedModal}
        />
        <ModalWindow
            type="auth_modal"
            message={closeAccountMessages[accountCloseModal]}
            open={accountCloseModal > 0}
            onClose={onCloseModal}
        />
        <ModalWindow
            type="auth_modal"
            message={confirmInviteMessages[inviteConfirmModal]}
            open={inviteConfirmModal > 0}
            onClose={onCloseModal}
        />
        <ModalWindow
            type="auth_modal"
            message={messageAnotherAccount}
            open={openAnotherAccountModal}
            onClose={onCloseAnotherAccountModal}
        />
      </div>
  );
}

const mapStateToProps = (state: IStore) => ({
  companyId:
      state.auth.profile && state.auth.profile.company
          ? state.auth.profile.company.companyId
          : null,
  invitedCompanyId: state.auth.invitedCompanyId,
  isAccountDeactivated: state.auth.isAccountDeactivated,
  isLoading: state.auth.isLoading && !state.auth.isAccountDeactivated,
  isTokenOutdated: state.auth.isTokenOutdated,
  lastRoute: state.auth.lastRoute,
  profileId: state.auth.profile ? state.auth.profile.id : null,
  sharedLinkData: state.auth.sharedLinkData,
  token: state.auth.token,
  userEmail: state.auth.profile?.data?.email,
});

const actions = {
  accessViaSharedLink,
  createCompany,
  logOut,
  requestTokenUpdate,
  setAccountDeactivated,
  setActionId,
  setInvitedCompanyId,
  setNewUser,
  setSharedLink,
  setToken
};

export default connect(mapStateToProps, actions)(Auth);
