import React, { createRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Input, FormGroup } from '@material-ui/core';
import { createCompany } from '@redux/reducers/auth/actions';
import { useHistory } from 'react-router-dom';

import styles from '../../styles.m.scss';

interface IProps {
  token: string;
  confirmAction: typeof createCompany;
  ownerEmail: string;
}

function AddCompany(props: IProps) {
  const [nameHasError, setNameError] = useState(false);

  const inputRef = createRef<HTMLInputElement>();
  const history = useHistory();

  useEffect(() => {
    if (!props.token) {
      history.push('/auth');
    }
  });

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    setNameError(false);
    if (e.key === 'Enter') {
      handelConfirm();
    }
  };

  const handelConfirm = () => {
    if (inputRef.current.value && inputRef.current.value.trim()
      && inputRef.current.value.length > 2 && inputRef.current.value.length < 30) {
      props.confirmAction(undefined, inputRef.current.value, props.ownerEmail);
    } else {
      setNameError(true);
    }
  };

  return (
    <FormGroup className={styles.mainWrap}>
      <h1>Sign In</h1>
      <p>Add your company to complete registration</p>
      <Input
        className={styles.input}
        placeholder="Company"
        type="email"
        error={nameHasError}
        inputRef={inputRef}
        onKeyPress={handleEnter}
      />
      <Button
        variant="contained"
        color="primary"
        className={styles.register}
        onClick={handelConfirm}
      >
        Confirm
      </Button>
    </FormGroup>
  );
}

const mapStateToProps = (state: IStore) => ({
  token: state.auth.token
});

export default connect(mapStateToProps)(AddCompany);
