enum Types {
  FETCH_FILES_REQUEST = '[Files] Fetch request',
  FETCH_FILES_SUCCESS = '[Files] Fetch success',

  GET_FILE_REQUEST = '[Files] Get by id request',
  GET_FILE_SUCCESS = '[Files] Get by id success',
  GET_FILE_ERROR = '[Files] Get by id error',

  GET_FILE_TAGS_REQUEST = '[Files] Get tags for company request',
  GET_FILE_TAGS_SUCCESS = '[Files] Get tags for company success',
  GET_FILE_TAGS_ERROR = '[Files] Get tags for company error',

  ADD_NEW_FILE_TAG_REQUEST = '[Files] Add new file tag request',
  ADD_NEW_FILE_TAG_SUCCESS = '[Files] Add new file tag success',
  ADD_NEW_FILE_TAG_ERROR = '[Files] Add new file tag error',

  DELETE_FILE_TAGS_REQUEST = '[Files] Delete file tags request',
  DELETE_FILE_TAGS_SUCCESS = '[Files] Delete file tags success',
  DELETE_FILE_TAGS_ERROR = '[Files] Delete file tags error',

  ADJUST_FILE_PERMISSIONS_REQUEST = '[Files] Adjust google file permissions by id request',
  ADJUST_FILE_PERMISSIONS_SUCCESS = '[Files] Adjust google file permissions by id success',
  CLEAR_PREVIEW_ALLOWED = '[Files] Don\'t allow file preview',
  NO_ACTION = '[Files] No action required',

  GET_FILE_FROM_NOTIFICATION_REQUEST = '[Files] Get by id from notification request',
  GET_FILE_FROM_NOTIFICATION_SUCCESS = '[Files] Get by id from notification success',
  GET_FILE_FROM_NOTIFICATION_ERROR = '[Files] Get by id from notification error',

  CREATE_DOC_FILE_REQUEST = '[File] Create document request',
  CREATE_DOC_FILE_SUCCESS = '[File] Create document success',

  CREATE_BY_URL_REQUEST = '[File] Create url request',
  CREATE_BY_URL_SUCCESS = '[File] Create url success',

  CREATE_BY_DRIVE_REQUEST = '[File] Create drive request',
  CREATE_BY_DRIVE_SUCCESS = '[File] Create drive success',

  UPLOAD_REQUEST = '[File] Upload request',
  UPLOAD_SUCCESS = '[File] Upload success',

  UPDATE_FILE_REQUEST = '[File] Update request',
  UPDATE_FILE_SUCCESS = '[File] Update success',
  UPDATE_FILE_ERROR = '[File] Update error',

  MAKE_SHARABLE_WITH_LINK_REQUEST = '[File] Make sharable with link request',
  MAKE_SHARABLE_WITH_LINK_SUCCESS = '[File] Make sharable with link success',
  MAKE_SHARABLE_WITH_LINK_ERROR = '[File] Make sharable with link error',

  DELETE_FILE_REQUEST = '[File] Delete request',
  DELETE_FILE_SUCCESS = '[File] Delete success',
  DELETE_ALL_FILES_FROM_SECTION = '[File] Delete all files from section',
  CLEAR_FILES_AFTER_NOTIFICATION = '[File] Clear files from notifications',

  CHANGE_TO_GROUP_REQUEST = '[File] Change to group request',
  CHANGE_TO_GROUP_SUCCESS = '[File] Change to group success',

  SET_ACTIVE_IDS = '[File] Set active ids',

  TOGGLE_NEW_FILE = '[File] Toggle new file window',

  CLEAR_ACTIVE_GROUP = '[File] Clear active group',

  SELECT_FROM_DRIVE_MODAL = '[File] Select from drive',

  INVITE_FILE_REQUEST = '[File] Invite request',
  INVITE_FILE_SUCCESS = '[File] Invite success',
  INVITE_FILE_ERROR = '[File] Invite error',

  CLEAR_SHARE_ERROR = '[File] Clear share error',

  OPEN_FILE_FROM_DRIVE_REQUEST = '[File] Open file from drive request',
  OPEN_FILE_FROM_DRIVE_SUCCESS = '[File] Open file from drive success',

  FILES_ERROR = '[File] Error',

  GET_FILES_FEED_REQUEST = '[File] Get files feed request',
  GET_FILES_FEED_SUCCESS = '[File] Get files feed success',
  GET_FILES_FEED_ERROR = '[File] Get files feed error',

  GET_FILES_FEED_NEW_AMOUNT_REQUEST = '[File] Get amount new files from feed request',
  GET_FILES_FEED_NEW_AMOUNT_SUCCESS = '[File] Get amount new files from feed success',
  GET_FILES_FEED_NEW_AMOUNT_ERROR = '[File] Get amount new files from feed error',
}

export default Types;
