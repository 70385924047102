import React, { useState, useEffect, createRef } from 'react';
import * as XLSX from 'xlsx';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  clearShareError,
  getUsersEmail,
  inviteUsers,
  shareCategory,
  fetchUsers
} from '@redux/reducers/auth/actions';
import {inviteToFile, clearShareFileError, makeFileShareableWithLink} from '@redux/reducers/files/actions';
import { isModerator } from '@services/user-role';
import {
  Modal,
  Fade,
  Backdrop,
  TextField,
  Button,
  Menu,
  MenuItem,
  withStyles,
  Checkbox
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import { isEmailValidate } from '@utils/validations';
import {
  PaymentDetailsModalWindows,
  ShareModalWindow
} from '@components/modal-window/modalWindowsTypes';
import Loader from '@components/loader';
import {
  clearCreatingCardFailed,
  upgradePlanWithoutCard
} from '@redux/reducers/payments​/actions';
import { getUsersArray } from '@redux/reducers/auth';
import {
  closeModalWindow,
  openModalWindow
} from '@redux/reducers/modal-window/actions';
import EditPaymentDetails from '@components/modal-edit-payment-details';
import { isModalWindowOpen } from '@services/modal-windows';
import { fetchProjects } from '@redux/reducers/projects/actions';
import {fetchCategories } from '@redux/reducers/categories/actions';
import ModalWindow from '@components/modal-window';
import TooltipSet from '@components/custom-input-tooltip';

import { useStylesCheckbox } from '@styles/checkbox';
import Close from '@assets/svg/close.svg?tag';
import styles from './styles.m.scss';
import Plausible from 'plausible-tracker';
import LinkIcon from '@material-ui/icons/Link';
import toast from 'react-hot-toast';
import {BeatLoader} from 'react-spinners';

const MenuFileItem = withStyles(() => ({
  root: {
    color: '#757575',
    minHeight: 'unset',
    padding: '5px 15px',
    width: '130px'
  }
}))(MenuItem);

const AutocompleteItem = withStyles(() => ({
  option: {
    padding: '5px'
  },
  paper: {
    borderRadius: '0',
    fontSize: '13px',
    margin: '0',
    padding: '0'
  }
}))(Autocomplete);

type UploadEmailsError =
  | 'invalid'
  | 'no-unique'
  | 'file-extension'
  | 'reading-file';
type UserRoleMenuBar = 'moderator' | 'user';
type InvitingType = 'by-input' | 'upload';

interface IMarkedFile {
  categoryId: string;
  fileId: string;
}

interface IReduxProps {
  users: IUserEmail[];
  count: number;
  isCardCreatingFailed: boolean;
  isOwner: boolean;
  modalWindows: any;
  card: ICard;
  profile: IProfile;
  projects: ICategory[];
  categories: ICategory[];
  categoryShareLink: string;
  companyUsers: IUser[];
  isLoadingOnFetchUsers: boolean;
  isLoadingCopyShareLink: boolean;
  isLoading: false;
  shareError: string;
  shareFileError: string;
  inviteUsers: typeof inviteUsers;
  clearShareError: typeof clearShareError;
  clearShareFileError: typeof clearShareFileError;
  inviteToFile: typeof inviteToFile;
  shareCategory: typeof shareCategory;
  getUsersEmail: typeof getUsersEmail;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
  upgradePlanWithoutCard: typeof upgradePlanWithoutCard;
  clearCreatingCardFailed: typeof clearCreatingCardFailed;
  fetchProjects: typeof fetchProjects;
  fetchCategories: typeof fetchCategories;
  fetchUsers: typeof fetchUsers;
  makeFileShareableWithLink: typeof makeFileShareableWithLink;
}

interface IOwnProps {
  categoryId?: string;
  filesIds?: string[] | IMarkedFile[];
  activeFileId?: string;
  userShareRole: UserRoleType | '';
  open: boolean;
  title?: string;
  subtitle?: string;
  setOpen: (value?: boolean) => void;
  onAutomaticallyPlanUpgrade?: (value: boolean) => void;
  onSkip?: () => void;
  link: string;
  isGroup?: boolean;
  isLink?: boolean;
}

interface IProps extends IReduxProps, IOwnProps {}

const mapStateToProps = (state: IStore) => {
  const companyUsers = getUsersArray(state.auth);
  return {
    activePlan: state.paymentsState.activePlan,
    card: state.paymentsState.card,
    categories: state.categoriesState.categories,
    categoryShareLink: state.categoriesState.shareLink,
    companyUsers,
    count: state.auth.users.count,
    isCardCreatingFailed: state.paymentsState.isCardCreatingFailed,
    isLoading: state.auth.isLoading,
    isLoadingCopyShareLink: state.filesState.isLoadingCopyShareLink,
    isLoadingOnFetchUsers: state.projectState.isLoadingOnFetchUsers,
    isOwner: state.auth.profile.company.owner,
    modalWindows: state.modalWindowState.modalWindows,
    profile: state.auth.profile,
    projects: state.projectState.projects,
    shareError: state.auth.shareError,
    shareFileError: state.filesState.shareError,
    users: state.auth.usersEmails,
  };
};

const actions = {
  clearCreatingCardFailed,
  clearShareError,
  clearShareFileError,
  closeModalWindow,
  fetchCategories,
  fetchProjects,
  fetchUsers,
  getUsersEmail,
  inviteToFile,
  inviteUsers,
  makeFileShareableWithLink,
  openModalWindow,
  shareCategory,
  upgradePlanWithoutCard
};

const Share = (props: IProps) => {
  const {
    activeFileId,
    filesIds,
    categoryId,
    userShareRole,
    users,
    open,
    setOpen,
    shareError,
    shareFileError,
    link,
  } = props;
  const [shareOptionEl, setShareOptionEl] = useState<HTMLElement | null>(null);
  const [selectedUserRole, setSelectedUserRole] = useState<UserRoleMenuBar>(
    'user'
  );
  const [selectedUsers, setSelectedUsers] = useState<IUserEmail[]>([]);
  const userListInputRef = createRef<HTMLInputElement>();
  const [invitingType, setInvitingType] = useState<InvitingType>('by-input');
  const [errorEmailMessage, setErrorEmailMessage] = useState<string>('');
  const emailInputRef = createRef<HTMLInputElement>();
  const [isAutomaticallyPlanOpen, setIsAutomaticallyPlanOpen] = useState(false);
  const [isUpgradePlanOpen, setIsUpgradePlanOpen] = useState(false);
  const [isInviteToCompany, setIsInviteToCompany] = useState(false);
  const [newActivePlan, setNewActivePlan] = useState<IPlan>(null);
  const [selectedPlan, setSelectedPlan] = useState<IPlan>(null);
  const [type, setType] = useState<'file' | 'project' | 'category' | null>(null);
  const [name, setName] = useState<string>('');
  const [isModalLoading, setIsModalLoading] = useState(false);
  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  useEffect(() => {
    props.getUsersEmail();
    props.fetchCategories();
    props.fetchProjects();
    if (props.isOwner) {
      props.fetchUsers();
    }
  }, [open]);

  useEffect(() => {
    if (shareFileError === 'USER_EXISTS' || shareFileError === 'USERS_INVITED' ||
      shareError === 'USER_EXISTS' || shareError === 'USERS_INVITED') {
      setErrorEmailMessage('Can\'t invite user who already uses Neatly with another company');
    }
  }, [shareError, shareFileError]);

  const classes = useStylesCheckbox({});

  const onClose = (() => {
    setOpen();
    props.clearShareError();
    props.clearShareFileError();
  });

  const isAutomaticallyPlanUpgrade = (countEmails: number) =>
    props.userShareRole &&
    props.isOwner;

  const onAddUser = () => {
    if (
      emailInputRef.current &&
      isEmailValidate(emailInputRef.current.value)
    ) {
      setSelectedUsers([
        ...selectedUsers,
        { email: emailInputRef.current.value, displayName: '' }
      ]);
    } else {
      setUploadError(emailInputRef.current.value, 'invalid');
    }
  };

  const handleAddEmailForFile = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (errorEmailMessage) {
      if (shareError) {
        props.clearShareError();
      }

      if (shareFileError) {
        props.clearShareFileError();
      }

      setErrorEmailMessage('');
    }
    if (event && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      onAddUser();
    }
  };

  const onCopyLink = async () => {
    try {
      if (categoryId) {
        await navigator.clipboard.writeText(props.categoryShareLink);
      } else {
        if (!props.isLink) { props.makeFileShareableWithLink(activeFileId); }
        await navigator.clipboard.writeText(props.link);
      }
      toast.success('Link copied');
      setOpen(false);
    } catch (err) {
      toast.error('Failed to copy link');
    }
  };

  const onShare = () => {
    const emails = selectedUsers.map((user) => user.email);
    if (!emails || !emails.length) {
      setOpen(false);
      return;
    }
    if (activeFileId) {
      setType('file');
      plausible.trackEvent('Share Single File');
      props.inviteToFile(emails, [activeFileId], isInviteToCompany);
    } else if (filesIds && filesIds.length) {
      setType('file');
      plausible.trackEvent('Share Single File');
      const filesToAdd: string[] = [];
      let complexIds: {
        [categoryId: string]: string[];
      };
      filesIds.forEach((f: string | IMarkedFile) => {
        if (typeof f === 'string') {
          filesToAdd.push(f);
        } else {
          if (complexIds) {
            if (complexIds[f.categoryId]) {
              complexIds[f.categoryId].push(f.fileId);
            } else {
              complexIds = { ...complexIds, [f.categoryId]: [f.fileId] };
            }
          } else {
            complexIds = { [f.categoryId]: [f.fileId] };
          }
        }
      });
      if (filesToAdd && filesToAdd.length) {
        props.inviteToFile(emails, filesToAdd, isInviteToCompany);
      } else if (complexIds) {
        for (const cat in complexIds) {
          if (Object.prototype.hasOwnProperty.call(complexIds, cat)) {
            props.inviteToFile(emails, complexIds[cat], isInviteToCompany);
          }
        }
      }
    } else if (categoryId) {
      const cat = props.categories.find((c) => c.id === categoryId);
      const proj = props.projects.find((p) => p.id === categoryId);
      if (cat) {
        setType('category');
        plausible.trackEvent('Invite Users', {
          props: {
            level: 'category'
          }
        });
        setName(cat.name);
      } else if (proj) {
        setType('project');
        plausible.trackEvent('Invite Users', {
          props: {
            level: 'project'
          }
        });
        setName(proj.name);
      }
      props.shareCategory(emails, [categoryId], selectedUserRole);
    }
  };

  const setUploadError = (email: string, errorType: UploadEmailsError) => {
    const finalEmail = email.length > 25 ? email.slice(0, 25) + '...' : email;
    switch (errorType) {
      case 'invalid':
        setErrorEmailMessage(`${finalEmail} is not valid email address`);
        break;
      case 'no-unique':
        setErrorEmailMessage(`${finalEmail} is not unique email address`);
        break;
      case 'file-extension':
        setErrorEmailMessage('Supported only Excel files');
        break;
      case 'reading-file':
        setErrorEmailMessage('Cannot read the file');
        break;
    }
  };

  const isValidFileExtensions = (file: File) => /(\.csv|\.txt|\.xlsx|\.xls)$/i.exec(file?.name);

  const validateEmails = (emails: string[]) => {
    const emailsSet = new Set(emails);
    emailsSet.forEach((email) => {
      email.toLowerCase();
      if (!isEmailValidate(email)) {
        setUploadError(email, 'invalid');
      }
    });
    return Array.from(emailsSet).map((e) => e.toLowerCase());
  };

  const processData = (dataString: string): string[] => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list: string[] = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: string[] = [];
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') {
              d = d.substring(1, d.length - 1);
            }
            if (d[d.length - 1] === '"') {
              d = d.substring(d.length - 2, 1);
            }
          }
          if (headers[j]) {
            obj.push(d);
          }
        }

        if (obj.filter((x) => x).length > 0) {
          list.push(...obj.filter((x) => x));
        }
      }
    }
    return list;
  };

  const processXLSXFile = (evt: ProgressEvent<FileReader>) => {
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    const wsName = wb.SheetNames[0];
    const ws = wb.Sheets[wsName];
    const data = XLSX.utils.sheet_to_csv(ws);
    const emails = processData(data);

    if (isAutomaticallyPlanUpgrade(emails.length)) {
      setIsAutomaticallyPlanOpen(true);
    } else {
      props.shareCategory(
        validateEmails(emails),
        [categoryId],
        selectedUserRole
      );
    }
  };

  const processTextFile = (evt: ProgressEvent<FileReader>) => {
    const contents = evt.target.result as string;
    const emails: string[] = contents.split(/\r?\n/);

    if (isAutomaticallyPlanUpgrade(emails.length)) {
      setIsAutomaticallyPlanOpen(true);
    } else {
      props.shareCategory(
        validateEmails(emails),
        [categoryId],
        selectedUserRole
      );
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (!isValidFileExtensions(file)) {
      setUploadError('', 'file-extension');
      return;
    }

    const reader = new FileReader();

    if (errorEmailMessage) {
      setErrorEmailMessage('');
    }

    if (shareFileError) {
      props.clearShareFileError();
    }

    if (shareError) {
      props.clearShareError();
    }

    if (file.type === 'text/plain') {
      reader.onload = (evt) => processXLSXFile(evt);
      reader.readAsText(file);
    } else {
      reader.onload = (evt) => processTextFile(evt);
      reader.readAsBinaryString(file);
    }
  };

  const onChangeSelectedUsers = (_event: React.ChangeEvent<{}>, value: any) => {
    if (errorEmailMessage) {
      if (shareError) {
        props.clearShareError();
      }

      if (shareFileError) {
        props.clearShareFileError();
      }

      setErrorEmailMessage('');
    }
    setSelectedUsers(value);
  };

  const handleUserRole = (value: UserRoleMenuBar) => {
    setSelectedUserRole(value);
  };

  const handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShareOptionEl(event.currentTarget);
  };

  const handlePaymentModal = (value: boolean) => {
    if (props.isCardCreatingFailed) {
      props.clearCreatingCardFailed();
    }
    if (value) {
      props.upgradePlanWithoutCard(false);
      if (isUpgradePlanOpen) {
        setIsUpgradePlanOpen(false);
      } else if (isAutomaticallyPlanOpen) {
        setIsAutomaticallyPlanOpen(false);
      }
      props.openModalWindow(
        PaymentDetailsModalWindows.editPaymentDetailsModal,
        'payment'
      );
    } else {
      props.closeModalWindow(
        PaymentDetailsModalWindows.editPaymentDetailsModal
      );
    }
  };

  const selectMenuItem = (value: UserRoleMenuBar): React.ReactNode =>
    selectedUserRole === value ? (
      <CheckIcon className={styles.checkIcon} />
    ) : null;

  const iconUserRole =
    selectedUserRole === 'moderator' ? (
      <CreateIcon />
    ) : (
      selectedUserRole === 'user' && <VisibilityIcon />
    );

  const optionBtnForModerator = (
    <Button className={styles.handleBtn} disabled={true}>
      {iconUserRole}
    </Button>
  );

  const optionBtnForAdmin = (
    <Button className={styles.handleBtn} onClick={handleOpenOptions}>
      {iconUserRole}
      <ArrowDropDownIcon className={styles.dropDown} />
    </Button>
  );

  const optionBtnByRole =
    userShareRole !== '' && props.isOwner
      ? optionBtnForAdmin
      : userShareRole !== '' &&
        isModerator(userShareRole) &&
        optionBtnForModerator;

  const optionBtn = userShareRole !== '' && optionBtnByRole;

  const placeholderUsersTextField =
    selectedUsers && selectedUsers.length === 0
      ? 'Enter names or email addresses...'
      : '';

  const usersTextField = (params: RenderInputParams) => (
    <TextField
      {...params}
      error={!!errorEmailMessage}
      placeholder={placeholderUsersTextField}
      fullWidth={true}
      className={styles.usersTextField}
      inputRef={emailInputRef}
      onKeyPress={handleAddEmailForFile}
    />
  );

  const checkedIcon = (
    <span className={classNames(classes.icon, classes.checkedIcon)} />
  );

  const getOnOptionsText = () => {
    if (!activeFileId && !filesIds?.length) {
      return (
        <div className="nonUser" onClick={onAddUser}>
          isn't a user...{' '}
          <Button className="button">invite to Join Neatly</Button>
        </div>
      );
    } else {
      return (
        <div className="nonUser" onClick={onAddUser}>
          No user with the email found... hit 'enter' to share anyway
        </div>
      );
    }
  };

  const successMessageShare = {
    action: 'Successfully Shared!',
    text: (
      <p>
        You successfully shared {type === 'file' ? 'file(s)' : type} <span>{name}</span>
      </p>
    ),
    title: <h1>Successfully Shared!</h1>
  };

  return (
    <div>
      <Modal
        className={styles.modalShare}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        { (props.isLoadingOnFetchUsers || props.isLoading) ? (
          <div className={styles.loader}>
            <Loader isLoading={true} isBackground={false} isNotAbsoloute={true}/>
          </div>
        ) : (
          <Fade in={open}>
            <div className={classNames(styles.paperShare, styles.fadeIn)}>
              <div className={styles.shareWithOthers}>
                <h2 className={styles.shareTitle}>{props.title ? props.title : 'Share with others'}</h2>
                {props.subtitle && (
                  <p className={styles.subtitle}>{props.subtitle}</p>
                )}
                <div
                  className={classNames(
                    styles.contentShare,
                    filesIds && styles.heightForFiles
                  )}
                >
                  <div className={styles.contentInputEmail}>
                    <div className={styles.errorWrap}>
                      <p className={styles.labelPeople}>People</p>
                    </div>
                    <div className={styles.inputBlock}>
                      <TooltipSet
                        message={errorEmailMessage}
                        isOpen={!!errorEmailMessage}
                        type="error"
                        isModal={true}
                      >
                        <AutocompleteItem
                          value={selectedUsers}
                          className={styles.autocomplete}
                          multiple={true}
                          debug={true}
                          options={users}
                          getOptionLabel={(option) => option.email}
                          renderInput={(params) => usersTextField(params)}
                          noOptionsText={getOnOptionsText()}
                          onChange={onChangeSelectedUsers}
                        />
                      </TooltipSet>
                      {optionBtn}
                      <Menu
                        elevation={1}
                        anchorEl={shareOptionEl}
                        open={Boolean(shareOptionEl)}
                        onClose={() => setShareOptionEl(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      >
                        <MenuFileItem onClick={() => handleUserRole('moderator')}>
                          View &amp; edit
                          {selectMenuItem('moderator')}
                        </MenuFileItem>
                        <MenuFileItem onClick={() => handleUserRole('user')}>
                          View
                          {selectMenuItem('user')}
                        </MenuFileItem>
                      </Menu>
                    </div>
                  </div>
                </div>
                {(activeFileId || filesIds?.length) && (
                  <div className={styles.inviteCheckWrap}>
                    <Checkbox
                      color="primary"
                      value={isInviteToCompany}
                      className={classes.root}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => setIsInviteToCompany(checked)}
                      icon={<span className={classes.icon} />}
                      checkedIcon={checkedIcon}
                    />
                    <p>
                      Also invite to join Neatly (if not a user) & invite to
                      category
                    </p>
                  </div>
                )}
                <footer className={props.onSkip ? styles.shareWithSkip : styles.shareDone}>
                  <div className={props.onSkip && styles.wrapSkip}>
                    { props.onSkip && (
                      <Button
                        variant="text"
                        className={styles.btnSkip}
                        onClick={props.onSkip}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                        variant="outlined"
                        className={classNames(styles.btnLink,
                            (props.filesIds?.length > 1 || props.isGroup) && styles.disabledBtnDone)}
                        onClick={onCopyLink}
                        disabled={props.isLoadingCopyShareLink || props.filesIds?.length > 1 || props.isGroup}
                    >
                      {props.isLoadingCopyShareLink ? (
                          <BeatLoader color={'#03a5fc'} size={10}/>
                      ) : (
                          <>
                            <LinkIcon className={styles.linkIcon} />
                            Copy Link
                          </>
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!selectedUsers.length}
                      className={classNames(
                        styles.btnDone,
                        selectedUsers.length === 0 && styles.disabledBtnDone
                      )}
                      onClick={onShare}
                    >
                      Done
                    </Button>
                  </div>
                </footer>
              </div>
              <Button className={styles.closeBtn} onClick={onClose}>
                <Close />
              </Button>
              <input
                id="file"
                type="file"
                accept=".csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref={userListInputRef}
                style={{ display: 'none' }}
                onChange={(e) => handleFileUpload(e)}
              />
            </div>
          </Fade>
        )}
      </Modal>
      <ModalWindow
        type="success"
        message={successMessageShare}
        open={isModalWindowOpen(
          props.modalWindows,
          ShareModalWindow.successModal,
          'share'
        )}
        onClose={() => {
          props.closeModalWindow(ShareModalWindow.successModal);
          props.setOpen(false);
        }}
        isLoading={props.isLoading}
      />
    </div>
  );
};

export default connect(mapStateToProps, actions)(Share);
