import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCard, fetchInvoice, upgradePlanWithoutCard, clearCreatingCardFailed, subscribeToStripe } from '@redux/reducers/payments​/actions';
import {
  openModalWindow,
  closeModalWindow
} from '@redux/reducers/modal-window/actions';
import { getInvoices } from '@redux/reducers/payments​';
import Scrollbars from 'react-custom-scrollbars';
import {
  Button,
  Card,
  CardActions,
  CardContent, IconButton, makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow, Tooltip,
  Typography
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import styles from './styles.m.scss';
import classNames from 'classnames';
import NoBillingInfo from '@pages/settings/components/no-billing-info';
import SubscriptionCard from '@components/subscription-card';
import Loader from '@components/loader';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
  activePlan: IPlan;
  card: ICard;
  modalWindows: any;
  invoices: IInvoice[];
  plans: IPlan[];
  isCardCreatingFailed: boolean;
  fetchInvoice: typeof fetchInvoice;
  fetchCard: typeof fetchCard;
  upgradePlanWithoutCard: typeof upgradePlanWithoutCard;
  clearCreatingCardFailed: typeof clearCreatingCardFailed;
  subscribeToStripe: typeof subscribeToStripe;
  openModalWindow: typeof openModalWindow;
  closeModalWindow: typeof closeModalWindow;
  countInvoices: number;
  subscription: ISubscription;
  remoteCountInvoices: number;
}

interface IState {
  newActivePlan: IPlan | null;
  selectedPlan: IPlan | null;
  isNewCard: boolean;
  pageInvoices: number;
  rowsPerPageInvoices: number;
  isLoading: boolean;
}

class BillingInfo extends React.Component<IProps, IState> {
  state = {
    isLoading: false,
    isNewCard: false,
    newActivePlan: null,
    pageInvoices: 0,
    rowsPerPageInvoices: 2,
    selectedPlan: null
  } as IState;

  componentDidMount = () => {
    if (!this.props.card) {
      this.props.fetchCard();
    }
    this.props.fetchInvoice();
  };

  handleSubscribeToStripe = () => {
    this.setState({isLoading: true});
    this.props.subscribeToStripe();
  };

  handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const { remoteCountInvoices, countInvoices } = this.props;
    const { rowsPerPageInvoices } = this.state;
    if (remoteCountInvoices > countInvoices && countInvoices < rowsPerPageInvoices * (newPage + 1)) {
      this.props.fetchInvoice(rowsPerPageInvoices, countInvoices);
    }
    this.setState({
      pageInvoices: newPage
    });
  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { countInvoices, remoteCountInvoices } = this.props;
    const { rowsPerPageInvoices } = this.state;
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (newRowsPerPage === -1 && countInvoices < remoteCountInvoices) {
      this.props.fetchInvoice(remoteCountInvoices - countInvoices, countInvoices);
    } else if (newRowsPerPage > countInvoices && newRowsPerPage < remoteCountInvoices) {
      this.props.fetchInvoice(rowsPerPageInvoices - countInvoices, countInvoices);
    }
    this.setState({
      pageInvoices: 0,
      rowsPerPageInvoices: newRowsPerPage
    });
  };

  getHeightOfTableInvoice = () => {
    const { rowsPerPageInvoices } = this.state;
    const { countInvoices } = this.props;
    const rowHeight = 57;
    const spaceHeight = 70;
    const heightFifeRows = rowHeight * 5 + spaceHeight;
    let result = 0;
    if (countInvoices < rowsPerPageInvoices || rowsPerPageInvoices === -1) {
      result = countInvoices * rowHeight + spaceHeight;
    } else {
      result = rowsPerPageInvoices * rowHeight + spaceHeight;
    }
    if (result > heightFifeRows) {
      return heightFifeRows;
    } else {
      return result;
    }
  };

  render() {
    const { invoices, countInvoices, remoteCountInvoices} = this.props;
    const { pageInvoices, rowsPerPageInvoices } = this.state;

    const plansCardContent = this.props.subscription && (
        <SubscriptionCard
            subscription={this.props.subscription}
        />
    );

    const subscriptionDetails = (
      <div className={styles.subscriptionDetails}>
        <h2 className={styles.subscriptionDetailsTitle}>Subscription details</h2>
        <div className={styles.subscriptionDetailsPropose}>
          <Scrollbars
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={200}
            className={styles.plansCardContentScroll}
          >
            {plansCardContent}
          </Scrollbars>
        </div>
      </div>
    );
    //
    const invoicesRows = invoices &&
      invoices.slice(
        rowsPerPageInvoices > 0 ? pageInvoices * rowsPerPageInvoices : 0,
        rowsPerPageInvoices > 0 ? pageInvoices * rowsPerPageInvoices + rowsPerPageInvoices : countInvoices
      ).map((invoice, key) => {
        const date = new Date(Number(invoice.date) * 1000);
        const optionsDate = {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        };
        const invoiceDate = date.toLocaleDateString('en-US', optionsDate);
        const dueDate = new Date(Number(invoice.dueDate) * 1000);
        const optionsDueDate = {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        };
        const invoiceDueDate = invoice.dueDate && dueDate.toLocaleDateString('en-US', optionsDueDate);
        const emptyDueDate = 'No Due date';
        const invoiceDueDateContent = invoice.dueDate ? invoiceDueDate : emptyDueDate;
        const notPaid = invoice.amountDue !== invoice.amount && styles.notPaid;
        const canceled = invoice.status === 'void' && styles.canceled;
        const status = invoice.status === 'void' ? 'closed' : invoice.status;

        return (
          <TableRow
           key={key}
           className={classNames(styles.invoicesSimpleRow, styles.invoicesRow, notPaid, canceled)}
          >
            <TableCell className={styles.invoiceCell}>
              <a href={invoice.invoiceUrl} target="_blank" rel="noopener noreferrer">
                {invoice?.uniqueId?.substr(invoice.uniqueId.length - 4)}
              </a>
            </TableCell>
            <TableCell className={styles.descriptionCell}>{invoice.description}</TableCell>
            <TableCell className={styles.amountCell}>${invoice.amountDue / 100}</TableCell>
            <TableCell>{invoice.email}</TableCell>
            <TableCell>{invoiceDate}</TableCell>
            <TableCell>{invoiceDueDateContent}</TableCell>
            <TableCell>{status}</TableCell>
          </TableRow>
        );
      });

    const invoicesTable = (
      <div className={styles.scrollTable} style={{ height: `${this.getHeightOfTableInvoice()}px` }}>
        <Scrollbars>
          <Table className={styles.invoicesTable}>
            <TableBody>
              <TableRow className={classNames(styles.invoicesHeaderRow, styles.invoicesRow)}>
                <TableCell>Invoice #</TableCell>
                <TableCell className={styles.descriptionCell}>Description</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Billing email</TableCell>
                <TableCell>Invoice date</TableCell>
                <TableCell>Due date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
              {invoicesRows}
            </TableBody>
          </Table>
        </Scrollbars>
      </div>
    );

    const invoicesLabelNoInvoices = <p className={styles.labelNoInvoices}>There are no invoices.</p>;

    const invoicesContent = invoices &&
      invoices.length &&
      (invoices.length > 0) ? invoicesTable : invoicesLabelNoInvoices;

    const invoicesSection = (
      <div className={styles.invoices}>
        <h2 className={styles.invoicesTitle}>Invoices</h2>
        {invoicesContent}
      </div>
    );

    const paginationInvoices =
      countInvoices > 0 ? (
        <TablePagination
          rowsPerPageOptions={[2, 5, 10, 25, { label: 'All', value: -1 }]}
          component="nav"
          count={remoteCountInvoices}
          rowsPerPage={rowsPerPageInvoices}
          page={pageInvoices}
          className={styles.paginationInvoices}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      ) : null;

    const subscriptionPage = this.props.subscription?.status === 'active' && (
        <div className={styles.contentBillingInfo}>
          <div className={styles.billingInfoDetails}>
            {subscriptionDetails}
          </div>
          {invoicesSection}
          {countInvoices > 1 && paginationInvoices}
        </div>
    );

    const freeTrialPage = this.props.subscription?.status === 'trialing' && (
        <div className={styles.freeTrialDetails}>
          <Card className={styles.freeTrialCard}>
            <CardContent>
              <Typography variant="body2" className={styles.freeTrialFontSize} style={{ fontSize: '18px' }}>
                The free trial period, which ends on {this.props.subscription.freeTrial.monthOfEnd}{' '}
                {this.props.subscription.freeTrial.dateOfEnd}, is currently in use.
              </Typography>
            </CardContent>
            <Tooltip title={`After that $${this.props.subscription.plan.amount / 100} per user`}>
              <IconButton>
                <HelpOutlineIcon style={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
          </Card>
        </div>
    );

    const invalidSubscriptionPage =
        this.props.subscription?.status !== 'trialing' &&
        this.props.subscription?.status !== 'active' && (
            <div className={styles.contentBillingInfo}>
              <div className={styles.billingInfoDetails}>
                Your subscription's status is invalid.
                <br/>
                Users in your company won't be able to log in.
                <br/>
                Please contact us to resolve the issue:
              </div>
              <br/>
              <b>info@useneatly.com</b>
            </div>
        );

    return (
        <>
          <Loader isLoading={this.state.isLoading} isBackground={true} />
          {this.props.subscription ? (
              <div>
                {subscriptionPage}
                {freeTrialPage}
                {invalidSubscriptionPage}
              </div>
          ) : (
              <NoBillingInfo onSubscribeClick={this.handleSubscribeToStripe} />
          )}
        </>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  const invoices = getInvoices(state.paymentsState);
  return {
    activePlan: state.paymentsState.activePlan,
    card: state.paymentsState.card,
    countInvoices: invoices ? invoices.length : 0,
    invoices,
    isCardCreatingFailed: state.paymentsState.isCardCreatingFailed,
    modalWindows: state.modalWindowState.modalWindows,
    remoteCountInvoices: state.paymentsState.countInvoices,
    subscription: state.auth.profile?.subscription
  };
};

const actions = {
  clearCreatingCardFailed,
  closeModalWindow,
  fetchCard,
  fetchInvoice,
  openModalWindow,
  subscribeToStripe,
  upgradePlanWithoutCard
};

export default compose(withRouter, connect(mapStateToProps, actions))(BillingInfo);
