import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import styles from '@components/banner/styles.m.scss';

interface IReduxProps {
  companyCreateDate: Date;
  subscriptionStatus: string;
}

const Banner = (props: IReduxProps) => {
  useEffect(() => {
    const daysPassedFromCreation = Math.floor(
        (new Date().getTime() - new Date(props.companyCreateDate).getTime()) /
        (1000 * 60 * 60 * 24)
    );

    if (props.subscriptionStatus === 'active') {
      return;
    }
    let message = '';
    let freeTrialDaysLeft = -1;

    if (props.companyCreateDate) {
      freeTrialDaysLeft = 14 - daysPassedFromCreation;
    }

    if (freeTrialDaysLeft > 0) {
      message = `${Math.ceil(freeTrialDaysLeft)} days of free trial left.`;
    } else {
      message = `Your free trial has ended. Only the owner is able to login. Please go to Settings -> Billing Info to update your subscription.`;
    }

    const backgroundColor = freeTrialDaysLeft < 0 ? '#ff4d4d' : '#83acf8';

    toast.custom((t) => (
        <div className={styles.toastBanner} style={{ width: '100%', backgroundColor }}>
          <span className={styles.message}>{message}</span>
          <button onClick={() => toast.remove(t.id)} className={styles.toastCloseBtn}>
            ✕
          </button>
        </div>
    ), {
      duration: Infinity,
    });
  }, [props.subscriptionStatus, props.companyCreateDate]);

  return <Toaster position="top-center" />;
};

const mapStateToProps = (state: IStore) => ({
  companyCreateDate: state.auth.profile.company.createdAt,
  subscriptionStatus: state.auth.profile.subscription?.status,
});

export default connect(mapStateToProps)(Banner);
