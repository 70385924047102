import React, { useState, createRef, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { inviteUsers, clearInviteError } from '@redux/reducers/auth/actions';
import { getUsersArray } from '@redux/reducers/auth';
import readXlsxFile from 'read-excel-file';
import {
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField,
  withStyles,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';
import TooltipSet from '@components/tooltips';
import AllCategories from '@components/modal-all-categories';
import { UpgradeModalWindows, PaymentDetailsModalWindows } from '@components/modal-window/modalWindowsTypes';
import { isModalWindowOpen } from '@services/modal-windows';
import { isEmailValidate } from '@utils/validations';
import { useStylesCheckbox } from '@styles/checkbox';
import classNames from 'classnames';

import Upload from '@assets/svg/upload.svg?tag';
import Close from '@assets/svg/close.svg?tag';
import CloseOptinal from '@assets/svg/closeOptinal.svg?tag';
import styles from './styles.m.scss';
import Loader from '@components/loader';
import Plausible from 'plausible-tracker';

const MenuInviteItem = withStyles(() => ({
  root: {
    borderRadius: '3px',
    color: '#757575',
    fontSize: '14px',
    margin: '0 8px',
    minHeight: 'unset',
    padding: '7px 15px',
    width: '145px'
  }
}))(MenuItem);

const EmailErrors = Object.freeze({
  emailIsInvalid: 'Email is not valid',
  emailIsNotUnique: 'Email has to be unique',
  userUsesNeatly: 'Can\'t invite user who already uses Neatly',
});

type UploadEmailsError = 'invalid' | 'no-unique' | 'file-extension' | 'reading-file';
type InvitingType = 'by-input' | 'upload';

interface IOwnProps {
  open: boolean;
  setOpen: (value?: boolean) => void;
  modalWindows: any;
}

interface IReduxProps {
  categories: ICategory[];
  projects: ICategory[];
  inviteUsers: typeof inviteUsers;
  count: number;
  users: IUser[];
  inviteError: string;
  clearInviteError: typeof clearInviteError;
  isLoadingInvite: boolean;
}

interface IProps extends IOwnProps, IReduxProps { }

const InviteNewUser = (props: IProps) => {
  const { open, setOpen, categories, projects, modalWindows, users,  inviteError, isLoadingInvite} = props;
  const [invitePermission, setInvitePermission] = useState<UserRoleType>('user');
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState<string[]>([]);
  const [inputEmail, setInputEmail] = useState<string>('');
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [errorEmailMessage, setErrorEmailMessage] = useState<string>('');
  const [isAllCategoriesOpen, setAllCategoriesOpen] = useState<boolean>(false);
  const [invitingType, setInvitingType] = useState<InvitingType>('by-input');
  const userListInputRef = createRef<HTMLInputElement>();
  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  useEffect(() => {
    if (inviteError === 'USER_EXISTS' || inviteError === 'USERS_INVITED') {
      setErrorEmailMessage(EmailErrors.userUsesNeatly);
    }
  }, [inviteError]);

  const handleChangeInvitePermission = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setInvitePermission(event.target.value as UserRoleType);
  };

  const categoriesAndProjects = categories.concat(projects);

  // type any is necessary for getting value
  const handleChangeCategories = (event: any, checked: boolean) => {
    if (checked) {
      setSelectedCategoriesIds(selectedCategoriesIds.concat([event.currentTarget.value]));
    } else {
      setSelectedCategoriesIds(selectedCategoriesIds.filter((id) => id !== event.currentTarget.value));
    }
  };

  const inviteNewUsers = () => {
    plausible.trackEvent('Invite Users', {
      props: {
        level: 'company'
      }
    });
    if (invitingType !== 'by-input') {
      setInvitingType('by-input');
    }
    const lowerCaseEmails = selectedEmails?.map((item) => ({ email: item.toLowerCase() }));
    props.inviteUsers(lowerCaseEmails, selectedCategoriesIds, invitePermission);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (errorEmailMessage) {
      if (errorEmailMessage === EmailErrors.userUsesNeatly) {
        props.clearInviteError();
      }
      setErrorEmailMessage('');
    }
    if (value.charAt(value.length - 1) === ' ') {
      addSelectedEmails();
    } else {
      setInputEmail(value);
    }
  };

  const isEmailNotUnique = (email: string) =>
    users && users.find((user) => user.data ? user.data.email === email : user.email === email);

  const isEmailsByInputNotUnique = (email: string) => selectedEmails.find((selectesEmail) => email === selectesEmail)
    || isEmailNotUnique(email);

  const isEmailsByUploadNotUnique = (email: string, uploadEmails: string[]) =>
    uploadEmails.find((uploadEmail) => email === uploadEmail) || isEmailNotUnique(email);

  const addSelectedEmails = () => {
    if (inputEmail.length) {
      const email = inputEmail.trim();
      if (!isEmailValidate(email)) {
        setErrorEmailMessage(EmailErrors.emailIsInvalid);
      } else if (isEmailsByInputNotUnique(email)) {
        setErrorEmailMessage(EmailErrors.emailIsNotUnique);
      } else {
        setSelectedEmails(selectedEmails.concat([email]));
        setInputEmail('');
      }
    }
  };

  const removeEmailFromSelected = (email: string) => {
    setSelectedEmails(selectedEmails.filter((value) => value !== email));
  };

  const onKeyPressEmail = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && inputEmail.length > 0) {
      addSelectedEmails();
    }
  };

  const setUploadError = (email: string, errorType: UploadEmailsError) => {
    const finalEmail = email.length > 25 ? email.slice(0, 25) + '...' : email;
    switch (errorType) {
      case 'invalid': setErrorEmailMessage(`${finalEmail} is not valid`); break;
      case 'no-unique': setErrorEmailMessage(`${finalEmail} is not unique`); break;
      case 'file-extension': setErrorEmailMessage('Supported only Excel files'); break;
      case 'reading-file': setErrorEmailMessage('Cannot read the file'); break;
    }
  };

  const onSaveCategories = (categoriesIds: string[]) => {
    setSelectedCategoriesIds(categoriesIds);
    setAllCategoriesOpen(false);
  };

  const isValidFileExtensions = (file: File) => /(\.csv|\.txt|\.xlsx|\.xls)$/i.exec(file?.name);

  const validateEmails = (emails: string[]) => {
    const emailsSet = new Set(emails);
    emailsSet.forEach((email) => {
      email.toLowerCase();
      if (!isEmailValidate(email)) {
        setUploadError(email, 'invalid');
      }
    });
    return Array.from(emailsSet).map((e) => ({
      email: e.toLowerCase()
    }));
  };

  const processData = (dataString: string): string[] => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list: string[] = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj: string[] = [];
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') {
              d = d.substring(1, d.length - 1);
            }
            if (d[d.length - 1] === '"') {
              d = d.substring(d.length - 2, 1);
            }
          }
          if (headers[j]) {
            obj.push(d);
          }
        }

        if (obj.filter((x) => x).length > 0) {
          list.push(...obj.filter((x) => x));
        }
      }
    }
    return list;
  };

  const processXLSXFile = (evt: ProgressEvent<FileReader>) => {
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    const wsName = wb.SheetNames[0];
    const ws = wb.Sheets[wsName];
    const data = XLSX.utils.sheet_to_csv(ws);
    const emails = processData(data);
    props.inviteUsers(validateEmails(emails), [], 'user');
  };

  const processTextFile = (evt: ProgressEvent<FileReader>) => {
    const contents = evt.target.result as string;
    const emails: string[] = contents.split(/\r?\n/);
    props.inviteUsers(validateEmails(emails), [], 'user');
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (!isValidFileExtensions(file)) {
      setUploadError('', 'file-extension');
      return;
    }

    const reader = new FileReader();

    if (errorEmailMessage) {
      setErrorEmailMessage('');
    }

    if (file.type === 'text/plain') {
      reader.onload = (evt) => processXLSXFile(evt);
      reader.readAsText(file);
    } else {
      reader.onload = (evt) => processTextFile(evt);
      reader.readAsBinaryString(file);
    }
  };

  const checkboxCategory = (categoryId: string) => (
    <Checkbox
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      className={classes.root}
      value={categoryId}
      color="primary"
    />
  );

  const isAnotherModalOpen = isModalWindowOpen(modalWindows, UpgradeModalWindows.confirmModal, 'upgrade')
    || isModalWindowOpen(modalWindows, PaymentDetailsModalWindows.editPaymentDetailsModal, 'payment');

  const classes = useStylesCheckbox({});

  const menuPropsSelectPermission: Partial<MenuProps> = {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    },
    getContentAnchorEl: null
  };

  const categoryCheckboxes =
    categoriesAndProjects &&
    categoriesAndProjects
      .slice(0, 2)
      .map((item, index) => (
        <FormControlLabel
          key={index}
          className={styles.formControlCategoryCheckbox}
          checked={selectedCategoriesIds.includes(item.id)}
          onChange={handleChangeCategories}
          control={checkboxCategory(item.id)}
          label={item.name}
        />
      ));

  const moreLabel = categoriesAndProjects?.length > 2 && (
    <Button
      className={classNames(styles.moreLabel)}
      onClick={() => setAllCategoriesOpen(true)}
    >
      More...
    </Button>
  );

  const emailOptionals = selectedEmails &&
    selectedEmails.map((email, index) => {

      const optinalEmail = (
        <div className={styles.optinalEmailBox} key={index}>
          <p className={styles.optinalEmailLabel}>{email}</p>
          <CloseOptinal
            className={styles.closeOptinal}
            onClick={() => removeEmailFromSelected(email)}
          />
        </div>
      );

      return optinalEmail;
    });

  const placeholderEmail = selectedEmails && selectedEmails.length
    ? ''
    : 'Enter names or email addresses...';

  return (
      <>
        <Loader isLoading={isLoadingInvite && open}/>
        <Modal
            className={styles.modalInvite}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition={true}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
          <Fade in={open && !isAnotherModalOpen}>
            <div className={classNames(styles.paperInvite, styles.fadeIn)}>
              <h2 className={styles.inviteTitle}>Invite a new user</h2>
              <div className={styles.contentInviteUser}>
                <p className={styles.labelPeople}>People</p>
                <TooltipSet
                    message={errorEmailMessage}
                    isOpen={!!errorEmailMessage}
                    type="error"
                    isModal={true}
                    placement={invitingType === 'upload' ? 'top-end' : 'bottom-start'}
                >
                  <div className={styles.inputEmailsBox}>
                    <Scrollbars
                        autoHide={true}
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                    >
                      {emailOptionals}
                      <TextField
                          className={styles.inputEmails}
                          value={inputEmail}
                          onChange={handleChangeEmail}
                          placeholder={placeholderEmail}
                          onKeyPress={onKeyPressEmail}
                          onBlur={addSelectedEmails}
                          autoFocus={true}
                      />
                    </Scrollbars>
                    <Select
                        className={styles.selectPermission}
                        value={invitePermission}
                        onChange={handleChangeInvitePermission}
                        MenuProps={menuPropsSelectPermission}
                    >
                      <MenuInviteItem value={'moderator'}>
                        View &amp; edit
                      </MenuInviteItem>
                      <MenuInviteItem value={'user'}>View</MenuInviteItem>
                    </Select>
                  </div>
                </TooltipSet>
                <div className={styles.checkboxCategoriesBox}>
                  {categoryCheckboxes}
                  {moreLabel}
                </div>
                <footer className={styles.inviteBtnBox}>
                  <Button
                      variant="contained"
                      className={classNames(
                          styles.inviteBtn,
                          !selectedEmails.length && styles.disabledBtnInvite
                      )}
                      onClick={inviteNewUsers}
                      disabled={!selectedEmails.length}
                  >
                    Invite
                  </Button>
                </footer>
              </div>
              <input
                  id="file"
                  type="file"
                  accept=".csv, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref={userListInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileUpload(e)}
              />
              <Button className={styles.closeBtn} onClick={() => setOpen(false)}>
                <Close />
              </Button>
              <AllCategories
                  open={isAllCategoriesOpen}
                  description={'inviting'}
                  categoriesIds={selectedCategoriesIds}
                  setOpen={setAllCategoriesOpen}
                  onSaveCategories={onSaveCategories}
              />
            </div>
          </Fade>
        </Modal>
      </>
  );
};

const mapStateToProps = (state: IStore) => {
  const users = getUsersArray(state.auth);
  return {
    categories: state.categoriesState.categories,
    count: state.auth.users.count,
    inviteError: state.auth.inviteError,
    isLoadingInvite: state.auth.isLoadingInvite,
    projects: state.projectState.projects,
    users,
  };
};

const actions = {
  clearInviteError,
  inviteUsers,
};

export default connect(mapStateToProps, actions)(InviteNewUser);
