import axios from 'axios';
import { Store, Dispatch, Middleware } from 'redux';
import Types from '../types';
import CategoryTypes from '@redux/reducers/categories/types';
import AuthTypes from '@redux/reducers/auth/types';
import CategoriesTypes from '@redux/reducers/categories/types';
import ModalWindowsTypes from '@redux/reducers/modal-window/types';
import { CategoriesModalWindows } from '@components/modal-window/modalWindowsTypes';
import configUrls from '../../config';

interface IConfig {
  data?: object;
  headers: object;
}

const baseUrl = configUrls.apiURL;

const middleware = (store: Store<IStore>) => (next: Dispatch) => async (
  action: IAction<any>
) => {
  next(action);

  if (action.type === Types.API_REQUEST && action.meta) {
    const { token } = store.getState().auth;
    const config: IConfig = token
      ? {
          headers: { Authorization: 'Bearer ' + token }
        }
      : null;
    const { method, url, onSuccess, onError } = action.meta;
    const fullUrl = baseUrl + url;

    try {
      let result = null;
      switch (method) {
        case 'get':
          result = await axios.get(fullUrl, config);
          break;
        case 'post':
          result = await axios.post(fullUrl, action.payload, config);
          break;
        case 'delete':
          if (config) {
            config.data = action.payload;
          }
          result = await axios.delete(fullUrl, config);
          break;
        case 'put':
          result = await axios.put(fullUrl, action.payload, config);
          break;
      }

      if (result) {
        if (result.data?.redirect_url) {
          window.location.href = result.data.redirect_url;
        }
        store.dispatch({
          payload: result.data,
          type: onSuccess
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        store.dispatch({
          payload: error.message,
          type: Types.API_UNAUTHORIZED
        });
      } else if (
        error.response.status === 403 &&
        error.response.data.message === 'NOT_ACTIVE'
      ) {
        store.dispatch({
          payload: error.message,
          type: Types.API_DEACTIVATED
        });
      } else if (
        error.response.status === 403 &&
        (error.response.data.message === 'USER_EXISTS' ||
          error.response.data.message === 'USERS_INVITED')
      ) {
        store.dispatch({
          payload: error.response.data.message,
          type: onError
        });
      } else if (
        error.response.status === 403 &&
        error.response.data.message === 'HAS_OPEN_INVOICES'
      ) {
        store.dispatch({
          payload: error.response.data.message,
          type: onError
        });
      } else if (
        error.response.status === 404 &&
        error.response.data.message === 'CATEGORY_NOT_FOUND'
      ) {
        store.dispatch({
          payload: error.response.data.message,
          type: onError
        });
        store.dispatch({
          payload: undefined,
          type: CategoryTypes.FETCH_CATEGORIES_REQUEST
        });
      } else if (
        error.response.status === 403 &&
        error.response.data.message === 'DO_NOT_HAVE_CREATE_CATEGORY_PERMISSION'
      ) {
        store.dispatch({
          payload: error.response.data.message,
          type: onError
        });
        store.dispatch({
          payload: undefined,
          type: AuthTypes.PROFILE_REQUEST
        });
        store.dispatch({
          payload: undefined,
          type: CategoriesTypes.FETCH_CATEGORIES_REQUEST
        });
        store.dispatch({
          payload: {
            id: 'category',
            keyName: CategoriesModalWindows.errorModal
          },
          type: ModalWindowsTypes.OPEN_MODAL_WINDOW
        });
      } else {
        store.dispatch({
          payload: error.message,
          type: onError
        });
      }
    }
  }
};

export default middleware as Middleware;
