import Types from './types';

const initialState: IWizardState = {
  activeStep: -1,
  isWizardActive: false,
  wizardSteps: [],
};

export default (state: IWizardState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.ADD_WIZARD_STEP_REQUEST:
      const newState = {...state};
      newState.wizardSteps[action.payload.step] = action.payload;
      return newState;

    case Types.SET_WIZARD_ACTIVE_STEP_REQUEST:
      return {...state, activeStep: action.payload};

    case Types.SET_WIZARD_IS_ACTIVE:
      return {...state, isWizardActive: action.payload, activeStep: 0};
  }
  return state;
};
