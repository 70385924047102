import {
  Card, Typography, CardContent, CardActionArea
} from '@material-ui/core';
import {
  createFromDrive,
  fetchFiles,
  getFilesFeed
} from '@redux/reducers/files/actions';
import classNames from 'classnames';
import React, { createRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTempSections } from '@redux/reducers/sections/actions';
import { getFilesBySectionId } from '@redux/reducers/files';
import { getNewFilesFeedAmount, deleteFiles } from '@redux/reducers/files/actions';
import CloseOptinal from '@assets/svg/closeOptinal.svg?tag';
import styles from './styles.m.scss';
import Plausible from 'plausible-tracker';
import {PopupModal} from 'react-calendly';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface IReduxProps {
  isLoading: boolean;
  filesFeed: IFilesFeed;
  groups: IFile[];
  sections: ISection[];
  categories: ICategory[];
  projects: ICategory[];
  fetchFiles: typeof fetchFiles;
  getFilesFeed: typeof getFilesFeed;
  getNewFilesFeedAmount: typeof getNewFilesFeedAmount;
  createFromDrive: typeof createFromDrive;
  fetchTempSections: typeof fetchTempSections;
  deleteFiles: typeof deleteFiles;
}

// tslint:disable-next-line:no-empty-interface
interface IOwnProps {}

interface IProps extends IReduxProps, IOwnProps {}

const NeedHelp = (props: IProps) => {
  const [isNeedHelp, setIsNeedHelp] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenChooseMethod, setIsOpenChooseMethod] = useState<boolean>(false);

  const plausible = Plausible({
    domain: `${process.env.DOMAIN}`
  });

  const handleRemoveHelpForUser = () => {
    localStorage.setItem('needHelp', String(false));
    setIsNeedHelp(false);
    plausible.trackEvent('Need Help', {
      props: {
        type: 'close'
      }
    });
  };

  const handleOpenChooseModal = () => {
    setIsOpen(true);
    plausible.trackEvent('Need Help', {
      props: {
        type: 'proceed'
      }
    });
  };

  useEffect(() => {
    const needHelp = localStorage.getItem('needHelp');
    if (needHelp === 'false') {
      setIsNeedHelp(false);
    }
  }, []);

  return (
      <div>
        {isNeedHelp && (
            <div className={styles.feedWrap}>
              {isOpenChooseMethod && (
                  <PopupModal
                      url="https://calendly.com/useneatly/video-call"
                      onModalClose={() => setIsOpenChooseMethod(false)}
                      open={true}
                      rootElement={document.getElementById('root')}
                  />
              )}
              {isOpen ? (
                  <Card className={classNames(styles.options)}>
                    <CloseOptinal
                        className={styles.closeOptinal}
                        onClick={handleRemoveHelpForUser}
                    />
                    <CardContent className={classNames(styles.content)} component="p">
                      <span className={styles.orText}>Choose a method:</span>
                      <button
                          className={classNames(styles.optionButton)}
                          onClick={() => setIsOpenChooseMethod(true)}
                      >
                        <VideocamOutlinedIcon className={classNames(styles.icon)} />
                        Schedule a call
                      </button>
                      <span>OR</span>
                      <button
                          className={classNames(styles.optionButton)}
                          onClick={(e) => {
                            const subject = encodeURIComponent('Need Help With Setting Up Neatly');
                            const body = encodeURIComponent('Hello Neatly team,\n\nI would like to ...\n\nThank you!');
                            window.location.href = `mailto:info@useneatly.com?subject=${subject}&body=${body}`;
                          }}
                      >
                        <EmailOutlinedIcon className={classNames(styles.icon)} />
                        Send us an email
                      </button>
                    </CardContent>
                  </Card>
              ) : (
                  <Card className={classNames(styles.drawer)}>
                    <CloseOptinal
                        className={styles.closeOptinal}
                        onClick={handleRemoveHelpForUser}
                    />
                    <CardActionArea
                        onClick={handleOpenChooseModal}
                        className={classNames(styles.cardActionArea)}
                    >
                      <CardContent>
                        <Typography gutterBottom={true} variant="h5" component="h2">
                          Need Some Help?
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          We can help you with tailoring Neatly so that it fits your use-case perfectly!
                          <br />
                          <br />
                          Contact us today
                          <ArrowForwardIcon className={classNames(styles.icon)} />
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
              )}
            </div>
        )}
      </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  categories: state.categoriesState.categories,
  filesFeed: state.filesState.filesFeed,
  groups:
    state.sectionsState.tempSections && state.sectionsState.tempSections.length
      ? getFilesBySectionId(
          state.filesState,
          state.sectionsState.tempSections[0].id
        )
      : null,
  isLoading: state.filesState.isLoadingCreating,
  projects: state.projectState.projects,
  sections: state.sectionsState.tempSections
});

const actions = {
  createFromDrive,
  deleteFiles,
  fetchFiles,
  fetchTempSections,
  getFilesFeed,
  getNewFilesFeedAmount,
};

export default connect(mapStateToProps, actions)(NeedHelp);
