import Types from './types';

export const fetchProjects = () => ({
  type: Types.FETCH_PROJECTS_REQUEST
});

export const createProject = (name: string, actionId?: string) => ({
  payload: { name, actionId },
  type: Types.CREATE_PROJECT_REQUEST
});

export const updateProject = (id: string, name: string) => ({
  payload: { id, name },
  type: Types.UPDATE_PROJECT_REQUEST
});

export const deleteProject = (id: string) => ({
  payload: id,
  type: Types.DELETE_PROJECT_REQUEST
});

export const toggleNotificationsProject = (id: string) => ({
  payload: id,
  type: Types.NOTIFICATIONS_PROJECT_REQUEST
});

export const setActiveProject = (activeProject: ICategory) => ({
  payload: activeProject,
  type: Types.SET_ACTIVE_PROJECT
});

export const syncProjectWithDriveDirectory = (categoryId: string, directoryId: string) => ({
  payload: { categoryId, directoryId },
  type: Types.SYNC_PROJECT_REQUEST
});

export const convertProject = (id: string, name: string, users: Array<{ email: string; role: string; }>) => ({
  payload: { id, name, users },
  type: Types.CONVERT_PROJECT_REQUEST
});

export const fetchProjectUsers = (id: string) => ({
  payload: { id },
  type: Types.FETCH_PROJECT_USERS_REQUEST
});
