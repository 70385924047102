import React from 'react';
import { connect } from 'react-redux';
import { fetchInvoice } from '@redux/reducers/payments​/actions';
import {
  setAddFileNotification,
  addNotification,
  readNotification,
  changePermissionNotification,
  removePermissionNotification
} from '@redux/reducers/notifications/actions';
import { clearFilesAfterNotifications } from '@redux/reducers/files/actions';
import socketIOClient from 'socket.io-client';
import config from '../../config';

interface IOwnProps {
  children?: any;
}

interface IReduxProps {
  token: string;
  categories: ICategory[];
  files: {
    [sectionId: string]: {
      [fileId: string]: IFile;
    };
  };
  filesInGroup: {
    [groupId: string]: {
      [fileId: string]: IFile;
    };
  };
  notifications: {
    [notificationId: string]: INotification;
  };
  isSocketsReady: boolean;
  fetchInvoice: typeof fetchInvoice;
  setAddFileNotification: typeof setAddFileNotification;
  changePermissionNotification: typeof changePermissionNotification;
  removePermissionNotification: typeof removePermissionNotification;
  readNotification: typeof readNotification;
  addNotification: typeof addNotification;
  clearFilesAfterNotifications: typeof clearFilesAfterNotifications;
}

interface IProps extends IOwnProps, IReduxProps { }

class Sockets extends React.Component<IProps> {
  setSockets = () => {
    const socket = socketIOClient(config.uriSocketClient, { query: { token: this.props.token } });
    socket.on('invoice', this.getNewInvoice);
    socket.on('payment_failed', this.getPaymentFailedNotification);
    if (this.props?.isSocketsReady) {
      this.props.categories?.map((category) => {
        socket.on(category.id, (event: INotification) => {
          switch (event.messageType) {
            case 'new_file' || 'file_deleted': {
              this.getFileNotification(event, category);
              return;
            }
            case 'permission_changed': {
              this.getPermissionNotification(event, category);
              return;
            }
          }
        });
      });
    }
  };

  getNewInvoice = (_event: INotification) => {
    this.props.fetchInvoice();
  };

  getPaymentFailedNotification = (event: INotification) => {
    if (event && this.props.notifications && !this.props.notifications[event.id]) {
      this.props.addNotification(
        event,
        'Monthly invoice for "Neatly" subscription failed'
      );
    }
  };

  getNewFileNotification = (event: INotification, category: ICategory) => {
    const { files, filesInGroup } = this.props;
    const filesData: IFileNotificationData = JSON.parse(event.message as string);
    if ((filesData.sectionId && !files[filesData.sectionId])
      || (filesData.groupId && !filesInGroup[filesData.groupId])) {
      this.props.setAddFileNotification(event, category, false);
    } else if (files[filesData.sectionId] && files[filesData.sectionId][filesData.fileId]) {
      this.props.addNotification(event, `${event.displayName} has added ${files[filesData.sectionId][filesData.fileId].name} to ${category.name}`);
    } else if (filesData.groupId && filesInGroup[filesData.groupId]
      && filesInGroup[filesData.groupId][filesData.fileId]) {
      this.props.addNotification(event, `${event.displayName} has added ${filesInGroup[filesData.groupId][filesData.fileId].name} to ${category.name}`);
    } else {
      this.props.setAddFileNotification(event, category, true);
    }
  };

  getDeleteFileNotification = (event: INotification, category: ICategory) => {
    const { files, filesInGroup } = this.props;
    const filesData: IFileNotificationData = JSON.parse(event.message as string);
    if (filesData.groupId
      && filesData.filesIds[0] === filesData.groupId && files[filesData.sectionId]
      && files[filesData.sectionId][filesData.groupId]) {
      this.props.addNotification(event, `${event.displayName} has deleted ${files[filesData.sectionId][filesData.groupId].name} from ${category.name}`);
      this.props.clearFilesAfterNotifications(filesData.sectionId, '', [filesData.groupId]);
    } else if (!files[filesData.sectionId] || (filesData.groupId && !filesInGroup[filesData.groupId])) {
      this.props.readNotification(event.id);
    } else if (files[filesData.sectionId][filesData.filesIds[0]]
      || filesData.groupId && filesInGroup[filesData.groupId][filesData.filesIds[0]]) {
      let filesNames = '';
      filesData.filesIds.map((fileId) => {
        filesData.groupId
          ? filesNames += ` ${filesInGroup[filesData.groupId][fileId].name},`
          : filesNames += ` ${files[filesData.sectionId][fileId].name},`;
      });
      this.props.addNotification(event, `${event.displayName} has deleted ${filesNames.slice(0, -1)} from ${category.name}`);
      this.props.clearFilesAfterNotifications(filesData.sectionId, filesData.groupId, filesData.filesIds);
    } else {
      this.props.readNotification(event.id);
    }
  };

  getFileNotification = (event: INotification, category: ICategory) => {
    const filesData: IFileNotificationData = JSON.parse(event.message as string);
    if (event && this.props.notifications && !this.props.notifications[event.id] && filesData) {
      if (event.messageType === 'new_file') {
        this.getNewFileNotification(event, category);
      } else if (event.messageType === 'file_deleted') {
        this.getDeleteFileNotification(event, category);
      }
    }
  };

  getPermissionNotification = (event: INotification, category: ICategory) => {
    if (event.message && event.displayName) {
      // changing category permissions for user (i.e. from moderator to user)
      this.props.changePermissionNotification(event, category);
      this.props.addNotification(event, `Your permissions for category ${category.name} were changed to ${event.message} by ${event.displayName}`);
    } else {
      // removing category permissions for user
      this.props.removePermissionNotification(event, category);
      this.props.addNotification(event, `Your permissions for category ${category.name} were removed`);
    }
  };

  render() {
    this.setSockets();
    return <>{this.props.children}</>;
  }
}

const actions = {
  addNotification,
  changePermissionNotification,
  clearFilesAfterNotifications,
  fetchInvoice,
  readNotification,
  removePermissionNotification,
  setAddFileNotification,
};

const mapStateToProps = (state: IStore) => ({
  categories: state.categoriesState.categories,
  files: state.filesState.files,
  filesInGroup: state.filesState.filesInGroup,
  isLoadingOnFetch: state.categoriesState.isLoadingOnFetch,
  isSocketsReady: state.filesState.isSocketsReady,
  notifications: state.notificationsState.notifications,
  token: state.auth.token,
});

export default connect(mapStateToProps, actions)(Sockets);
