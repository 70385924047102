import Types from './types';
import CategoryTypes from '../categories/types';
import toast from 'react-hot-toast';
import {dispatch} from '@services/store';

interface IFilesFetchResponse {
  count: number;
  page: IFile[];
  sectionId: string;
}

const initialState: IFilesState = {
  activeFile: null,
  activeGroup: null,
  activeIds: null,
  counts: {},
  errors: [],
  file: null,
  fileTagsLoader: false,
  files: {},
  filesFeed: null,
  filesInGroup: {},
  isLoading: false,
  isLoadingCopyShareLink: false,
  isLoadingCreating: false,
  isLoadingOnFetch: false,
  isPaginationFetching: false,
  isPreviewAllowed: false,
  isSelectFromDrive: false,
  isSocketsReady: false,
  newFileId: '',
  shareError: '',
  tags: [],
};

export default (state: IFilesState = initialState, action: IAction) => {
  switch (action.type) {
    case Types.SET_ACTIVE_IDS: {
      const newState = {
        ...state
      };
      if (action.payload.sectionId) {
        newState.activeIds = action.payload;
        newState.activeFile = action.payload.groupId
          ? state.filesInGroup[action.payload.groupId][
              action.payload.filesIds[0]
            ]
          : state.files[action.payload.sectionId][action.payload.filesIds[0]];
      } else {
        newState.activeIds = null;
      }
      return newState;
    }

    case Types.FETCH_FILES_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: true,
        isPaginationFetching: !!action.payload.offset
      };
    }

    case Types.UPDATE_FILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingOnFetch: false
      };
    }

    case CategoryTypes.GET_CATEGORY_SHARABLE_LINK_SUCCESS:
    case Types.MAKE_SHARABLE_WITH_LINK_SUCCESS: {
      return {
        ...state,
        isLoadingCopyShareLink: false
      };
    }

    case Types.MAKE_SHARABLE_WITH_LINK_REQUEST: {
      return {
        ...state,
        isLoadingCopyShareLink: true
      };
    }

    case CategoryTypes.GET_CATEGORY_SHARABLE_LINK: {
      return {
        ...state,
        isLoadingCopyShareLink: true
      };
    }

    case CategoryTypes.GET_CATEGORY_SHARABLE_LINK_ERROR:
    case Types.MAKE_SHARABLE_WITH_LINK_ERROR: {
      return {
        ...state,
        isLoadingCopyShareLink: false
      };
    }

    case Types.UPDATE_FILE_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const file = action.payload.file as IFile;
      file.name = action.payload.name;
      file.tags = action.payload.tags;

      newState.activeFile = file;

      if (file) {
        if (file.groupId) {
          newState.filesInGroup[file.groupId] = {
            ...newState.filesInGroup[file.groupId],
            [file.id]: file
          };
        } else {
          newState.files[file.sectionId] = {
            ...newState.files[file.sectionId],
            [file.id]: file
          };
          if (newState.activeGroup && newState.activeGroup.id === file.id) {
            newState.activeGroup = file;
          }
        }
      }

      return newState;
    }

    case Types.GET_FILE_FROM_NOTIFICATION_REQUEST:
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false
      };
    case Types.CREATE_BY_DRIVE_REQUEST:
    case Types.CREATE_DOC_FILE_REQUEST:
    case Types.CREATE_BY_URL_REQUEST:
    case Types.OPEN_FILE_FROM_DRIVE_REQUEST:
    case Types.UPLOAD_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: true,
        isLoadingOnFetch: false
      };
    }

    case Types.GET_FILE_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false
      };
    }

    case Types.ADJUST_FILE_PERMISSIONS_REQUEST: {
      return {
        ...state,
      };
    }

    case Types.ADJUST_FILE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        isPreviewAllowed: true
      };
    }

    case Types.CLEAR_PREVIEW_ALLOWED: {
      return {
        ...state,
        isPreviewAllowed: false
      };
    }

    case Types.CHANGE_TO_GROUP_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: true,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false
      } as IFilesState;
    }

    case Types.DELETE_FILE_REQUEST: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: true,
        isLoadingOnFetch: false
      };

      if (action.payload.sectionId) {
        newState.activeIds = action.payload;
      }

      return newState;
    }

    case Types.GET_FILE_FROM_NOTIFICATION_ERROR:
    case Types.FILES_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false,
        isPaginationFetching: false
      } as IFilesState;
    }

    case Types.INVITE_FILE_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingCopyShareLink: false,
        shareError: action.payload
      } as IFilesState;
    }

    case Types.GET_FILE_ERROR: {
      const newState: IFilesState = {
        ...state,
        errors: [action.payload],
        file: null,
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false
      };

      return newState;
    }

    case Types.UPDATE_FILE_ERROR: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false
      };

      const file = newState.activeFile;

      if (file) {
        if (file.groupId) {
          newState.filesInGroup[file.groupId] = {
            ...newState.filesInGroup[file.groupId],
            [file.id]: file
          };
        } else {
          newState.files[file.sectionId] = {
            ...newState.files[file.sectionId],
            [file.id]: file
          };
          if (newState.activeGroup && newState.activeGroup.id === file.id) {
            newState.activeGroup = file;
          }
        }
      }

      return newState;
    }

    case Types.GET_FILE_TAGS_ERROR:
    case Types.GET_FILE_TAGS_SUCCESS: {
      const newState = {
        ...state,
        fileTagsLoader: false
      };

      const response = action.payload as IFileTag[];
      if (!response) { return newState; }

      newState.tags = response;
      return newState;
    }

    case Types.DELETE_FILE_TAGS_SUCCESS:
    case Types.DELETE_FILE_TAGS_ERROR:
    case Types.ADD_NEW_FILE_TAG_ERROR:
    case Types.ADD_NEW_FILE_TAG_SUCCESS: {
      return {
        ...state,
        fileTagsLoader: false
      };
    }

    case Types.DELETE_FILE_TAGS_REQUEST:
    case Types.GET_FILE_TAGS_REQUEST: {
      return {
        ...state,
        fileTagsLoader: true
      };
    }

    case Types.FETCH_FILES_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        isLoadingOnFetch: false
      };

      const response = action.payload as IFilesFetchResponse;
      if (response && response.count) {
        const files = response.page;
        if (files[0].groupId) {
          newState.activeGroup =
            newState.files[response.sectionId][files[0].groupId];
          if (!newState.isPaginationFetching) {
            newState.filesInGroup[files[0].groupId] = {};
          }
          files.forEach((f) => {
            newState.filesInGroup[f.groupId]
              ? (newState.filesInGroup[f.groupId][f.id] = f)
              : (newState.filesInGroup[f.groupId] = { [f.id]: f });
          });
        } else {
          if (!newState.isPaginationFetching) {
            newState.files[response.sectionId] = {};
          }
          newState.counts[response.sectionId] = response.count;
          newState.files[response.sectionId] = {};
          files.forEach((f) => {
              newState.files[f.sectionId][f.id] = f;
          });
        }
      } else {
        newState.files[response.sectionId] = {};
      }
      if (newState.isPaginationFetching) {
        newState.isPaginationFetching = false;
      }
      newState.isSocketsReady = true;
      return newState;
    }

    case Types.OPEN_FILE_FROM_DRIVE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        file: action.payload as IFile,
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };
      return newState;
    }

    case Types.GET_FILE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        file: action.payload as IFile,
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const file = action.payload as IFile;
      if (
        file &&
        (!state.files[file.sectionId] ||
          (state.files[file.sectionId] &&
            !state.files[file.sectionId][file.id]))
      ) {
        if (file.groupId) {
          if (newState.filesInGroup[file.groupId]) {
            newState.files[file.sectionId][file.groupId].filesCount += 1;
          } else if (
            newState.files[file.sectionId] &&
            newState.files[file.sectionId][file.groupId]
          ) {
            newState.files[file.sectionId][file.groupId].filesCount = 1;
          }
          newState.filesInGroup[file.groupId] = {
            ...newState.filesInGroup[file.groupId],
            [file.id]: file
          };
        } else {
          newState.files[file.sectionId] = {
            ...newState.files[file.sectionId],
            [file.id]: file
          };
        }
        if (!file.groupId) {
          if (isNaN(newState.counts[file.sectionId])) {
            newState.counts[file.sectionId] = 1;
          } else {
            newState.counts[file.sectionId] = state.counts[file.sectionId] + 1;
          }
        }
      }

      return newState;
    }

    case Types.CREATE_DOC_FILE_SUCCESS:
    case Types.CREATE_BY_URL_SUCCESS:
    case Types.UPLOAD_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const file = action.payload.file as IFile;
      const group = action.payload.group as IFile;

      if (group) {
        newState.files[group.sectionId] = {
          ...newState.files[group.sectionId],
          [group.id]: group
        };
        newState.activeGroup = group;
      }

      if (file) {
        if (file.groupId) {
          if (newState.filesInGroup[file.groupId]) {
            newState.files[file.sectionId][file.groupId].filesCount += 1;
          } else if (
            newState.files[file.sectionId] &&
            newState.files[file.sectionId][file.groupId]
          ) {
            newState.files[file.sectionId][file.groupId].filesCount = 1;
          }
          newState.filesInGroup[file.groupId] = {
            ...newState.filesInGroup[file.groupId],
            [file.id]: file
          };
        } else {
          newState.files[file.sectionId] = {
            ...newState.files[file.sectionId],
            [file.id]: file
          };
        }
        newState.newFileId = file.id;
      }
      if (!file.groupId || group) {
        if (isNaN(newState.counts[file.sectionId])) {
          newState.counts[file.sectionId] = 1;
        } else {
          newState.counts[file.sectionId] = state.counts[file.sectionId] + 1;
        }
      }

      return newState;
    }

    case Types.CREATE_BY_DRIVE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const files = action.payload.files as IFile[];
      const group = action.payload.group as IFile;

      if (group) {
        newState.files[group.sectionId] = {
          ...newState.files[group.sectionId],
          [group.id]: group
        };
        newState.activeGroup = group;
      }

      files.forEach((file) => {
        if (file) {
          if (file.groupId) {
            if (newState.filesInGroup[file.groupId]) {
              newState.files[file.sectionId][file.groupId].filesCount += 1;
            } else if (
              newState.files[file.sectionId] &&
              newState.files[file.sectionId][file.groupId]
            ) {
              newState.files[file.sectionId][file.groupId].filesCount = 1;
            }
            newState.filesInGroup[file.groupId] = {
              ...newState.filesInGroup[file.groupId],
              [file.id]: file
            };
          } else {
            newState.files[file.sectionId] = {
              ...newState.files[file.sectionId],
              [file.id]: file
            };
          }
        }

        if (!file.groupId || group) {
          if (isNaN(newState.counts[file.sectionId])) {
            newState.counts[file.sectionId] = 1;
          } else {
            newState.counts[file.sectionId] = state.counts[file.sectionId] + 1;
          }
        }
      });

      return newState;
    }

    case Types.GET_FILE_FROM_NOTIFICATION_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const file = action.payload as IFile;

      if (file) {
        if (file.groupId) {
          if (newState.filesInGroup[file.groupId]) {
            newState.files[file.sectionId][file.groupId].filesCount += 1;
          } else if (
            newState.files[file.sectionId] &&
            newState.files[file.sectionId][file.groupId]
          ) {
            newState.files[file.sectionId][file.groupId].filesCount = 1;
          }
          newState.filesInGroup[file.groupId] = {
            ...newState.filesInGroup[file.groupId],
            [file.id]: file
          };
        } else {
          newState.files[file.sectionId] = {
            ...newState.files[file.sectionId],
            [file.id]: file
          };
        }
      }

      if (!file.groupId) {
        if (isNaN(newState.counts[file.sectionId])) {
          newState.counts[file.sectionId] = 1;
        } else {
          newState.counts[file.sectionId] = state.counts[file.sectionId] + 1;
        }
      }

      return newState;
    }

    case Types.CLEAR_FILES_AFTER_NOTIFICATION:
    case Types.DELETE_FILE_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      newState.files = { ...state.files };
      newState.filesInGroup = { ...state.filesInGroup };
      newState.counts = { ...state.counts };

      const sectionId = action.payload.statusCode
        ? state.activeIds?.sectionId
        : action.payload.sectionId;
      const groupId = action.payload.statusCode
        ? state.activeIds?.groupId
        : action.payload.groupId;
      const filesIds = action.payload.statusCode
        ? state.activeIds?.filesIds
        : action.payload.filesIds;

      if (sectionId && filesIds?.length) {
        if (groupId) {
          filesIds.forEach((fileId: string) => {
            delete newState.filesInGroup[groupId][fileId];
          });
          newState.files[sectionId][groupId].filesCount -= filesIds.length;
          if (newState.files[sectionId][groupId].filesCount === 0) {
            delete newState.filesInGroup[groupId];
            newState.counts[sectionId] = newState.counts[sectionId] - 1;
            delete newState.files[sectionId][groupId];
          }
        } else {
          newState.counts[sectionId] =
            newState.counts[sectionId] - filesIds.length;
          filesIds.forEach((fileId: string) => {
            if (newState.files[sectionId][fileId].isGroup) {
              delete newState.filesInGroup[fileId];
            }
            delete newState.files[sectionId][fileId];
          });
        }
        if (newState.counts[sectionId] === 0) {
          delete newState.counts[sectionId];
          delete newState.files[sectionId];
        }
      }

      return newState;
    }

    case Types.DELETE_ALL_FILES_FROM_SECTION: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const sectionId = action.payload.sectionId;
      if (sectionId) {
        newState.files = { ...state.files };
        newState.counts = { ...state.counts };
        newState.filesInGroup = { ...state.filesInGroup };

        delete newState.counts[sectionId];
        const files: IFile[] = getFilesBySectionId(state, sectionId);
        if (files) {
          files.map((item) => {
            if (item.isGroup) {
              delete newState.filesInGroup[item.id];
            }
          });
        }
        delete newState.files[sectionId];
      }

      return newState;
    }

    case Types.CHANGE_TO_GROUP_SUCCESS: {
      const newState = {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false
      };

      const group = action.payload as IFile;
      newState.files = { ...state.files };
      delete newState.files[group.sectionId][state.activeIds.filesIds[0]];
      newState.files[group.sectionId] = {
        ...newState.files[group.sectionId],
        [group.id]: group
      };

      return newState;
    }

    case Types.INVITE_FILE_SUCCESS: {
      return {
        ...state,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingAllFiles: false,
        isLoadingCopyShareLink: false,
        isLoadingCreating: false,
        shareError: ''
      };
    }

    case Types.CLEAR_SHARE_ERROR: {
      return {
        ...state,
        errors: [action.payload],
        isLoading: false,
        isLoadingCopyShareLink: false,
        shareError: ''
      };
    }

    case Types.TOGGLE_NEW_FILE: {
      return {
        ...state,
        newFileId: action.payload
      };
    }

    case Types.CLEAR_ACTIVE_GROUP: {
      return {
        ...state,
        activeGroup: null
      };
    }

    case Types.SELECT_FROM_DRIVE_MODAL: {
      return {
        ...state,
        isSelectFromDrive: action.payload
      };
    }

    case Types.INVITE_FILE_REQUEST: {
      return {
        ...state,
        errors: [],
        isLoading: true,
        isLoadingCopyShareLink: false,
        shareError: ''
      } as IFilesState;
    }

    case Types.GET_FILES_FEED_REQUEST: {
      return {
        ...state,
        filesFeed: {
          ...state.filesFeed,
          errors: new Array<string>(),
          isLoading: true,
          isLoadingCopyShareLink: false,
        }
      } as IFilesState;
    }

    case Types.GET_FILES_FEED_NEW_AMOUNT_REQUEST: {
      return {
        ...state,
        filesFeed: {
          ...state.filesFeed,
          errors: new Array<string>(),
          isLoadingOnAmount: true
        }
      } as IFilesState;
    }

    case Types.GET_FILES_FEED_SUCCESS: {
      const newState = {
        ...state,
        filesFeed: { ...state.filesFeed }
      };
      newState.filesFeed = {
        ...newState.filesFeed,
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnAmount: false
      };
      newState.filesFeed.files = action.payload as IFeedFile[];
      return newState as IFilesState;
    }

    case Types.GET_FILES_FEED_NEW_AMOUNT_SUCCESS: {
      const newState = {
        ...state,
        ...state.filesFeed
      };

      newState.filesFeed = {
        errors: new Array<string>(),
        isLoading: false,
        isLoadingOnAmount: false
      };

      newState.filesFeed.existNew = action.payload.existNewFiles;
      newState.filesFeed.newFilesCount = action.payload.newFilesCount;
      return newState as IFilesState;
    }

    case Types.GET_FILES_FEED_ERROR: {
      return {
        ...state,
        filesFeed: {
          errors: [action.payload],
          isLoading: false
        }
      } as IFilesState;
    }

    case Types.GET_FILES_FEED_NEW_AMOUNT_ERROR: {
      return {
        ...state,
        filesFeed: {
          errors: [action.payload],
          isLoadingOnAmount: false
        }
      } as IFilesState;
    }

    default:
      return state;
  }
};

// selectors:
export const getFilesBySectionId = (
  state: IFilesState,
  sectionId: string
): IFile[] => {
  return state.files[sectionId] ? Object.values(state.files[sectionId]) : null;
};

export const getFilesByGroupId = (
  state: IFilesState,
  groupId: string
): IFile[] => {
  if (groupId) {
    return state.filesInGroup[groupId]
      ? Object.values(state.filesInGroup[groupId])
      : null;
  }
  return null;
};
