import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

interface IProps {
  message: string;
  isOpen: boolean;
  type: 'error';
  children?: any;
  placement?:
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
  isModal?: boolean;
}

const getStyleTooltip = (zIndexValue: number) => (
  {
    popper: {
      marginLeft: '0px',
      zIndex: zIndexValue
    },
    tooltip: {
      backgroundColor: '#E57373',
      color: 'white',
      fontSize: '12px',
      marginTop: '10px',
      maxWidth: 'unset',
      opacity: '1'
    }
  }
);

export default function TooltipSet(props: IProps) {
  const { message, isOpen, placement, isModal } = props;

  const finalPlacement = placement || 'bottom-start';
  const zIndex = isModal ? 1500 : 500;
  const TooltipWithStyle = withStyles(() => (getStyleTooltip(zIndex)))(Tooltip);

  return (
    <TooltipWithStyle
      title={message}
      open={isOpen}
      placement={finalPlacement}
    >
      {props.children}
    </TooltipWithStyle>
  );
}
