import React from 'react';
import { connect } from 'react-redux';
import { setActiveTab } from '@redux/reducers/dashboard/action';
import { setActiveCategory } from '@redux/reducers/categories/actions';
import { setActiveProject } from '@redux/reducers/projects/actions';
import { Tab, Tabs } from '@material-ui/core';
import Categories from '@components/categories';
import Projects from '@components/projects';
import styles from './styles.m.scss';

interface IReduxProps {
  activeTab: 'categories' | 'projects';
  projects: ICategory[];
  roleOfUser: UserRoleType;
  setActiveCategory: typeof setActiveCategory;
  setActiveProject: typeof setActiveProject;
  setActiveTab: typeof setActiveTab;
}

interface IOwnProps {
  history: any;
}

interface IProps extends IReduxProps, IOwnProps { }

const DashboardTabs = (props: IProps) => {
  const { history, activeTab, setActiveTab: setTab } = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      setTab('categories');
    } else {
      setTab('projects');
    }

    history.push('/dashboard');
    props.setActiveCategory(null);
    props.setActiveProject(null);

  };

  const value = activeTab === 'projects' ? 1 : 0;

  return (
    <div>
        <Tabs className={styles.dashboardTabs} value={value} onChange={handleChange}>
          <Tab className={styles.dashboardTab} label="categories" id="simple-tab$0" aria-controls="simple-tabpanel$0"/>
          <Tab className={styles.dashboardTab} label="projects" id="simple-tab$1" aria-controls="simple-tabpanel$1" />
        </Tabs>
      <Categories history={history} index={0} value={value}/>
      <Projects history={history} index={1} value={value}/>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  activeTab: state.dashboardState.activeTab,
  projects: state.projectState.projects,
  roleOfUser: state.auth.profile.role,
});

const actions = {
  setActiveCategory,
  setActiveProject,
  setActiveTab,
};

export default connect(mapStateToProps, actions)(DashboardTabs);
