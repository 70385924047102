import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './styles.m.scss';

type TooltipType = 'error';

interface IProps extends PropsWithChildren<any> {
  message: string;
  isOpen: boolean;
  type: TooltipType;
}

const InputTooltip = (props: IProps) => {
  const { message, isOpen, type, children } = props;

  return (
    <div className={classNames(styles.tipWrap, isOpen && styles.open)}>
      {children}
      <div className={styles.tooltip}>
        {message}
      </div>
    </div>
  );
};

export default InputTooltip;
