import { makeStyles } from '@material-ui/core';

export const useStylesCheckbox = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // necessary! otherwise checkedIcon won`t be displayed
  // tslint:disable-next-line: object-literal-sort-keys
  icon: {
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'backgroundColor': '#f5f8fa',
    'borderRadius': 3,
    'box-shadow': 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.2)',
    'height': 16,
    'input:disabled ~ &': {
      background: 'rgba(206,217,224,.5)',
      boxShadow: 'none',
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'width': 16,
  },
  checkedIcon: {
    '&:before': {
      backgroundImage:
        'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
        ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
        '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
      content: '""',
      display: 'block',
      height: 16,
      width: 16,
    },
    'backgroundColor': '#2979ff',
    'input:hover ~ &': {
      backgroundColor: '#007bff',
    },
  },
});
