enum Types {

  FETCH_CARD_REQUEST = '[Payments] Fetch card request',
  FETCH_CARD_SUCCESS = '[Payments] Fetch card success',

  FETCH_INVOICE_REQUEST = '[Payments] Fetch invoices request',
  FETCH_INVOICE_SUCCESS = '[Payments] Fetch invoices success',

  CREATE_PAYMENTS_CARD_REQUEST = '[Payments] Create payments card request',
  CREATE_PAYMENTS_CARD_SUCCESS = '[Payments] Create payments card success',
  CREATE_PAYMENTS_CARD_ERROR = '[Payments] Create payments card error',

  UPGRADE_PLAN_WITHOUT_CARD = '[Payments] Upgrade plan without card',

  SUBSCRIBE_TO_STRIPE = '[Payments] Subscribe to Stripe',

  CLEAR_CREATING_CARD_FAILED = '[Payments] Clear creating card failed',

  PAYMENTS_ERROR = '[Payments] Error'
}

export default Types;
